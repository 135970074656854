import api from "./api.js";

export default class TransactionApi {
  static async GetList(params) {
    return await api.get(`admin/calculates`, params);
  }
  static async GetById(calculateId) {
    return await api.get(`admin/calculate/${calculateId}`);
  }
  static async Delete(data) {
    return await api.del(`admin/calculates`, "", data);
  }
  static async DeleteById(calculateId) {
    return await api.del(`admin/calculate/${calculateId}`);
  }

  static async Complete(calculateId) {
    return await api.put(`admin/calculate/complete/${calculateId}`);
  }
  static async request(calculateId) {
    return await api.put(`admin/calculate/request/${calculateId}`);
  }
}
