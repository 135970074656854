import React from "react";
import styled from "styled-components";

function DetailPage(props) {
  return (
    <>
      <DetailPageWrapper
        style={{
          ...props.style,
        }}
        ref={props.ref}
      >
        {props.children}
      </DetailPageWrapper>
    </>
  );
}

export default DetailPage;

const DetailPageWrapper = styled.div.attrs((props) => {})`
  width: 1044px;
  height: auto;
  padding: 40px;
  position: relative;
  border-radius: 8px;
  border: 1px solid #dfdfdf;
  background: #fff;
  margin-bottom: 100px;
`;
