import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import Header from "../../../component/organisms/header";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import Sidebar from "../../../component/organisms/sidebar";
import DetailTop from "../../../component/atoms/layout/detail/detailTop";
import DetailPage from "../../../component/atoms/layout/detail/detailPage";
import DetailPageTitle from "../../../component/atoms/text/detail/detailPageTitle";
import DetailPageLayout from "../../../component/atoms/layout/detail/detailPageLayout";
import GoBack from "../../../component/molecules/goBack";
import EditBtn from "../../../component/atoms/button/editBtn";
import DetailPageForm from "../../../component/atoms/layout/detail/detailPageForm";
import DetailFormTitle from "../../../component/atoms/text/detail/detailFormTitle";
import DetailFormText from "../../../component/atoms/text/detail/detailFormText";
import { toast } from "react-toastify";
import { toastMsg } from "../../../util/toastMsg";
import { errorHandler } from "../../../util/error-handler";
import { route } from "../../../router/route";
import DelBtn from "../../../component/atoms/button/delBtn";
import Modal from "../../../component/templates/Modal";
import { modalMsg } from "../../../util/modalMsg";
import moment from "moment";
import FileImgPreview from "../../../component/molecules/fileImgPreview";
import TransactionApi from "../../../api/transaction.api";
import CategoryApi from "../../../api/category.api";
import Table from "../../../component/atoms/layout/table/table";
import Tr from "../../../component/atoms/layout/table/tr";
import Th from "../../../component/atoms/layout/table/th";
import Checkbox from "../../../component/atoms/input/checkbox";
import ThText from "../../../component/atoms/text/table/thText";
import Td from "../../../component/atoms/layout/table/td";
import TdText from "../../../component/atoms/text/table/tdText";
import DetailBtn from "../../../component/atoms/button/detailBtn";
import Pagenation from "../../../component/molecules/pagenation";
import Pagination2 from "../../../component/molecules/pagination2";
import MarketApi from "../../../api/market.api";
import PretendardText from "../../../component/atoms/text/pretendardText";
import MerchApi from "../../../api/merch.api";
import ReviewApi from "../../../api/review.api";
import TableDelBtn from "../../../component/atoms/button/tableDelBtn";
import TableFilterManager from "../../../component/atoms/layout/table/tableFilterManager";
import TextArea from "../../../component/atoms/input/textarea";
import MarketRegisterDetailTab from "../../../component/templates/market/marketRegisterDetailTab";
import MarketDeleteDetailTab from "../../../component/templates/market/marketDeleteDetailTab";

function MarketDeleteDetailMerchDetail(props) {
  const navigate = useNavigate();
  let { id, merchId } = useParams();

  const [data, setData] = useState({});

  // init
  const getData = async () => {
    try {
      const response = await MerchApi.GetById(merchId);
      setData({
        ...response.data.data,
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  // ----- 삭제
  const [delModalOpen, setDelModalOpen] = useState(false);

  const deleteOneFunc = async () => {
    try {
      await MerchApi.DeleteById(merchId);
      toast(toastMsg.delete);
      setDelModalOpen(false);
      navigate(-1);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <MarketDeleteDetailTab />

          <>
            <DetailTop>
              <GoBack />
              <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
                <DelBtn onClick={() => setDelModalOpen(true)} />
                <EditBtn
                  onClick={() => {
                    navigate(route.market_register_detail_merch_edit + `/${id}/${merchId}`);
                  }}
                />
              </div>
            </DetailTop>

            <DetailPage style={{ minHeight: "360px", marginBottom: "20px" }}>
              <DetailPageTitle>상세보기</DetailPageTitle>
              <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                <DetailPageForm>
                  <DetailFormTitle>상품명</DetailFormTitle>
                  <DetailFormText>{data.name}</DetailFormText>
                </DetailPageForm>
                <DetailPageForm style={{ alignItems: "flex-start" }}>
                  <DetailFormTitle>
                    상품 요약 설명*
                    <br />
                    <span
                      style={{
                        fontSize: "12px",
                        fontWeight: "400",
                        lineHeight: "24.5px",
                        color: "#ADB4BA",
                      }}
                    >
                      (최대 30자)
                    </span>
                  </DetailFormTitle>
                  <DetailFormText>{data.explanation}</DetailFormText>
                </DetailPageForm>
                <DetailPageForm>
                  <DetailFormTitle>금액</DetailFormTitle>
                  <DetailFormText>{Number(data.price).toLocaleString("ko-KR")}원</DetailFormText>
                </DetailPageForm>
                <DetailPageForm>
                  <DetailFormTitle>배송비</DetailFormTitle>
                  <DetailFormText>{Number(data.deliveryFee).toLocaleString("ko-KR")}원</DetailFormText>
                </DetailPageForm>
                <DetailPageForm>
                  <DetailFormTitle>채팅 링크</DetailFormTitle>
                  <DetailFormText>{data.chatLink}</DetailFormText>
                </DetailPageForm>
                <DetailPageForm>
                  <DetailFormTitle>상품 내용</DetailFormTitle>
                  <DetailFormText>{data.content}</DetailFormText>
                </DetailPageForm>
                <DetailPageForm style={{ alignItems: "flex-start" }}>
                  <DetailFormTitle>상품 이미지</DetailFormTitle>
                  <div style={{ display: "flex", gap: "5px", flexWrap: "wrap", width: "744px" }}>
                    {data.images?.length > 0
                      ? data.images?.map((item, index) => {
                          return (
                            <FileImgPreview
                              key={index}
                              fileUrl={item.fileUrl}
                              fileName={item.originFileName}
                              serverFileName={item.serverFileName}
                            />
                          );
                        })
                      : null}
                  </div>
                </DetailPageForm>
                <DetailPageForm>
                  <DetailFormTitle>상품 상태</DetailFormTitle>
                  <DetailFormText>{data.merchStatusTitle}</DetailFormText>
                </DetailPageForm>
              </div>
            </DetailPage>
          </>
        </DetailPageLayout>
      </MainLayout>
      {delModalOpen ? (
        <Modal
          title={modalMsg.delete?.title}
          text={modalMsg.delete?.content}
          btnTitle={"삭제하기"}
          setModalOpen={setDelModalOpen}
          onClick={() => {
            deleteOneFunc();
          }}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default MarketDeleteDetailMerchDetail;
