import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import MainLayout from "../../component/atoms/layout/mainLayout";
import PretendardText from "../../component/atoms/text/pretendardText";
import MainGraph from "../../component/molecules/mainGraph";
import Header from "../../component/organisms/header";
import Sidebar from "../../component/organisms/sidebar";
import { errorHandler } from "../../util/error-handler";
import { toastMsg } from "../../util/toastMsg";
import { route } from "../../router/route";
import CountApi from "../../api/count.api";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import CategoryApi from "../../api/category.api";
import Td from "../../component/atoms/layout/table/td";
import TdText from "../../component/atoms/text/table/tdText";
import DetailBtn from "../../component/atoms/button/detailBtn";
import Pagenation from "../../component/molecules/pagenation";
import CommentApi from "../../api/comment.api";
import Pagination2 from "../../component/molecules/pagination2";
import MarketApi from "../../api/market.api";

function Home(props) {
  ChartJS.register(ArcElement, Tooltip, Legend);

  const navigate = useNavigate();

  //퀵메뉴 목록
  const quickMenu = [
    {
      category: "사용자 관리",
      title: "일반회원 관리",
      route: route.member_member + "/1",
    },
    {
      category: "사용자 관리",
      title: "인플루언서 관리",
      route: route.member_influencer + "/1",
    },
    {
      category: "마켓 관리",
      title: "등록된 마켓 관리",
      route: route.market_register + "/1",
    },
    {
      category: "거래 관리",
      title: "진행중인 거래",
      route: route.transaction_purchase + "/1",
    },
    {
      category: "기타 관리",
      title: "1:1문의 관리",
      route: route.service_ask + "/1",
    },
    {
      category: "서비스 관리",
      title: "1:1문의 관리",
      route: route.service_ask + "/1",
    },
  ];

  //방문자수, 날짜 정보
  const [visit, setVisit] = useState([]);
  const [graphMax, setGraphMax] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(null);
  const [totalCount, setTotalCount] = useState({
    totalCount: 0,
    joinCount: 0,
    leaveCount: 0,
  });
  const [visitLine, setVisitLine] = useState([]);

  useEffect(() => {
    getMemberCount();
  }, []);

  const getMemberCount = async () => {
    try {
      const count = (await CountApi.GetMemberCount()).data.data;
      setTotalCount(count);
    } catch (error) {
      toast(errorHandler(error));
    }
  };

  useEffect(() => {
    getVisitorCount();
  }, [currentPage]);

  const getVisitorCount = async () => {
    try {
      const data = {
        page: currentPage,
        size: 10,
      };
      const list = (await CountApi.GetVisitorCount(data)).data.data;
      setVisit(list.content);
      //totalPage 구하기
      setTotalPages(list.totalPages);
    } catch (error) {
      toast(toastMsg.errorText_500);
    }
  };

  const [categoryList, setCategoryList] = useState([]);

  const getCategoryList = async () => {
    try {
      let list = (await CategoryApi.GetListAll()).data.data;
      setCategoryList(
        list.map((item) => {
          return {
            ...item,
          };
        })
      );
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    getCategoryList();
  }, []);

  useEffect(() => {
    let line = [];
    let max = 0;
    for (let i = 0; i < visit.length; i++) {
      if (max <= visit[i].count) {
        max = visit[i].count;
      }
    }
    setGraphMax(max);
    let per = max / 3;
    for (let i = 0; i < 3; i++) {
      line.push(Math.round(max - per * i));
    }
    setVisitLine(line);
  }, [visit]);

  function getRandomInRange(min, max) {
    return Math.random() * (max - min) + min;
  }

  const getRandomColor = useCallback(() => {
    var r = Math.floor(getRandomInRange(39, 217));
    var g = Math.floor(getRandomInRange(190, 255));
    var b = Math.floor(getRandomInRange(1, 255));
    return "rgba(" + r + "," + g + "," + b + "," + 1 + ")";
  }, []);

  const getColor = useCallback(() => {
    const color = [];
    for (var i = 0; i < categoryList.length; i++) {
      color.push(getRandomColor());
    }
    return color;
  }, [categoryList.length, getRandomColor]);

  const [purchaseRatio, setPurchaseRatio] = useState({});
  const getPurchaseAgeRatio = async () => {
    try {
      const data = (await CountApi.GetPurchaseRatio()).data.data;
      const sortedEntries = Object.entries(data.purchaseRatio).sort(([, valueA], [, valueB]) => valueB - valueA); // 내림차순 정렬, valueB - valueA로 변경하면 오름차순 정렬
      const sortedObject = Object.fromEntries(sortedEntries);
      setPurchaseRatio(sortedObject);
    } catch (error) {
      toast(errorHandler(error));
    }
  };

  useEffect(() => {
    getPurchaseAgeRatio();
  }, []);

  const chartData1 = useMemo(() => {
    return {
      labels: Object.keys(purchaseRatio),
      datasets: [
        {
          label: "",
          data: Object.values(purchaseRatio),
          backgroundColor: getColor(),
          borderWidth: 1,
        },
      ],
    };
  }, [getColor, purchaseRatio]);

  const [storeRatio, setStoreRatio] = useState({});
  const getStoreAgeRatio = async () => {
    try {
      const data = (await CountApi.GetStoreRatio()).data.data;
      const sortedEntries = Object.entries(data.storeRatio).sort(([, valueA], [, valueB]) => valueB - valueA); // 내림차순 정렬, valueB - valueA로 변경하면 오름차순 정렬
      const sortedObject = Object.fromEntries(sortedEntries);

      setStoreRatio(sortedObject);
    } catch (error) {
      toast(errorHandler(error));
    }
  };

  useEffect(() => {
    getStoreAgeRatio();
  }, []);

  const chartData2 = useMemo(() => {
    return {
      labels: Object.keys(storeRatio),
      datasets: [
        {
          label: "",
          data: Object.values(storeRatio),
          backgroundColor: getColor(),
          borderWidth: 1,
        },
      ],
    };
  }, [getColor, storeRatio]);

  const [memberRatio, setMemberRatio] = useState({});
  const getMemberAgeRatio = async () => {
    try {
      const data = (await CountApi.GetMemberAgeRatio()).data.data;
      setMemberRatio(data);
    } catch (error) {
      toast(errorHandler(error));
    }
  };

  useEffect(() => {
    getMemberAgeRatio();
  }, []);

  const chartData3 = useMemo(() => {
    return {
      labels: ["20세 이하", "20세~24세", "25세~29세", "30세~34세", "35세 이상"],
      datasets: [
        {
          label: "",
          data: Object.values(memberRatio),
          backgroundColor: getColor(),
          borderWidth: 1,
        },
      ],
    };
  }, [getColor, memberRatio]);

  const options = {
    plugins: {
      legend: {
        padding: "",
        align: "start",
        position: "bottom",
        labels: {
          boxWidth: 11,
          boxHeight: 11,
          color: "#636C73",
          font: {
            size: 12,
          },
        },
        title: {
          display: true,
          padding: 5,
        },
      },
      tooltip: {
        // titleFont: {
        //   size: 12,
        // },
        // bodyFont: {
        //   size: 12,
        // },
        // footerFont: {
        //   // size: 10, // there is no footer by default
        // },
        callbacks: {
          // title: () => {
          //   return "title이랑 label 위치 변경!";
          // },
          label: (item) => {
            const ratio = item.dataset.data[item.dataIndex];
            const info = `${ratio.toFixed(2)}%`;
            return info;
          },
        },
      },
    },
  };

  const [pagination1, setPagination1] = useState({
    size: 5, // 한 페이지에 표시될 게시글 수
    rangeSize: 2, // 하단 페이지 사이즈

    totalCount: 0,
    totalPages: 0,
    first: false,
    last: false,
  });
  const [pagination2, setPagination2] = useState({
    size: 5, // 한 페이지에 표시될 게시글 수
    rangeSize: 2, // 하단 페이지 사이즈

    totalCount: 0,
    totalPages: 0,
    first: false,
    last: false,
  });
  const [page1, setPage1] = useState(1);
  const [page2, setPage2] = useState(1);

  const getList1 = async () => {
    try {
      const data = {
        page: page1 - 1,
        size: pagination1.size,
      };
      let list = (await CountApi.GetMarketLoginCountRanks(data)).data.data;
      setRanking1(
        list.content.map((item) => {
          return {
            ...item,
            checked: false,
          };
        })
      );
      setPagination1({
        ...pagination1,
        totalCount: list.totalElements,
        totalPages: list.totalPages,
        first: list.first,
        last: list.last,
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  const getList2 = async () => {
    try {
      const data = {
        page: page2 - 1,
        size: pagination2.size,
      };
      let list = (await CountApi.GetMarketSaleCountRanks(data)).data.data;
      setRanking2(
        list.content.map((item) => {
          return {
            ...item,
            checked: false,
          };
        })
      );
      setPagination2({
        ...pagination2,
        totalCount: list.totalElements,
        totalPages: list.totalPages,
        first: list.first,
        last: list.last,
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    getList1();
  }, [page1]);

  useEffect(() => {
    getList2();
  }, [page2]);

  const [ranking1, setRanking1] = useState([]);
  const [ranking2, setRanking2] = useState([]);

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <HomeWrapper>
          <HomeHeader>
            <div>
              <PretendardText
                style={{
                  color: "#808991",
                  fontSize: "20px",
                  fontWeight: "600",
                  lineHeight: "34px",
                  letterSpacing: "-0.06px",
                }}
              >
                Welcome!
              </PretendardText>
              <PretendardText
                style={{
                  color: "#262C31",
                  fontSize: "40px",
                  fontWeight: "700",
                  lineHeight: "56px",
                  letterSpacing: "-0.28px",
                }}
              >
                안녕하세요. 관리자님 :)
              </PretendardText>
            </div>
            <TotalCountWrapper>
              <TotalCountInfo>
                <HomePretendardText style={{ color: "#636C73" }}>전체 사용자 수</HomePretendardText>
                <img src="/assets/admin/icons/ico_divider.svg" alt="" />
                <HomePretendardText style={{ color: "#636C73" }}>{totalCount?.totalCount}명</HomePretendardText>
              </TotalCountInfo>
              <TotalCountInfo>
                <HomePretendardText style={{ color: "#636C73" }}>회원(가입/탈퇴)</HomePretendardText>
                <img src="/assets/admin/icons/ico_divider.svg" alt="" />
                <HomePretendardText style={{ color: "#636C73" }}>
                  {totalCount?.joinCount}/{totalCount?.leaveCount}
                </HomePretendardText>
              </TotalCountInfo>
            </TotalCountWrapper>
          </HomeHeader>

          <HomePretendardText style={{ marginBottom: "10px" }}>방문자 통계</HomePretendardText>
          <MainGraph visit={visit} graphMax={graphMax} currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages} visitLine={visitLine} />

          <HomePretendardText style={{ marginBottom: "10px" }}>사용자 활동 통계</HomePretendardText>
          <div style={{ display: "flex", gap: "21px", marginBottom: "40px" }}>
            <div
              style={{
                width: "334px",
                height: "401px",
                borderRadius: "8px",
                border: "1px solid #D7DBE0",
                padding: "20px",
              }}
            >
              <PretendardText
                style={{
                  color: "#262C31",
                  fontSize: "14px",
                  fontWeight: "600",
                  lineHeight: "19.6px",
                }}
              >
                카테고리별 거래 비율
              </PretendardText>

              <Doughnut
                data={chartData1}
                options={options}
                style={{
                  marginTop: "20px",
                }}
              />
            </div>

            <div
              style={{
                width: "334px",
                height: "401px",
                borderRadius: "8px",
                border: "1px solid #D7DBE0",
                padding: "20px",
              }}
            >
              <PretendardText
                style={{
                  color: "#262C31",
                  fontSize: "14px",
                  fontWeight: "600",
                  lineHeight: "19.6px",
                }}
              >
                카테고리별 찜 비율
              </PretendardText>

              <Doughnut
                data={chartData2}
                options={options}
                style={{
                  marginTop: "20px",
                }}
              />
            </div>

            <div
              style={{
                width: "334px",
                height: "401px",
                borderRadius: "8px",
                border: "1px solid #D7DBE0",
                padding: "20px",
              }}
            >
              <PretendardText
                style={{
                  color: "#262C31",
                  fontSize: "14px",
                  fontWeight: "600",
                  lineHeight: "19.6px",
                }}
              >
                연령대별 비율
              </PretendardText>

              <Doughnut
                data={chartData3}
                options={options}
                style={{
                  marginTop: "20px",
                }}
              />
            </div>
          </div>

          <HomePretendardText style={{ marginBottom: "10px" }}>인플루언서 활동 통계</HomePretendardText>
          <div style={{ display: "flex", gap: "19px", marginBottom: "40px" }}>
            <div
              style={{
                width: "512px",
                height: "425px",
                borderRadius: "8px",
                border: "1px solid #D7DBE0",
                padding: "20px",
              }}
            >
              <PretendardText
                style={{
                  color: "#262C31",
                  fontSize: "14px",
                  fontWeight: "600",
                  lineHeight: "19.6px",
                }}
              >
                마켓 접속률 순위
              </PretendardText>

              <div style={{ marginTop: "20px", minHeight: "295px" }}>
                {ranking1.map((item, index) => {
                  return (
                    <div key={index} style={{ display: "flex", height: "59px", alignItems: "center" }}>
                      <TdText style={{ width: "23px" }}>{(page1 - 1) * 5 + index + 1}</TdText>
                      <TdText style={{ width: "196px", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", wordBreak: "break-all" }}>{item.influencerInfo?.email}</TdText>
                      <TdText style={{ width: "196px", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", wordBreak: "break-all" }}>{item.name}</TdText>
                      <DetailBtn
                        onClick={() => {
                          navigate(route.market_register_detail_basic + `/${item.id}?tab=1`);
                        }}
                      />
                    </div>
                  );
                })}
              </div>

              <div style={{ marginTop: "20px" }}>
                <Pagination2
                  style={{
                    width: "472px",
                    marginTop: "0",
                  }}
                  setPagination={setPage1}
                  currentPage={page1}
                  totalPages={pagination1.totalPages}
                  rangeSize={pagination1.rangeSize}
                  first={pagination1.first}
                  last={pagination1.last}
                />
              </div>
            </div>
            <div
              style={{
                width: "512px",
                height: "425px",
                borderRadius: "8px",
                border: "1px solid #D7DBE0",
                padding: "20px",
              }}
            >
              <PretendardText
                style={{
                  color: "#262C31",
                  fontSize: "14px",
                  fontWeight: "600",
                  lineHeight: "19.6px",
                }}
              >
                마켓 거래횟수 순위
              </PretendardText>

              <div style={{ marginTop: "20px", minHeight: "295px" }}>
                {ranking2.map((item, index) => {
                  return (
                    <div key={index} style={{ display: "flex", height: "59px", alignItems: "center" }}>
                      <TdText style={{ width: "23px" }}>{(page2 - 1) * 5 + index + 1}</TdText>
                      <TdText style={{ width: "196px", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", wordBreak: "break-all" }}>{item.influencerInfo?.email}</TdText>
                      <TdText style={{ width: "196px", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", wordBreak: "break-all" }}>{item.name}</TdText>
                      <DetailBtn
                        onClick={() => {
                          navigate(route.market_register_detail_basic + `/${item.id}?tab=1`);
                        }}
                      />
                    </div>
                  );
                })}
              </div>

              <div style={{ marginTop: "20px" }}>
                <Pagination2
                  style={{
                    width: "472px",
                    marginTop: "0",
                  }}
                  setPagination={setPage2}
                  currentPage={page2}
                  totalPages={pagination2.totalPages}
                  rangeSize={pagination2.rangeSize}
                  first={pagination2.first}
                  last={pagination2.last}
                />
              </div>
            </div>
          </div>

          <HomePretendardText style={{ marginBottom: "10px" }}>퀵메뉴</HomePretendardText>
          <QuickMenuWrapper>
            {quickMenu.map((item, index) => {
              return (
                <QuickMenu key={index}>
                  <QuickMenuCaption>{item.category}</QuickMenuCaption>
                  <QuickMenuTitle onClick={() => navigate(`${item.route}`)}>{item.title}</QuickMenuTitle>
                </QuickMenu>
              );
            })}
          </QuickMenuWrapper>
        </HomeWrapper>
      </MainLayout>
    </>
  );
}

export default Home;

const HomeWrapper = styled.div.attrs((props) => {})`
  width: 1044px;
  height: auto;
  margin-top: 190px;
`;

const HomeHeader = styled.div.attrs((props) => {})`
  width: 1044px;
  height: 90px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 40px;
`;

const TotalCountWrapper = styled.div.attrs((props) => {})`
  display: flex;
  align-items: center;
  padding: 14px 40px;
  gap: 50px;
  background: #f2f4f5;
  border-radius: 6px;
`;

const TotalCountInfo = styled.div.attrs((props) => {})`
  display: flex;
  align-items: center;
  gap: 14px;
`;

const HomePretendardText = styled(PretendardText).attrs((props) => {})`
  color: #262c31;
  font-size: 16px;
  font-weight: 700;
  line-height: 28px; /* 175% */
  letter-spacing: -0.048px;
`;

const QuickMenuWrapper = styled.div.attrs((props) => {})`
  width: 1044px;
  display: flex;
  align-items: center;
  gap: 10px 21px;
  flex-wrap: wrap;
  margin-bottom: 103px;
`;

const QuickMenu = styled.div.attrs((props) => {})`
  width: 334px;
  height: 113px;
  border-radius: 8px;
  border: 1px solid var(--gray-2, #d7dbe0);
  padding: 27px 32px;
  cursor: pointer;
`;

const QuickMenuCaption = styled(PretendardText).attrs((props) => {})`
  color: #27beff;
  font-size: 14px;
  font-weight: 600;
  line-height: 22.75px; /* 163.333% */
  margin-bottom: 4px;
`;

const QuickMenuTitle = styled(PretendardText).attrs((props) => {})`
  color: #333;
  font-size: 18px;
  font-weight: 700;
  line-height: 25px; /* 170% */
  letter-spacing: -0.054px;
  cursor: pointer;

  &::after {
    content: url("/assets/admin/icons/ico_quick_arrow.svg");
    margin-left: 6px;
    width: 20px;
    height: 20px;
    position: relative;
    top: 4px;
  }
`;
