import api from "./api.js";

export default class TermsApi {
  static async Create(data) {
    return await api.post(`admin/terms`, data);
  }
  static async GetList(params) {
    return await api.get(`admin/terms`, params);
  }
  static async GetById(termsId) {
    return await api.get(`admin/terms/${termsId}`);
  }
  static async Update(termsId, data) {
    return await api.put(`admin/terms/${termsId}`, data);
  }
  static async Delete(data) {
    return await api.del(`admin/terms`, "", data);
  }
}
