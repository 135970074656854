import api from "./api.js";

export default class PopupApi {
  static async Create(data) {
    return await api.post(`admin/popup`, data);
  }
  static async GetList(params) {
    return await api.get(`admin/popups`, params);
  }
  static async GetById(popupId) {
    return await api.get(`admin/popup/${popupId}`);
  }
  static async Update(popupId, data) {
    return await api.put(`admin/popup/${popupId}`, data);
  }
  static async Delete(data) {
    return await api.del(`admin/popups`, "", data);
  }
  static async DeleteById(popupId) {
    return await api.del(`admin/popup/${popupId}`);
  }

  static async Activation(popupId) {
    return await api.put(`admin/popup/activate/${popupId}`);
  }
  static async DeActivation(popupId) {
    return await api.put(`admin/popup/deactivate/${popupId}`);
  }
}
