import { Routes, Route } from "react-router-dom";
import React from "react";
import { route } from "./route";
import Login from "../pages/login/login";
import Join from "../pages/join/join";
import JoinComplete from "../pages/join/joinComplete";
import Home from "../pages/home/home";
import Manager from "../pages/manager/manager";
import ManagerDetail from "../pages/manager/managerDetail";
import RootEdit from "../pages/manager/rootEdit";
import AdminEdit from "../pages/manager/adminEdit";
import Basic from "../pages/service/basic/basic";
import BasicEdit from "../pages/service/basic/basicEdit";
import OpenSource from "../pages/service/openSource/openSource";
import OpensourceDetail from "../pages/service/openSource/openSourceDetail";
import OpenSourceEdit from "../pages/service/openSource/openSourceEdit";
import Terms from "../pages/service/terms/terms";
import TermsDetail from "../pages/service/terms/termsDetail";
import TermsEdit from "../pages/service/terms/termsEdit";
import TermsAdd from "../pages/service/terms/termsAdd";
import Popup from "../pages/service/popup/popup";
import PopupDetail from "../pages/service/popup/popupDetail";
import PopupEdit from "../pages/service/popup/popupEdit";
import PopupAdd from "../pages/service/popup/popupAdd";
import Faq from "../pages/service/faq/faq";
import FaqDetail from "../pages/service/faq/faqDetail";
import FaqEdit from "../pages/service/faq/faqEdit";
import FaqAdd from "../pages/service/faq/faqAdd";
import Notice from "../pages/service/notice/notice";
import NoticeDetail from "../pages/service/notice/noticeDetail";
import NoticeAdd from "../pages/service/notice/noticeAdd";
import Report from "../pages/service/report/report";
import Alarm from "../pages/service/alarm/alarm";
import AlarmDetail from "../pages/service/alarm/alarmDetail";
import AlarmAdd from "../pages/service/alarm/alarmAdd";
import Ask from "../pages/service/ask/ask";
import Ads from "../pages/etc/ads";
import AdsDetail from "../pages/etc/adsDetail";
import AdsAdd from "../pages/etc/adsAdd";
import AdsEdit from "../pages/etc/adsEdit";
import Settle from "../pages/transaction/settle/settle";
import SettleDetail from "../pages/transaction/settle/settleDetail";
import Refund from "../pages/transaction/refund/refund";
import RefundDetail from "../pages/transaction/refund/refundDetail";
import Purchase from "../pages/transaction/purchase/purchase";
import Member from "../pages/member/member/member";
import MemberDetail from "../pages/member/member/memberDetail";
import MemberEdit from "../pages/member/member/memberEdit";
import Influencer from "../pages/member/influencer/influencer";
import Leave from "../pages/member/leave/leave";
import InfluencerDetail from "../pages/member/influencer/influencerDetail";
import InfluencerEdit from "../pages/member/influencer/influencerEdit";
import MarketRegister from "../pages/market/register/marketRegister";
import MarketDelete from "../pages/market/delete/marketDelete";
import MarketCategory from "../pages/market/category/marketCategory";
import MarketSetting from "../pages/market/setting/marketSetting";
import PurchaseDetail from "../pages/transaction/purchase/purchaseDetail";
import MarketCategoryAdd from "../pages/market/category/marketCategoryAdd";
import MarketCategoryEdit from "../pages/market/category/marketCategoryEdit";
import MarketCategoryDetail from "../pages/market/category/marketCategoryDetail";
import MarketRegisterDetailReviewDetail from "../pages/market/register/marketRegisterDetailReviewDetail";
import MarketRegisterDetailMerchDetail from "../pages/market/register/marketRegisterDetailMerchDetail";
import MarketDeleteDetailMerchDetail from "../pages/market/delete/marketDeleteDetailMerchDetail";
import MarketDeleteDetailReviewDetail from "../pages/market/delete/marketDeleteDetailReviewDetail";
import MarketRegisterDetailBasic from "../pages/market/register/marketRegisterDetailBasic";
import MarketRegisterDetailMerch from "../pages/market/register/marketRegisterDetailMerch";
import MarketRegisterDetailReview from "../pages/market/register/marketRegisterDetailReview";
import MarketDeleteDetailBasic from "../pages/market/delete/marketDeleteDetailBasic";
import MarketDeleteDetailMerch from "../pages/market/delete/marketDeleteDetailMerch";
import MarketDeleteDetailReview from "../pages/market/delete/marketDeleteDetailReview";
import MarketRegisterEditBasic from "../pages/market/register/marketRegisterEditBasic";
import MarketRegisterDetailMerchEdit from "../pages/market/register/marketRegisterDetailMerchEdit";
import Column from "../pages/etc/column/column";
import ColumnDetail from "../pages/etc/column/columnDetail";
import ColumnEdit from "../pages/etc/column/columnEdit";
import ColumnAdd from "../pages/etc/column/columnAdd";
import Style from "../pages/etc/style/style";
import StyleDetail from "../pages/etc/style/styleDetail";
import StyleAdd from "../pages/etc/style/styleAdd";
import StyleEdit from "../pages/etc/style/styleEdit";

const Router = () => {
  return (
    <Routes>
      {/* default */}
      <Route path={route.default} element={<Login />} />

      {/* 로그인 */}
      <Route path={route.login} element={<Login />} />
      <Route path={route.join} element={<Join />} />
      <Route path={route.join_complete} element={<JoinComplete />} />

      {/* 홈 */}
      <Route path={route.home} element={<Home />} />

      {/* 일반 회원 관리 */}
      <Route path={route.member_member + "/:page"} element={<Member />} />
      <Route path={route.member_member_detail + "/:id"} element={<MemberDetail />} />
      <Route path={route.member_member_edit + "/:id"} element={<MemberEdit />} />
      <Route path={route.member_influencer + "/:page"} element={<Influencer />} />
      <Route path={route.member_influencer_detail + "/:id"} element={<InfluencerDetail />} />
      <Route path={route.member_influencer_edit + "/:id"} element={<InfluencerEdit />} />
      <Route path={route.member_leave + "/:page"} element={<Leave />} />

      {/* 관리자회원관리 */}
      <Route path={route.manager + "/:page"} element={<Manager />} />
      <Route path={route.manager_detail + "/:id"} element={<ManagerDetail />} />
      <Route path={route.manager_edit_root + "/:id"} element={<RootEdit />} />
      <Route path={route.manager_edit_admin + "/:id"} element={<AdminEdit />} />

      {/* 마켓 관리 */}
      <Route path={route.market_register + "/:page"} element={<MarketRegister />} />
      <Route path={route.market_register_detail_basic + "/:id"} element={<MarketRegisterDetailBasic />} />
      <Route path={route.market_register_detail_merch + "/:page/:id"} element={<MarketRegisterDetailMerch />} />
      <Route path={route.market_register_detail_review + "/:page/:id"} element={<MarketRegisterDetailReview />} />
      <Route path={route.market_register_detail_merch_detail + "/:id/:merchId"} element={<MarketRegisterDetailMerchDetail />} />
      <Route path={route.market_register_detail_review_detail + "/:id/:reviewId"} element={<MarketRegisterDetailReviewDetail />} />
      <Route path={route.market_register_edit_basic + "/:id"} element={<MarketRegisterEditBasic />} />
      <Route path={route.market_register_detail_merch_edit + "/:id/:merchId"} element={<MarketRegisterDetailMerchEdit />} />
      <Route path={route.market_delete + "/:page"} element={<MarketDelete />} />
      <Route path={route.market_delete_detail_basic + "/:id"} element={<MarketDeleteDetailBasic />} />
      <Route path={route.market_delete_detail_merch + "/:page/:id"} element={<MarketDeleteDetailMerch />} />
      <Route path={route.market_delete_detail_review + "/:page/:id"} element={<MarketDeleteDetailReview />} />
      <Route path={route.market_delete_detail_merch_detail + "/:id/:merchId"} element={<MarketDeleteDetailMerchDetail />} />
      <Route path={route.market_delete_detail_review_detail + "/:id/:reviewId"} element={<MarketDeleteDetailReviewDetail />} />
      <Route path={route.market_category + "/:page"} element={<MarketCategory />} />
      <Route path={route.market_category_add} element={<MarketCategoryAdd />} />
      <Route path={route.market_category_edit + "/:id"} element={<MarketCategoryEdit />} />
      <Route path={route.market_category_detail + "/:page/:id"} element={<MarketCategoryDetail />} />
      <Route path={route.market_setting + "/:page"} element={<MarketSetting />} />

      {/* 거래 관리 */}
      <Route path={route.transaction_purchase + "/:page"} element={<Purchase />} />
      <Route path={route.transaction_purchase_detail + "/:id"} element={<PurchaseDetail />} />
      <Route path={route.transaction_settle + "/:page"} element={<Settle />} />
      <Route path={route.transaction_settle_detail + "/:id"} element={<SettleDetail />} />
      <Route path={route.transaction_refund + "/:page"} element={<Refund />} />
      <Route path={route.transaction_refund_detail + "/:id"} element={<RefundDetail />} />

      {/* 기타 관리 */}
      {/* <Route path={route.etc_ads + "/:page"} element={<Ads />} />
      <Route path={route.etc_ads_detail + "/:id"} element={<AdsDetail />} />
      <Route path={route.etc_ads_add} element={<AdsAdd />} />
      <Route path={route.etc_ads_edit + "/:id"} element={<AdsEdit />} /> */}

      <Route path={route.etc_column + "/:page"} element={<Column />} />
      <Route path={route.etc_column_detail + "/:page/:id"} element={<ColumnDetail />} />
      <Route path={route.etc_column_add} element={<ColumnAdd />} />
      <Route path={route.etc_column_edit + "/:id"} element={<ColumnEdit />} />

      <Route path={route.etc_style + "/:page"} element={<Style />} />
      <Route path={route.etc_style_detail + "/:id"} element={<StyleDetail />} />
      <Route path={route.etc_style_add} element={<StyleAdd />} />
      <Route path={route.etc_style_edit + "/:id"} element={<StyleEdit />} />

      {/* 서비스 관리 */}
      <Route path={route.service_basic + "/:page"} element={<Basic />} />
      <Route path={route.service_basic_edit} element={<BasicEdit />} />
      <Route path={route.service_openSource + "/:page"} element={<OpenSource />} />
      <Route path={route.service_openSource_detail + "/:id"} element={<OpensourceDetail />} />
      <Route path={route.service_openSource_edit + "/:id"} element={<OpenSourceEdit />} />
      <Route path={route.service_terms + "/:page"} element={<Terms />} />
      <Route path={route.service_terms_detail + "/:id"} element={<TermsDetail />} />
      <Route path={route.service_terms_edit + "/:id"} element={<TermsEdit />} />
      <Route path={route.service_terms_add} element={<TermsAdd />} />
      <Route path={route.service_popup + "/:page"} element={<Popup />} />
      <Route path={route.service_popup_detail + "/:id"} element={<PopupDetail />} />
      <Route path={route.service_popup_edit + "/:id"} element={<PopupEdit />} />
      <Route path={route.service_popup_add} element={<PopupAdd />} />
      <Route path={route.service_faq + "/:page"} element={<Faq />} />
      <Route path={route.service_faq_detail + "/:id"} element={<FaqDetail />} />
      <Route path={route.service_faq_edit + "/:id"} element={<FaqEdit />} />
      <Route path={route.service_faq_add} element={<FaqAdd />} />
      <Route path={route.service_notice + "/:page"} element={<Notice />} />
      <Route path={route.service_notice_detail + "/:id"} element={<NoticeDetail />} />
      <Route path={route.service_notice_add} element={<NoticeAdd />} />
      <Route path={route.service_report + "/:page"} element={<Report />} />
      <Route path={route.service_ask + "/:page"} element={<Ask />} />
      <Route path={route.service_alarm + "/:page"} element={<Alarm />} />
      <Route path={route.service_alarm_detail + "/:id"} element={<AlarmDetail />} />
      <Route path={route.service_alarm_add} element={<AlarmAdd />} />
    </Routes>
  );
};

export default Router;
