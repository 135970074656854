import api from "./api.js";

export default class PurchaseApi {
  static async GetList(params) {
    return await api.get(`admin/purchases`, params);
  }
  static async GetListRefund(params) {
    return await api.get(`admin/refunds`, params);
  }
  static async GetById(purchaseId) {
    return await api.get(`admin/purchase/${purchaseId}`);
  }
  static async Delete(data) {
    return await api.del(`admin/purchases`, "", data);
  }
  static async DeleteById(purchaseId) {
    return await api.del(`admin/purchase/${purchaseId}`);
  }

  static async UpdateStatus(purchaseId, data) {
    return await api.put(`admin/purchase/${purchaseId}`, data);
  }
}
