import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import Header from "../../../component/organisms/header";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import Sidebar from "../../../component/organisms/sidebar";
import DetailTop from "../../../component/atoms/layout/detail/detailTop";
import DetailPage from "../../../component/atoms/layout/detail/detailPage";
import DetailPageTitle from "../../../component/atoms/text/detail/detailPageTitle";
import DetailPageLayout from "../../../component/atoms/layout/detail/detailPageLayout";
import GoBack from "../../../component/molecules/goBack";
import EditBtn from "../../../component/atoms/button/editBtn";
import DetailPageForm from "../../../component/atoms/layout/detail/detailPageForm";
import DetailFormTitle from "../../../component/atoms/text/detail/detailFormTitle";
import DetailFormText from "../../../component/atoms/text/detail/detailFormText";
import { toast } from "react-toastify";
import { toastMsg } from "../../../util/toastMsg";
import { errorHandler } from "../../../util/error-handler";
import { route } from "../../../router/route";
import DelBtn from "../../../component/atoms/button/delBtn";
import Modal from "../../../component/templates/Modal";
import { modalMsg } from "../../../util/modalMsg";
import moment from "moment";
import FileImgPreview from "../../../component/molecules/fileImgPreview";
import TransactionApi from "../../../api/transaction.api";
import CategoryApi from "../../../api/category.api";
import Table from "../../../component/atoms/layout/table/table";
import Tr from "../../../component/atoms/layout/table/tr";
import Th from "../../../component/atoms/layout/table/th";
import Checkbox from "../../../component/atoms/input/checkbox";
import ThText from "../../../component/atoms/text/table/thText";
import Td from "../../../component/atoms/layout/table/td";
import TdText from "../../../component/atoms/text/table/tdText";
import DetailBtn from "../../../component/atoms/button/detailBtn";
import Pagenation from "../../../component/molecules/pagenation";
import Pagination2 from "../../../component/molecules/pagination2";
import MarketApi from "../../../api/market.api";
import PretendardText from "../../../component/atoms/text/pretendardText";
import MerchApi from "../../../api/merch.api";
import ReviewApi from "../../../api/review.api";
import TableDelBtn from "../../../component/atoms/button/tableDelBtn";
import TableFilterManager from "../../../component/atoms/layout/table/tableFilterManager";
import MarketRegisterDetailTab from "../../../component/templates/market/marketRegisterDetailTab";
import MarketDeleteDetailTab from "../../../component/templates/market/marketDeleteDetailTab";

function MarketDeleteDetailReviewDetail(props) {
  const navigate = useNavigate();
  let { reviewId } = useParams();

  const [data, setData] = useState({});

  // init
  const getData = async () => {
    try {
      const response = await ReviewApi.GetById(reviewId);
      setData({
        ...response.data.data,
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  // ----- 숨김
  const [delModalOpen, setDelModalOpen] = useState(false);

  const deleteOneFunc = async () => {
    try {
      await ReviewApi.Hidden(reviewId);
      toast(toastMsg.create);
      setDelModalOpen(false);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <MarketDeleteDetailTab />

          <>
            <DetailTop>
              <GoBack />
              <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
                <DelBtn onClick={() => setDelModalOpen(true)} text={"숨김"} />
              </div>
            </DetailTop>

            <DetailPage style={{ minHeight: "360px", marginBottom: "20px" }}>
              <DetailPageTitle>기본정보</DetailPageTitle>
              <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                <DetailPageForm>
                  <DetailFormTitle>이메일</DetailFormTitle>
                  <DetailFormText>{data.writerInfo?.email}</DetailFormText>
                </DetailPageForm>
                <DetailPageForm>
                  <DetailFormTitle>이름</DetailFormTitle>
                  <DetailFormText>{data.writerInfo?.name}</DetailFormText>
                </DetailPageForm>
                <DetailPageForm>
                  <DetailFormTitle>작성일자</DetailFormTitle>
                  <DetailFormText>{moment(data.createdAt).format("YYYY/MM/DD")}</DetailFormText>
                </DetailPageForm>
                <DetailPageForm style={{ alignItems: "flex-start" }}>
                  <DetailFormTitle>리뷰 이미지</DetailFormTitle>
                  <div style={{ display: "flex", gap: "5px", flexWrap: "wrap", width: "744px" }}>
                    {data.images?.length > 0
                      ? data.images?.map((item, index) => {
                          return (
                            <FileImgPreview
                              key={index}
                              fileUrl={item.fileUrl}
                              fileName={item.originFileName}
                              serverFileName={item.serverFileName}
                            />
                          );
                        })
                      : null}
                  </div>
                </DetailPageForm>
                <DetailPageForm>
                  <DetailFormTitle>상품명</DetailFormTitle>
                  <DetailFormText>{data.merchInfo?.name}</DetailFormText>
                </DetailPageForm>
                <DetailPageForm>
                  <DetailFormTitle>별점</DetailFormTitle>
                  <DetailFormText>{data.score}</DetailFormText>
                </DetailPageForm>
                <DetailPageForm style={{ alignItems: "flex-start" }}>
                  <DetailFormTitle>리뷰 내용</DetailFormTitle>
                  <DetailFormText>{data.content}</DetailFormText>
                </DetailPageForm>
              </div>
            </DetailPage>
          </>
        </DetailPageLayout>
      </MainLayout>
      {delModalOpen ? (
        <Modal
          title={"숨김처리 하시겠습니까?"}
          btnTitle={"삭제하기"}
          setModalOpen={setDelModalOpen}
          onClick={() => {
            deleteOneFunc();
          }}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default MarketDeleteDetailReviewDetail;
