import { toast } from "react-toastify";
import { route } from "../router/route";

function errorHandler(error) {
  const message = error.response?.data?.message;
  const status = error.response?.status;
  const message400 = message ? message : "잘못된 요청입니다.";
  const message500 = message ? message : "서버에 문제가 있습니다. 잠시후 다시 시도해주세요.";

  switch (status) {
    case 403:
      toast("권한이 없습니다. 로그인해주세요.");
      sessionStorage.clear();
      window.location.href = route.login;
      break;
    case 400:
      toast(`${message400}`);
      break;
    case 500:
      toast(`${message500}`);
      break;
    default:
      break;
  }
}

export { errorHandler };
