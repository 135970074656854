import api from "./api.js";

export default class ColumnApi {
  static async Create(data) {
    return await api.post(`admin/columnBoard`, data);
  }
  static async GetList(params) {
    return await api.get(`admin/columnBoards`, params);
  }
  static async GetById(columnBoardId) {
    return await api.get(`admin/columnBoard/${columnBoardId}`);
  }
  static async Update(columnBoardId, data) {
    return await api.put(`admin/columnBoard/${columnBoardId}`, data);
  }
  static async Delete(data) {
    return await api.del(`admin/columnBoards`, "", data);
  }
  static async DeleteById(columnBoardId) {
    return await api.del(`admin/columnBoard/${columnBoardId}`);
  }

  static async Activation(columnBoardId) {
    return await api.put(`admin/columnBoard/activate/${columnBoardId}`);
  }
  static async DeActivation(columnBoardId) {
    return await api.put(`admin/columnBoard/deactivate/${columnBoardId}`);
  }
}
