import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import TableTitle from "../../../component/atoms/text/table/tableTitle";
import DetailPageForm from "../../../component/atoms/layout/detail/detailPageForm";
import DetailFormTitle from "../../../component/atoms/text/detail/detailFormTitle";
import DetailFormText from "../../../component/atoms/text/detail/detailFormText";
import moment from "moment";
import SaveBtn from "../../../component/atoms/button/saveBtn";
import TextArea from "../../../component/atoms/input/textarea";
import { errorHandler } from "../../../util/error-handler";
import AskApi from "../../../api/ask.api";
import { toast } from "react-toastify";
import { toastMsg } from "../../../util/toastMsg";
import { nullCheck } from "../../../util/check";
import FileImgPreview from "../../../component/molecules/fileImgPreview";

function MarketDetailModal({ data, setModalOpen }) {
  const categoryTitle = useMemo(() => {
    const categoryList = data.categoryDtoList;
    const categoryTitleList = categoryList.map((item) => item.title);
    return categoryTitleList.join();
  }, [data]);

  return (
    <ModalWrapper>
      <ModalBox>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "30px",
          }}
        >
          <TableTitle>마켓 상세</TableTitle>
          <img
            style={{
              cursor: "pointer",
            }}
            src="/assets/admin/icons/ico_popup_close.svg"
            alt=""
            onClick={() => setModalOpen(false)}
          />
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <DetailPageForm>
            <DetailFormTitle>이메일</DetailFormTitle>
            <DetailFormText>{data.influencerInfo?.email}</DetailFormText>
          </DetailPageForm>
          <DetailPageForm>
            <DetailFormTitle>이름</DetailFormTitle>
            <DetailFormText>{data.influencerInfo?.name}</DetailFormText>
          </DetailPageForm>
          <DetailPageForm>
            <DetailFormTitle>닉네임</DetailFormTitle>
            <DetailFormText>{data.influencerInfo?.nickName}</DetailFormText>
          </DetailPageForm>
          <DetailPageForm>
            <DetailFormTitle>상태</DetailFormTitle>
            <DetailFormText>{data.influencerInfo?.isActive ? "활성" : "비활성"}</DetailFormText>
          </DetailPageForm>
          <DetailPageForm>
            <DetailFormTitle>개설일</DetailFormTitle>
            <DetailFormText>{moment(data.createdAt).format("YYYY/MM/DD")}</DetailFormText>
          </DetailPageForm>
          <DetailPageForm>
            <DetailFormTitle>마켓명</DetailFormTitle>
            <DetailFormText>{data.name}</DetailFormText>
          </DetailPageForm>
          <DetailPageForm>
            <DetailFormTitle>카테고리</DetailFormTitle>
            <DetailFormText>{categoryTitle}</DetailFormText>
          </DetailPageForm>
          <DetailPageForm style={{ alignItems: "flex-start" }}>
            <DetailFormTitle>프로필 이미지</DetailFormTitle>
            <FileImgPreview
              fileUrl={data.profileFileUrl}
              fileName={data.profileOriginFileName}
              serverFileName={data.profileServerFileName}
            />
          </DetailPageForm>
          <DetailPageForm style={{ alignItems: "flex-start" }}>
            <DetailFormTitle>배경 이미지</DetailFormTitle>
            <FileImgPreview
              fileUrl={data.bgFileUrl}
              fileName={data.bgOriginFileName}
              serverFileName={data.bgServerFileName}
            />
          </DetailPageForm>
          <DetailPageForm style={{ alignItems: "flex-start" }}>
            <DetailFormTitle>로고 이미지</DetailFormTitle>
            <FileImgPreview
              fileUrl={data.logoFileUrl}
              fileName={data.logoOriginFileName}
              serverFileName={data.logoServerFileName}
            />
          </DetailPageForm>
          <DetailPageForm style={{ alignItems: "flex-start" }}>
            <DetailFormTitle>마켓 요약 설명</DetailFormTitle>
            <DetailFormText>{data.explanation}</DetailFormText>
          </DetailPageForm>
          <DetailPageForm>
            <DetailFormTitle>SNS 유형</DetailFormTitle>
            <DetailFormText>{data.snsTypeTitle}</DetailFormText>
          </DetailPageForm>
          <DetailPageForm>
            <DetailFormTitle>SNS 아이디</DetailFormTitle>
            <DetailFormText>{data.snsId}</DetailFormText>
          </DetailPageForm>
          <DetailPageForm>
            <DetailFormTitle>SNS 링크</DetailFormTitle>
            <DetailFormText>{data.snsLink}</DetailFormText>
          </DetailPageForm>
        </div>
      </ModalBox>
    </ModalWrapper>
  );
}

export default MarketDetailModal;

const ModalWrapper = styled.div.attrs((props) => {})`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background-color: #00000060;
  position: absolute;
  top: 0;
  z-index: 30;
  padding-top: 31px;
`;

const ModalBox = styled.div.attrs((props) => {})`
  width: 1064px;
  max-height: 1018px;
  padding: 50px;
  border: 1px solid #d7dbe0;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
  position: relative;
`;
