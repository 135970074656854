import React from "react";
import styled from "styled-components";

function DetailPageForm(props) {
  return (
    <>
      <DetailPageFormWrapper
        style={{
          ...props.style,
        }}
      >
        {props.children}
      </DetailPageFormWrapper>
    </>
  );
}

export default DetailPageForm;

const DetailPageFormWrapper = styled.div.attrs((props) => {})`
  max-width: 964px;
  display: flex;
  align-items: center;
  position: relative;
`;
