import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Header from "../../../component/organisms/header";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import Sidebar from "../../../component/organisms/sidebar";
import TableLayout from "../../../component/atoms/layout/table/tableLayout";
import TableTitle from "../../../component/atoms/text/table/tableTitle";
import DetailPage from "../../../component/atoms/layout/detail/detailPage";
import DetailPageForm from "../../../component/atoms/layout/detail/detailPageForm";
import DetailTop from "../../../component/atoms/layout/detail/detailTop";
import DetailFormTitle from "../../../component/atoms/text/detail/detailFormTitle";
import DetailFormText from "../../../component/atoms/text/detail/detailFormText";
import EditBtn from "../../../component/atoms/button/editBtn";
import Modal from "../../../component/templates/Modal";
import { errorHandler } from "../../../util/error-handler";
import { toast } from "react-toastify";
import { toastMsg } from "../../../util/toastMsg";
import MainInput from "../../../component/atoms/input/mainInput";
import SaveBtn from "../../../component/atoms/button/saveBtn";
import DetailPageTitle from "../../../component/atoms/text/detail/detailPageTitle";
import { nullCheck } from "../../../util/check";
import BaseApi from "../../../api/base.api";

function BasicEdit(props) {
  const navigate = useNavigate();

  // init
  const getData = async () => {
    try {
      const response = await BaseApi.Get();
      setSaveData({
        ...response.data.data,
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const [saveData, setSaveData] = useState({
    businessName: "",
    representative: "",
    businessNumber: "",
    reportNumber: "",
    phoneNumber: "",
    address: "",
    email: "",
  });

  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const saveFunc = async () => {
    for (let key in saveData) {
      if (nullCheck(saveData[key])) {
        setSaveModalOpen(false);
        return toast("필수사항을 모두 입력해주세요.");
      }
    }

    try {
      const requestDto = {
        businessName: saveData.businessName,
        representative: saveData.representative,
        businessNumber: saveData.businessNumber,
        phoneNumber: saveData.phoneNumber,
        address: saveData.address,
        email: saveData.email,
        reportNumber: saveData.reportNumber,
      };

      await BaseApi.Update(requestDto);
      setSaveModalOpen(false);
      navigate(-1);
      toast(toastMsg.create);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <TableLayout>
          <DetailTop>
            <TableTitle>기본 관리</TableTitle>
          </DetailTop>
          <DetailPage style={{ minHeight: "602px" }}>
            <DetailPageTitle>기본 관리</DetailPageTitle>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                marginBottom: "79px",
              }}
            >
              <DetailPageForm>
                <DetailFormTitle>상호명*</DetailFormTitle>
                <MainInput
                  type={"text"}
                  placeholder={"상호명을 입력해주세요"}
                  value={saveData.businessName}
                  onChange={(e) => {
                    setSaveData({ ...saveData, businessName: e.target.value });
                  }}
                />
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>대표자*</DetailFormTitle>
                <MainInput
                  type={"text"}
                  placeholder={"대표자를 입력해주세요"}
                  value={saveData.representative}
                  onChange={(e) => {
                    setSaveData({ ...saveData, representative: e.target.value });
                  }}
                />
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>사업자등록번호*</DetailFormTitle>
                <MainInput
                  type={"text"}
                  placeholder={"사업자등록번호를 입력해주세요"}
                  value={saveData.businessNumber}
                  onChange={(e) => {
                    setSaveData({ ...saveData, businessNumber: e.target.value });
                  }}
                />
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>통신판매업신고번호*</DetailFormTitle>
                <MainInput
                  type={"text"}
                  placeholder={"통신판매업신고번호를 입력해주세요"}
                  value={saveData.reportNumber}
                  onChange={(e) => {
                    setSaveData({ ...saveData, reportNumber: e.target.value });
                  }}
                />
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>주소*</DetailFormTitle>
                <MainInput
                  type={"text"}
                  placeholder={"주소를 입력해주세요"}
                  value={saveData.address}
                  onChange={(e) => {
                    setSaveData({ ...saveData, address: e.target.value });
                  }}
                />
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>전화번호*</DetailFormTitle>
                <MainInput
                  type={"text"}
                  placeholder={"전화번호를 입력해주세요"}
                  value={saveData.phoneNumber}
                  onChange={(e) => {
                    setSaveData({ ...saveData, phoneNumber: e.target.value });
                  }}
                />
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>이메일*</DetailFormTitle>
                <MainInput
                  type={"text"}
                  placeholder={"이메일를 입력해주세요"}
                  value={saveData.email}
                  onChange={(e) => {
                    setSaveData({ ...saveData, email: e.target.value });
                  }}
                />
              </DetailPageForm>
            </div>
            <SaveBtn
              text={"저장하기"}
              style={{ bottom: "40px", right: "40px" }}
              onClick={() => {
                setSaveModalOpen(true);
              }}
            />
          </DetailPage>
        </TableLayout>

        {saveModalOpen ? (
          <Modal
            title={"저장하시겠습니까"}
            btnTitle={"저장하기"}
            setModalOpen={setSaveModalOpen}
            onClick={() => {
              saveFunc();
            }}
          />
        ) : null}
      </MainLayout>
    </>
  );
}

export default BasicEdit;
