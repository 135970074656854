import React from "react";
import styled from "styled-components";
import PretendardText from "../text/pretendardText";

function DetailBtn({ style, text, onClick }) {
  return (
    <>
      <DetailBtnWrapper style={{ ...style }} onClick={onClick}>
        <img src="/assets/admin/icons/ico_see_more_plus.svg" />
        <PretendardText>{text ?? "보기"}</PretendardText>
      </DetailBtnWrapper>
    </>
  );
}

export default DetailBtn;

const DetailBtnWrapper = styled.button.attrs((props) => {})`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 3px 8px;
  border-radius: 4px;
  border: 1px solid #636c73;
  background: #636c73;
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 600;
  line-height: 22.75px;
  color: #fff;
  cursor: pointer;
`;
