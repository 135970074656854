import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import Header from "../../../component/organisms/header";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import Sidebar from "../../../component/organisms/sidebar";
import DetailTop from "../../../component/atoms/layout/detail/detailTop";
import DetailPage from "../../../component/atoms/layout/detail/detailPage";
import DetailPageTitle from "../../../component/atoms/text/detail/detailPageTitle";
import DetailPageLayout from "../../../component/atoms/layout/detail/detailPageLayout";
import GoBack from "../../../component/molecules/goBack";
import EditBtn from "../../../component/atoms/button/editBtn";
import DetailPageForm from "../../../component/atoms/layout/detail/detailPageForm";
import DetailFormTitle from "../../../component/atoms/text/detail/detailFormTitle";
import DetailFormText from "../../../component/atoms/text/detail/detailFormText";
import { toast } from "react-toastify";
import { toastMsg } from "../../../util/toastMsg";
import { errorHandler } from "../../../util/error-handler";
import { route } from "../../../router/route";
import DelBtn from "../../../component/atoms/button/delBtn";
import Modal from "../../../component/templates/Modal";
import { modalMsg } from "../../../util/modalMsg";
import moment from "moment";
import FileImgPreview from "../../../component/molecules/fileImgPreview";
import TransactionApi from "../../../api/transaction.api";
import CategoryApi from "../../../api/category.api";
import Table from "../../../component/atoms/layout/table/table";
import Tr from "../../../component/atoms/layout/table/tr";
import Th from "../../../component/atoms/layout/table/th";
import Checkbox from "../../../component/atoms/input/checkbox";
import ThText from "../../../component/atoms/text/table/thText";
import Td from "../../../component/atoms/layout/table/td";
import TdText from "../../../component/atoms/text/table/tdText";
import DetailBtn from "../../../component/atoms/button/detailBtn";
import Pagenation from "../../../component/molecules/pagenation";
import Pagination2 from "../../../component/molecules/pagination2";
import MarketApi from "../../../api/market.api";
import PretendardText from "../../../component/atoms/text/pretendardText";
import MerchApi from "../../../api/merch.api";
import ReviewApi from "../../../api/review.api";
import TableDelBtn from "../../../component/atoms/button/tableDelBtn";
import TableFilterManager from "../../../component/atoms/layout/table/tableFilterManager";
import MarketRegisterDetailTab from "../../../component/templates/market/marketRegisterDetailTab";
import MarketDeleteDetailTab from "../../../component/templates/market/marketDeleteDetailTab";

function MarketDeleteDetailBasic(props) {
  const navigate = useNavigate();

  let { id } = useParams();

  const [data, setData] = useState({});

  // init
  const getData = async () => {
    try {
      const response = await MarketApi.GetByIdDeleted(id);
      setData({
        ...response.data.data,
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  // ----- 삭제
  const [delModalOpen, setDelModalOpen] = useState(false);

  const deleteOneFunc = async () => {
    try {
      await MarketApi.DeleteByIdHard(id);
      toast(toastMsg.delete);
      setDelModalOpen(false);
      navigate(-1);
    } catch (error) {
      errorHandler(error);
    }
  };

  const categoryListString = useMemo(() => {
    const categoryList = data.categoryDtoList;
    const categoryTitleList = categoryList?.map((item) => item.title);
    return categoryTitleList?.join(", ");
  }, [data]);

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <MarketDeleteDetailTab />

          <>
            <DetailTop>
              <GoBack />
              <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
                <DelBtn onClick={() => setDelModalOpen(true)} />
                <EditBtn
                  onClick={() => {
                    navigate(route.market_register_edit_basic + `/${id}`);
                  }}
                />
              </div>
            </DetailTop>

            <DetailPage style={{ minHeight: "360px", marginBottom: "20px" }}>
              <DetailPageTitle>기본정보</DetailPageTitle>
              <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                <DetailPageForm>
                  <DetailFormTitle>이메일</DetailFormTitle>
                  <DetailFormText>{data.influencerInfo?.email}</DetailFormText>
                </DetailPageForm>
                <DetailPageForm>
                  <DetailFormTitle>이름</DetailFormTitle>
                  <DetailFormText>{data.influencerInfo?.name}</DetailFormText>
                </DetailPageForm>
                <DetailPageForm>
                  <DetailFormTitle>닉네임</DetailFormTitle>
                  <DetailFormText>{data.influencerInfo?.nickName}</DetailFormText>
                </DetailPageForm>
                <DetailPageForm>
                  <DetailFormTitle>상태</DetailFormTitle>
                  <DetailFormText>{data.influencerInfo?.isActive ? "활성" : "비활성"}</DetailFormText>
                </DetailPageForm>
                <DetailPageForm>
                  <DetailFormTitle>개설일</DetailFormTitle>
                  <DetailFormText>{moment(data.createdAt).format("YYYY/MM/DD")}</DetailFormText>
                </DetailPageForm>
                <DetailPageForm>
                  <DetailFormTitle>마켓명</DetailFormTitle>
                  <DetailFormText>{data.name}</DetailFormText>
                </DetailPageForm>
                <DetailPageForm>
                  <DetailFormTitle>카테고리</DetailFormTitle>
                  <DetailFormText>{categoryListString}</DetailFormText>
                </DetailPageForm>
                <DetailPageForm style={{ alignItems: "flex-start" }}>
                  <DetailFormTitle>프로필 이미지</DetailFormTitle>
                  <FileImgPreview fileUrl={data.profileFileUrl} fileName={data.profileOriginFileName} serverFileName={data.profileServerFileName} />
                </DetailPageForm>
                <DetailPageForm style={{ alignItems: "flex-start" }}>
                  <DetailFormTitle>배경 이미지</DetailFormTitle>
                  <FileImgPreview fileUrl={data.bgFileUrl} fileName={data.bgOriginFileName} serverFileName={data.bgServerFileName} />
                </DetailPageForm>
                <DetailPageForm style={{ alignItems: "flex-start" }}>
                  <DetailFormTitle>로고 이미지</DetailFormTitle>
                  <FileImgPreview fileUrl={data.logoFileUrl} fileName={data.logoOriginFileName} serverFileName={data.logoServerFileName} />
                </DetailPageForm>
                <DetailPageForm style={{ alignItems: "flex-start" }}>
                  <DetailFormTitle>마켓 요약 설명</DetailFormTitle>
                  <DetailFormText>{data.explanation}</DetailFormText>
                </DetailPageForm>
                <DetailPageForm>
                  <DetailFormTitle>SNS 유형</DetailFormTitle>
                  <DetailFormText>{data.snsTypeTitle}</DetailFormText>
                </DetailPageForm>
                <DetailPageForm>
                  <DetailFormTitle>SNS 아이디</DetailFormTitle>
                  <DetailFormText>{data.snsId}</DetailFormText>
                </DetailPageForm>
                <DetailPageForm>
                  <DetailFormTitle>SNS 링크</DetailFormTitle>
                  <DetailFormText>{data.snsLink}</DetailFormText>
                </DetailPageForm>
                <DetailPageForm>
                  <DetailFormTitle>추천마켓 여부</DetailFormTitle>
                  <DetailFormText>{data.isRecommended ? "예" : "아니오"}</DetailFormText>
                </DetailPageForm>
              </div>
            </DetailPage>
          </>
        </DetailPageLayout>
      </MainLayout>

      {delModalOpen ? (
        <Modal
          title={modalMsg.delete?.title}
          text={modalMsg.delete?.content}
          btnTitle={"삭제하기"}
          setModalOpen={setDelModalOpen}
          onClick={() => {
            deleteOneFunc();
          }}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default MarketDeleteDetailBasic;
