import api from "./api.js";

export default class FaqApi {
  static async Create(data) {
    return await api.post(`admin/faq`, data);
  }
  static async GetList(params) {
    return await api.get(`admin/faqs`, params);
  }
  static async GetById(faqId) {
    return await api.get(`admin/faq/${faqId}`);
  }
  static async Update(faqId, data) {
    return await api.put(`admin/faq/${faqId}`, data);
  }
  static async Delete(data) {
    return await api.del(`admin/faqs`, "", data);
  }
  static async DeleteById(faqId) {
    return await api.del(`admin/faq/${faqId}`);
  }
}
