import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import Header from "../../../component/organisms/header";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import Sidebar from "../../../component/organisms/sidebar";
import DetailTop from "../../../component/atoms/layout/detail/detailTop";
import DetailPage from "../../../component/atoms/layout/detail/detailPage";
import DetailPageTitle from "../../../component/atoms/text/detail/detailPageTitle";
import DetailPageLayout from "../../../component/atoms/layout/detail/detailPageLayout";
import GoBack from "../../../component/molecules/goBack";
import EditBtn from "../../../component/atoms/button/editBtn";
import DetailPageForm from "../../../component/atoms/layout/detail/detailPageForm";
import DetailFormTitle from "../../../component/atoms/text/detail/detailFormTitle";
import DetailFormText from "../../../component/atoms/text/detail/detailFormText";
import { toast } from "react-toastify";
import { toastMsg } from "../../../util/toastMsg";
import { errorHandler } from "../../../util/error-handler";
import { route } from "../../../router/route";
import DelBtn from "../../../component/atoms/button/delBtn";
import Modal from "../../../component/templates/Modal";
import { modalMsg } from "../../../util/modalMsg";
import moment from "moment";
import FileImgPreview from "../../../component/molecules/fileImgPreview";
import PurchaseApi from "../../../api/purchase.api";
import MemberApi from "../../../api/member.api";

function RefundDetail(props) {
  const navigate = useNavigate();
  let { id } = useParams();

  const [data, setData] = useState({
    merchInfo: "",
    purchaseStatus: "",
    purchaseStatusTitle: "",
    accountOwner: "",
    bank: "",
    account: "",
    merchNum: "",
    buyerNum: "",
    marketNum: "",
    marketName: "",
    merchName: "",
    buyerName: "",
    amount: "",
    sellerNum: "",
    calculated: "",
    calculateId: "",
    deliveryFee: "",
    price: "",
    images: "",
  });

  const [buyerInfo, setBuyerInfo] = useState({
    email: "",
    name: "",
    nickName: "",
    phoneNumber: "",
  });

  // init
  const getData = async () => {
    try {
      const response = await PurchaseApi.GetById(id);
      setData({
        ...response.data.data,
      });
      const response2 = await MemberApi.GetById(response.data.data.buyerNum);
      setBuyerInfo({
        ...response2.data.data,
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  // ----- 삭제
  const [delModalOpen, setDelModalOpen] = useState(false);

  const deleteFunc = async () => {
    try {
      await PurchaseApi.DeleteById(id);
      toast(toastMsg.delete);
      setDelModalOpen(false);
      navigate(-1);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <GoBack />
            <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
              <DelBtn onClick={() => setDelModalOpen(true)} />
            </div>
          </DetailTop>
          <DetailPage style={{ minHeight: "630px", marginBottom: "20px" }}>
            <DetailPageTitle>상세보기</DetailPageTitle>
            <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
              <DetailPageForm>
                <DetailFormTitle>이메일</DetailFormTitle>
                <DetailFormText>{buyerInfo.email}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>이름</DetailFormTitle>
                <DetailFormText>{buyerInfo.name}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>닉네임</DetailFormTitle>
                <DetailFormText>{buyerInfo.nickName}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>연락처</DetailFormTitle>
                <DetailFormText>{buyerInfo.phoneNumber}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>입금은행</DetailFormTitle>
                <DetailFormText>{data.bank}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>입금계좌</DetailFormTitle>
                <DetailFormText>{data.account}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>입금자명</DetailFormTitle>
                <DetailFormText>{data.accountOwner}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>입금금액</DetailFormTitle>
                <DetailFormText>{Number(data.amount).toLocaleString("ko-KR")}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>입금 인증 이미지</DetailFormTitle>
                <div style={{ display: "flex", gap: "5px" }}>
                  {data.images.length > 0
                    ? data.images.map((item, index) => {
                        return (
                          <FileImgPreview
                            key={index}
                            fileUrl={item.fileUrl}
                            fileName={item.originFileName}
                            serverFileName={item.serverFileName}
                          />
                        );
                      })
                    : null}
                </div>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>환불 상태</DetailFormTitle>
                <DetailFormText>{data.purchaseStatusTitle}</DetailFormText>
              </DetailPageForm>
            </div>
          </DetailPage>
        </DetailPageLayout>
      </MainLayout>
      {delModalOpen ? (
        <Modal
          title={modalMsg.delete?.title}
          text={modalMsg.delete?.content}
          btnTitle={"삭제하기"}
          setModalOpen={setDelModalOpen}
          onClick={() => {
            deleteFunc();
          }}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default RefundDetail;
