import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Header from "../../component/organisms/header";
import MainLayout from "../../component/atoms/layout/mainLayout";
import Sidebar from "../../component/organisms/sidebar";
import DetailTop from "../../component/atoms/layout/detail/detailTop";
import DetailPage from "../../component/atoms/layout/detail/detailPage";
import DetailPageTitle from "../../component/atoms/text/detail/detailPageTitle";
import DetailPageLayout from "../../component/atoms/layout/detail/detailPageLayout";
import GoBack from "../../component/molecules/goBack";
import DetailPageForm from "../../component/atoms/layout/detail/detailPageForm";
import DetailFormTitle from "../../component/atoms/text/detail/detailFormTitle";
import MainInput from "../../component/atoms/input/mainInput";
import SaveBtn from "../../component/atoms/button/saveBtn";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { toastMsg } from "../../util/toastMsg";
import Radio from "../../component/atoms/input/radio";
import Checkbox from "../../component/atoms/input/checkbox";
import DetailFormText from "../../component/atoms/text/detail/detailFormText";
import { errorHandler } from "../../util/error-handler";
import { nullCheck } from "../../util/check";
import { route } from "../../router/route";
import { regExpression } from "../../util/reg-expresstion";
import PretendardText from "../../component/atoms/text/pretendardText";
import Modal from "../../component/templates/Modal";
import { modalMsg } from "../../util/modalMsg";
import ManagerApi from "../../api/manager.api";

function RootEdit(props) {
  const navigate = useNavigate();
  let { id } = useParams();

  const [saveData, setSaveData] = useState({
    name: "",
    email: "",
    password: "",
    passwordCheck: "",
    managerAuthority: "",
    accessMenu: [],
    passwordChangeFlag: false,
  });
  const [pwEdit, setPwEdit] = useState(false);
  const [PwErr, setPwErr] = useState(false);
  const [PwCheckErr, setPwCheckErr] = useState(false);

  // init
  const getData = async () => {
    try {
      const response = await ManagerApi.GetById(id);
      const data = response.data.data;
      setSaveData({
        ...data,
        accessMenu: data.accessMenu ? JSON.parse(data.accessMenu) : [],
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  //비밀번호 체크
  useEffect(() => {
    if (saveData.password !== "" && !regExpression.password.test(saveData.password)) {
      setPwErr(true);
    } else setPwErr(false);
  }, [saveData.password]);

  //비밀번호 확인 체크
  useEffect(() => {
    if (saveData.passwordCheck !== "" && saveData.password !== saveData.passwordCheck) {
      setPwCheckErr(true);
    } else setPwCheckErr(false);
  }, [saveData.passwordCheck]);

  //저장하기
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const saveFunc = async () => {
    try {
      if (nullCheck(saveData.name)) {
        setSaveModalOpen(false);
        return toast("이름을 입력해주세요.");
      }
      if (pwEdit) {
        if (nullCheck(saveData.password)) {
          setSaveModalOpen(false);
          return toast("비밀번호를 입력해주세요");
        }
        if (nullCheck(saveData.passwordCheck)) {
          setSaveModalOpen(false);
          return toast("비밀번호를 입력해주세요");
        }
        if (PwErr) {
          setSaveModalOpen(false);
          return toast("비밀번호를 올바르게 입력해주세요");
        }
        if (PwCheckErr) {
          setSaveModalOpen(false);
          return toast("비밀번호가 일치하지 않습니다");
        }
      }

      const requestDto = {
        email: saveData.email,
        name: saveData.name,
        managerAuthority: saveData.managerAuthority,
        accessMenu: JSON.stringify(saveData.accessMenu),
        password: "",
        passwordChangeFlag: false,
      };

      await ManagerApi.Update(id, requestDto);
      toast(toastMsg.update);
      navigate(-1);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <GoBack />
          </DetailTop>
          <DetailPage style={{ paddingBottom: "83px", minHeight: "762px" }}>
            <DetailPageTitle>수정하기</DetailPageTitle>
            <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
              <DetailPageForm>
                <DetailFormTitle>이름*</DetailFormTitle>
                <MainInput
                  type={"text"}
                  placeholder={"이름을 입력해주세요"}
                  value={saveData.name}
                  onChange={(e) => {
                    setSaveData({ ...saveData, name: e.target.value });
                  }}
                />
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>이메일</DetailFormTitle>
                <DetailFormText>{saveData.email}</DetailFormText>
              </DetailPageForm>
              {pwEdit ? (
                <>
                  <DetailPageForm>
                    <DetailFormTitle>비밀번호*</DetailFormTitle>
                    <MainInput
                      type={"password"}
                      placeholder={"새로운 비밀번호를 입력해 주세요."}
                      helperText={"영문/숫자/특수문자 중 2가지 이상 포함, 8자 ~ 16자"}
                      onError={PwErr}
                      onChange={(e) => {
                        setSaveData({ ...saveData, password: e.target.value });
                      }}
                    />
                  </DetailPageForm>
                  <DetailPageForm>
                    <DetailFormTitle>비밀번호 재입력*</DetailFormTitle>
                    <MainInput
                      type={"password"}
                      placeholder={"새로운 비밀번호를 한 번 더 입력해 주세요."}
                      helperText={"비밀번호가 일치하지 않습니다"}
                      onError={PwCheckErr}
                      onChange={(e) => {
                        setSaveData({ ...saveData, passwordCheck: e.target.value });
                      }}
                    />
                  </DetailPageForm>
                </>
              ) : (
                <DetailPageForm>
                  <DetailFormTitle>비밀번호</DetailFormTitle>
                  <PwEditBtn
                    onClick={() => {
                      setPwEdit(true);
                    }}
                  >
                    비밀번호 변경
                  </PwEditBtn>
                </DetailPageForm>
              )}

              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>관리자 유형*</DetailFormTitle>
                <div style={{ display: "flex", gap: "19px" }}>
                  <Radio
                    id={"ROOT"}
                    valueText={"ROOT"}
                    name={"author"}
                    checked={saveData.managerAuthority === "ROOT"}
                    onChange={() => {
                      setSaveData({ ...saveData, managerAuthority: "ROOT" });
                    }}
                  />
                  <Radio
                    id={"ADMIN"}
                    valueText={"ADMIN"}
                    name={"author"}
                    checked={saveData.managerAuthority === "ADMIN"}
                    onChange={() => {
                      setSaveData({ ...saveData, managerAuthority: "ADMIN" });
                    }}
                  />
                </div>
              </DetailPageForm>
              <PretendardText
                style={{
                  color: "#FF4975",
                  fontSize: "16px",
                  fontWeight: "600",
                  lineHeight: "22.4px",
                  letterSpacing: "-0.408px",
                }}
              >
                *ROOT일 경우 아래 접근권한 설정에 관계없이 모든 메뉴 접근이 가능합니다.
              </PretendardText>
              <DetailPageForm style={{ alignItems: "flex-start", marginBottom: "79px" }}>
                <DetailFormTitle>접근권한*</DetailFormTitle>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "22px",
                  }}
                >
                  <div>
                    <CheckboxListTitle>사용자 관리</CheckboxListTitle>
                    <div style={{ display: "flex", gap: "20px" }}>
                      <CheckboxWrapper>
                        <Checkbox
                          style={{
                            width: "18px",
                            height: "18px",
                          }}
                          checked={saveData.accessMenu.includes(route.member)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              saveData.accessMenu.push(route.member);
                            } else {
                              saveData.accessMenu = saveData.accessMenu.filter((item) => item !== route.member);
                            }
                            setSaveData({ ...saveData });
                          }}
                        />
                        <DetailFormText style={{ color: "#262C31", fontWeight: "500" }}>일반회원 관리</DetailFormText>
                      </CheckboxWrapper>
                      <CheckboxWrapper>
                        <Checkbox
                          style={{
                            width: "18px",
                            height: "18px",
                          }}
                          checked={saveData.accessMenu.includes(route.member_influencer)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              saveData.accessMenu.push(route.member_influencer);
                            } else {
                              saveData.accessMenu = saveData.accessMenu.filter(
                                (item) => item !== route.member_influencer
                              );
                            }
                            setSaveData({ ...saveData });
                          }}
                        />
                        <DetailFormText style={{ color: "#262C31", fontWeight: "500" }}>인플루언서 관리</DetailFormText>
                      </CheckboxWrapper>
                      <CheckboxWrapper>
                        <Checkbox
                          style={{
                            width: "18px",
                            height: "18px",
                          }}
                          checked={saveData.accessMenu.includes(route.member_leave)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              saveData.accessMenu.push(route.member_leave);
                            } else {
                              saveData.accessMenu = saveData.accessMenu.filter((item) => item !== route.member_leave);
                            }
                            setSaveData({ ...saveData });
                          }}
                        />
                        <DetailFormText style={{ color: "#262C31", fontWeight: "500" }}>탈퇴회원 관리</DetailFormText>
                      </CheckboxWrapper>
                    </div>
                  </div>
                  <div>
                    <CheckboxListTitle>마켓 관리</CheckboxListTitle>
                    <div style={{ display: "flex", gap: "20px" }}>
                      <CheckboxWrapper>
                        <Checkbox
                          style={{
                            width: "18px",
                            height: "18px",
                          }}
                          checked={saveData.accessMenu.includes(route.market_register)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              saveData.accessMenu.push(route.market_register);
                            } else {
                              saveData.accessMenu = saveData.accessMenu.filter(
                                (item) => item !== route.market_register
                              );
                            }
                            setSaveData({ ...saveData });
                          }}
                        />
                        <DetailFormText style={{ color: "#262C31", fontWeight: "500" }}>
                          등록된 마켓 관리
                        </DetailFormText>
                      </CheckboxWrapper>
                      <CheckboxWrapper>
                        <Checkbox
                          style={{
                            width: "18px",
                            height: "18px",
                          }}
                          checked={saveData.accessMenu.includes(route.market_delete)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              saveData.accessMenu.push(route.market_delete);
                            } else {
                              saveData.accessMenu = saveData.accessMenu.filter((item) => item !== route.market_delete);
                            }
                            setSaveData({ ...saveData });
                          }}
                        />
                        <DetailFormText style={{ color: "#262C31", fontWeight: "500" }}>
                          삭제된 마켓 관리
                        </DetailFormText>
                      </CheckboxWrapper>
                      <CheckboxWrapper>
                        <Checkbox
                          style={{
                            width: "18px",
                            height: "18px",
                          }}
                          checked={saveData.accessMenu.includes(route.market_category)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              saveData.accessMenu.push(route.market_category);
                            } else {
                              saveData.accessMenu = saveData.accessMenu.filter(
                                (item) => item !== route.market_category
                              );
                            }
                            setSaveData({ ...saveData });
                          }}
                        />
                        <DetailFormText style={{ color: "#262C31", fontWeight: "500" }}>카테고리 관리</DetailFormText>
                      </CheckboxWrapper>
                      <CheckboxWrapper>
                        <Checkbox
                          style={{
                            width: "18px",
                            height: "18px",
                          }}
                          checked={saveData.accessMenu.includes(route.market_setting)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              saveData.accessMenu.push(route.market_setting);
                            } else {
                              saveData.accessMenu = saveData.accessMenu.filter((item) => item !== route.market_setting);
                            }
                            setSaveData({ ...saveData });
                          }}
                        />
                        <DetailFormText style={{ color: "#262C31", fontWeight: "500" }}>마켓 설정</DetailFormText>
                      </CheckboxWrapper>
                    </div>
                  </div>
                  <div>
                    <CheckboxListTitle>거래 관리</CheckboxListTitle>
                    <div style={{ display: "flex", gap: "20px" }}>
                      <CheckboxWrapper>
                        <Checkbox
                          style={{
                            width: "18px",
                            height: "18px",
                          }}
                          checked={saveData.accessMenu.includes(route.transaction_purchase)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              saveData.accessMenu.push(route.transaction_purchase);
                            } else {
                              saveData.accessMenu = saveData.accessMenu.filter(
                                (item) => item !== route.transaction_purchase
                              );
                            }
                            setSaveData({ ...saveData });
                          }}
                        />
                        <DetailFormText style={{ color: "#262C31", fontWeight: "500" }}>거래내역 관리</DetailFormText>
                      </CheckboxWrapper>
                      <CheckboxWrapper>
                        <Checkbox
                          style={{
                            width: "18px",
                            height: "18px",
                          }}
                          checked={saveData.accessMenu.includes(route.transaction_refund)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              saveData.accessMenu.push(route.transaction_refund);
                            } else {
                              saveData.accessMenu = saveData.accessMenu.filter(
                                (item) => item !== route.transaction_refund
                              );
                            }
                            setSaveData({ ...saveData });
                          }}
                        />
                        <DetailFormText style={{ color: "#262C31", fontWeight: "500" }}>환불내역 관리</DetailFormText>
                      </CheckboxWrapper>
                      <CheckboxWrapper>
                        <Checkbox
                          style={{
                            width: "18px",
                            height: "18px",
                          }}
                          checked={saveData.accessMenu.includes(route.transaction_settle)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              saveData.accessMenu.push(route.transaction_settle);
                            } else {
                              saveData.accessMenu = saveData.accessMenu.filter(
                                (item) => item !== route.transaction_settle
                              );
                            }
                            setSaveData({ ...saveData });
                          }}
                        />
                        <DetailFormText style={{ color: "#262C31", fontWeight: "500" }}>정산 요청 관리</DetailFormText>
                      </CheckboxWrapper>
                    </div>
                  </div>
                  <div>
                    <CheckboxListTitle>기타 관리</CheckboxListTitle>
                    <div style={{ display: "flex", gap: "20px" }}>
                      <CheckboxWrapper>
                        <Checkbox
                          style={{
                            width: "18px",
                            height: "18px",
                          }}
                          checked={saveData.accessMenu.includes(route.etc_ads)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              saveData.accessMenu.push(route.etc_ads);
                            } else {
                              saveData.accessMenu = saveData.accessMenu.filter((item) => item !== route.etc_ads);
                            }
                            setSaveData({ ...saveData });
                          }}
                        />
                        <DetailFormText style={{ color: "#262C31", fontWeight: "500" }}>광고 관리</DetailFormText>
                      </CheckboxWrapper>
                    </div>
                  </div>
                  <div>
                    <CheckboxListTitle>서비스 관리</CheckboxListTitle>
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "10px 20px",
                        maxWidth: "645px",
                      }}
                    >
                      <CheckboxWrapper>
                        <Checkbox
                          style={{
                            width: "18px",
                            height: "18px",
                          }}
                          checked={saveData.accessMenu.includes(route.service_alarm)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              saveData.accessMenu.push(route.service_alarm);
                            } else {
                              saveData.accessMenu = saveData.accessMenu.filter((item) => item !== route.service_alarm);
                            }
                            setSaveData({ ...saveData });
                          }}
                        />
                        <DetailFormText style={{ color: "#262C31", fontWeight: "500" }}>알림 관리</DetailFormText>
                      </CheckboxWrapper>
                      <CheckboxWrapper>
                        <Checkbox
                          style={{
                            width: "18px",
                            height: "18px",
                          }}
                          checked={saveData.accessMenu.includes(route.service_ask)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              saveData.accessMenu.push(route.service_ask);
                            } else {
                              saveData.accessMenu = saveData.accessMenu.filter((item) => item !== route.service_ask);
                            }
                            setSaveData({ ...saveData });
                          }}
                        />
                        <DetailFormText style={{ color: "#262C31", fontWeight: "500" }}>1:1문의 관리</DetailFormText>
                      </CheckboxWrapper>
                      <CheckboxWrapper>
                        <Checkbox
                          style={{
                            width: "18px",
                            height: "18px",
                          }}
                          checked={saveData.accessMenu.includes(route.service_notice)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              saveData.accessMenu.push(route.service_notice);
                            } else {
                              saveData.accessMenu = saveData.accessMenu.filter((item) => item !== route.service_notice);
                            }
                            setSaveData({ ...saveData });
                          }}
                        />
                        <DetailFormText style={{ color: "#262C31", fontWeight: "500" }}>공지사항 관리</DetailFormText>
                      </CheckboxWrapper>
                      <CheckboxWrapper>
                        <Checkbox
                          style={{
                            width: "18px",
                            height: "18px",
                          }}
                          checked={saveData.accessMenu.includes(route.service_faq)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              saveData.accessMenu.push(route.service_faq);
                            } else {
                              saveData.accessMenu = saveData.accessMenu.filter((item) => item !== route.service_faq);
                            }
                            setSaveData({ ...saveData });
                          }}
                        />
                        <DetailFormText style={{ color: "#262C31", fontWeight: "500" }}>FAQ 관리</DetailFormText>
                      </CheckboxWrapper>
                      <CheckboxWrapper>
                        <Checkbox
                          style={{
                            width: "18px",
                            height: "18px",
                          }}
                          checked={saveData.accessMenu.includes(route.service_popup)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              saveData.accessMenu.push(route.service_popup);
                            } else {
                              saveData.accessMenu = saveData.accessMenu.filter((item) => item !== route.service_popup);
                            }
                            setSaveData({ ...saveData });
                          }}
                        />
                        <DetailFormText style={{ color: "#262C31", fontWeight: "500" }}>팝업 관리</DetailFormText>
                      </CheckboxWrapper>
                      <CheckboxWrapper>
                        <Checkbox
                          style={{
                            width: "18px",
                            height: "18px",
                          }}
                          checked={saveData.accessMenu.includes(route.service_terms)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              saveData.accessMenu.push(route.service_terms);
                            } else {
                              saveData.accessMenu = saveData.accessMenu.filter((item) => item !== route.service_terms);
                            }
                            setSaveData({ ...saveData });
                          }}
                        />
                        <DetailFormText style={{ color: "#262C31", fontWeight: "500" }}>약관 관리</DetailFormText>
                      </CheckboxWrapper>
                      <CheckboxWrapper>
                        <Checkbox
                          style={{
                            width: "18px",
                            height: "18px",
                          }}
                          checked={saveData.accessMenu.includes(route.service_openSource)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              saveData.accessMenu.push(route.service_openSource);
                            } else {
                              saveData.accessMenu = saveData.accessMenu.filter(
                                (item) => item !== route.service_openSource
                              );
                            }
                            setSaveData({ ...saveData });
                          }}
                        />
                        <DetailFormText style={{ color: "#262C31", fontWeight: "500" }}>오픈소스 관리</DetailFormText>
                      </CheckboxWrapper>
                      <CheckboxWrapper>
                        <Checkbox
                          style={{
                            width: "18px",
                            height: "18px",
                          }}
                          checked={saveData.accessMenu.includes(route.service_basic)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              saveData.accessMenu.push(route.service_basic);
                            } else {
                              saveData.accessMenu = saveData.accessMenu.filter((item) => item !== route.service_basic);
                            }
                            setSaveData({ ...saveData });
                          }}
                        />
                        <DetailFormText style={{ color: "#262C31", fontWeight: "500" }}>기본 관리</DetailFormText>
                      </CheckboxWrapper>
                    </div>
                  </div>
                  <div>
                    <CheckboxListTitle>관리자회원 관리</CheckboxListTitle>
                    <div style={{ display: "flex", gap: "20px" }}>
                      <CheckboxWrapper>
                        <Checkbox
                          style={{
                            width: "18px",
                            height: "18px",
                          }}
                          checked={saveData.accessMenu.includes(route.manager)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              saveData.accessMenu.push(route.manager);
                            } else {
                              saveData.accessMenu = saveData.accessMenu.filter((item) => item !== route.manager);
                            }
                            setSaveData({ ...saveData });
                          }}
                        />
                        <DetailFormText style={{ color: "#262C31", fontWeight: "500" }}>관리자회원 관리</DetailFormText>
                      </CheckboxWrapper>
                    </div>
                  </div>
                </div>
              </DetailPageForm>
            </div>
            <SaveBtn
              style={{ bottom: "40px", right: "40px" }}
              onClick={() => {
                setSaveModalOpen(true);
              }}
            />
          </DetailPage>
        </DetailPageLayout>
      </MainLayout>
      {saveModalOpen ? (
        <Modal
          title={modalMsg.update?.title}
          text={modalMsg.update?.content}
          setModalOpen={setSaveModalOpen}
          onClick={saveFunc}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default RootEdit;

const CheckboxWrapper = styled.div.attrs((props) => {})`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const PwEditBtn = styled.button.attrs((props) => {})`
  width: 338px;
  height: 48px;
  display: flex;
  padding: 13px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  border: 1px solid #b8bfc4;
  background-color: transparent;
  color: #808991;
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
`;

const CheckboxListTitle = styled(PretendardText).attrs((props) => {})`
  color: #808991;
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.039px;
  margin-bottom: 5px;
`;
