import api from "./api.js";

export default class MarketApi {
  // 등록된
  static async GetList(params) {
    return await api.get(`admin/markets`, params);
  }
  static async GetById(marketId) {
    return await api.get(`admin/market/${marketId}`);
  }
  static async Update(marketId, data) {
    return await api.put(`admin/market/${marketId}`, data);
  }
  static async DeleteByIdSoft(marketId) {
    return await api.del(`admin/market/soft/${marketId}`);
  }

  // 삭제된
  static async GetListDeleted(params) {
    return await api.get(`admin/markets/deleted`, params);
  }
  static async GetByIdDeleted(marketId) {
    return await api.get(`admin/market/deleted/${marketId}`);
  }
  static async DeleteByIdHard(marketId) {
    return await api.del(`admin/market/hard/${marketId}`);
  }

  // 세팅
  static async GetAccount() {
    return await api.get(`/account`);
  }
  static async GetRefundPolicy() {
    return await api.get(`/refund_policy`);
  }
  static async UpdateAccount(data) {
    return await api.put(`admin/account`, data);
  }
  static async UpdateRefundPolicy(data) {
    return await api.put(`admin/refund_policy`, data);
  }
  static async Activation(marketId) {
    return await api.put(`admin/market/activate/${marketId}`);
  }
  static async DeActivation(marketId) {
    return await api.put(`admin/market/deactivate/${marketId}`);
  }
}
