import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import Header from "../../../component/organisms/header";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import Sidebar from "../../../component/organisms/sidebar";
import TableLayout from "../../../component/atoms/layout/table/tableLayout";
import TableTitle from "../../../component/atoms/text/table/tableTitle";
import TableTop from "../../../component/atoms/layout/table/tableTop";
import TableSearchBtn from "../../../component/atoms/layout/table/tableSearchBtn";
import TableCount from "../../../component/molecules/tableCount";
import SearchBar from "../../../component/atoms/input/searchBar";
import Table from "../../../component/atoms/layout/table/table";
import Tr from "../../../component/atoms/layout/table/tr";
import Checkbox from "../../../component/atoms/input/checkbox";
import Th from "../../../component/atoms/layout/table/th";
import Td from "../../../component/atoms/layout/table/td";
import Pagenation from "../../../component/molecules/pagenation";
import { toast } from "react-toastify";
import { toastMsg } from "../../../util/toastMsg";
import moment from "moment";
import Modal from "../../../component/templates/Modal";
import { errorHandler } from "../../../util/error-handler";
import { route } from "../../../router/route";
import TableFilterManager from "../../../component/atoms/layout/table/tableFilterManager";
import ThText from "../../../component/atoms/text/table/thText";
import TdText from "../../../component/atoms/text/table/tdText";
import DelBtn from "../../../component/atoms/button/delBtn";
import DetailBtn from "../../../component/atoms/button/detailBtn";
import PretendardText from "../../../component/atoms/text/pretendardText";
import { modalMsg } from "../../../util/modalMsg";
import AddBtn from "../../../component/atoms/button/addBtn";
import TableDelBtn from "../../../component/atoms/button/tableDelBtn";
import ExcelBtn from "../../../component/atoms/button/excelBtn";
import TransactionApi from "../../../api/transaction.api";
import MarketApi from "../../../api/market.api";
import { saveAs } from "file-saver";
import ExcelApi from "../../../api/excel.api";

function MarketDelete(props) {
  const navigate = useNavigate();
  const location = useLocation();

  const { page } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [pagination, setPagination] = useState({
    size: 10, // 한 페이지에 표시될 게시글 수
    rangeSize: 5, // 하단 페이지 사이즈

    totalCount: 0,
    totalPages: 0,
    first: false,
    last: false,
  });
  const [list, setList] = useState([]);
  const [keyword, setKeyword] = useState("");

  const getList = async () => {
    try {
      const data = {
        keyword: searchParams.get("keyword"),
        page: page - 1,
        size: pagination.size,
      };
      let list = (await MarketApi.GetListDeleted(data)).data.data;
      setList(
        list.content.map((item) => {
          const categoryList = item.categoryDtoList;
          const categoryTitleList = categoryList.map((data) => data.title);
          return {
            ...item,
            checked: false,
            categoryTitle: categoryTitleList.join(),
          };
        })
      );
      setPagination({
        ...pagination,
        totalCount: list.totalElements,
        totalPages: list.totalPages,
        first: list.first,
        last: list.last,
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    setKeyword(searchParams.get("keyword") ? searchParams.get("keyword") : "");
    getList();
  }, [location]);

  // ----- 삭제
  const [delId, setDelId] = useState(0);
  const [delModalOpen, setDelModalOpen] = useState(false);

  const deleteOneFunc = async () => {
    try {
      await MarketApi.DeleteByIdHard(delId);
      toast(toastMsg.delete);
      setDelModalOpen(false);
      setDelId(0);
      getList();
    } catch (error) {
      errorHandler(error);
    }
  };

  //------------엑셀 생성
  const downloadExcel = async (excelData) => {
    const Excel = require("exceljs");
    const workbook = new Excel.Workbook();
    workbook.addWorksheet("Sheet One");
    const sheetOne = workbook.getWorksheet("Sheet One");

    sheetOne.columns = [
      { header: "이메일", key: "email", width: 40 },
      { header: "마켓명", key: "name", width: 40 },
      { header: "카테고리", key: "categoryTitle", width: 40 },
      { header: "삭제일", key: "createdAt", width: 40 },
    ];

    excelData.map((item) => {
      // 행 추가
      sheetOne.addRow(item);
    });
    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), "삭제된 마켓 관리.xlsx");
  };

  const getExcelAll = async () => {
    try {
      const excelArr = [];
      const response = (await ExcelApi.GetDelMarketAll()).data.data;

      response.map((item) => {
        const email = item.influencerInfo?.email;
        const createDate = moment(item.createdAt).format("YYYY/MM/DD");
        const categoryTitle = item.categoryDtoList
          ?.map((item02) => {
            return item02.title;
          })
          .join(", ");
        excelArr.unshift({
          ...item,
          createdAt: createDate,
          email: email,
          categoryTitle: categoryTitle,
        });
      });

      downloadExcel(excelArr);
    } catch (error) {
      errorHandler(error);
    }
  };

  const getExcelChecked = async () => {
    const filter = list.filter((item) => item.checked);
    if (filter.length <= 0) {
      return toast("항목을 선택해주세요");
    }
    let queryString = filter.map((idList) => `idList=${idList.id}`).join("&");

    try {
      const excelArr = [];
      const response = (await ExcelApi.GetDelMarketSelected(queryString)).data.data;

      response.map((item) => {
        const email = item.influencerInfo?.email;
        const createDate = moment(item.createdAt).format("YYYY/MM/DD");
        const categoryTitle = item.categoryDtoList
          ?.map((item02) => {
            return item02.title;
          })
          .join(", ");
        excelArr.unshift({
          ...item,
          createdAt: createDate,
          email: email,
          categoryTitle: categoryTitle,
        });
      });

      downloadExcel(excelArr);
      // getList();
    } catch (error) {
      errorHandler(error);
    }
  };
  //------------액셀 부분 끝

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <TableLayout>
          <TableTitle>삭제된 마켓 관리</TableTitle>
          <TableTop>
            <TableCount text={"마켓"} count={pagination.totalCount} />
            <TableSearchBtn>
              <SearchBar
                value={keyword}
                onChange={(e) => {
                  setKeyword(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    navigate(`${route.market_register}/1?keyword=${keyword}`, { replace: true });
                  }
                }}
                onClick={() => {
                  navigate(`${route.market_register}/1?keyword=${keyword}`, { replace: true });
                }}
              />
              <ExcelBtn onSelectClick={getExcelChecked} onAllClick={getExcelAll} />
            </TableSearchBtn>
          </TableTop>
          <Table>
            <colgroup>
              <col width="70px" />
              <col width="231px" />
              <col width="195px" />
              <col width="230px" />
              <col width="142px" />
              <col width="97px" />
              <col width="82px" />
            </colgroup>
            <thead>
              <Tr style={{ height: "41px", background: "#f2f4f5" }}>
                <Th style={{ paddingLeft: "20px" }}>
                  <Checkbox
                    checked={list.filter((item) => item.checked).length === list.length}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setList(
                          list.map((item) => {
                            return {
                              ...item,
                              checked: true,
                            };
                          })
                        );
                      } else {
                        setList(
                          list.map((item) => {
                            return {
                              ...item,
                              checked: false,
                            };
                          })
                        );
                      }
                    }}
                  />
                </Th>
                <Th>
                  <ThText>이메일</ThText>
                </Th>
                <Th>
                  <ThText>마켓명</ThText>
                </Th>
                <Th>
                  <ThText>카테고리</ThText>
                </Th>
                <Th>
                  <ThText>삭제일</ThText>
                </Th>
                <Th>
                  <ThText style={{ textAlign: "center", paddingRight: "" }}>완전 삭제</ThText>
                </Th>
                <Th>
                  <ThText style={{ textAlign: "center", paddingRight: "" }}>상세보기</ThText>
                </Th>
              </Tr>
            </thead>
            <tbody>
              {list.map((item, index) => {
                return (
                  <Tr style={{ height: "57px", background: "#fff" }} key={index}>
                    <Td style={{ paddingLeft: "20px" }}>
                      <Checkbox
                        checked={item.checked}
                        onChange={() => {
                          item.checked = !item.checked;
                          setList([...list]);
                        }}
                      />
                    </Td>
                    <Td>
                      <TdText
                        style={{
                          width: "145px",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          wordBreak: "break-all",
                        }}
                      >
                        {item.influencerInfo?.email}
                      </TdText>
                    </Td>
                    <Td>
                      <TdText
                        style={{
                          width: "145px",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          wordBreak: "break-all",
                        }}
                      >
                        {item.name}
                      </TdText>
                    </Td>
                    <Td>
                      <TdText>{item.categoryTitle}</TdText>
                    </Td>
                    <Td>
                      <TdText>{moment(item.createdAt).format("YYYY/MM/DD")}</TdText>
                    </Td>
                    <Td>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <TableDelBtn onClick={() => setDelModalOpen(true)} />
                      </div>
                    </Td>
                    <Td>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <DetailBtn
                          onClick={() => {
                            navigate(route.market_delete_detail_basic + `/${item.id}?tab=1`);
                          }}
                        />
                      </div>
                    </Td>
                  </Tr>
                );
              })}
            </tbody>
          </Table>
          <Pagenation
            route={route.market_delete}
            queryString={`keyword=${keyword}`}
            currentPage={page}
            totalPages={pagination.totalPages}
            rangeSize={pagination.rangeSize}
            first={pagination.first}
            last={pagination.last}
          />
        </TableLayout>

        {delModalOpen ? (
          <Modal
            title={modalMsg.delete?.title}
            text={modalMsg.delete?.content}
            btnTitle={"삭제하기"}
            setModalOpen={setDelModalOpen}
            onClick={() => {
              deleteOneFunc();
            }}
          />
        ) : (
          ""
        )}
      </MainLayout>
    </>
  );
}

export default MarketDelete;
