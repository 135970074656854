import React from "react";
import styled from "styled-components";
import PretendardText from "../text/pretendardText";

function ImgInput({ id, accept, onChange }) {
  return (
    <>
      <FileInput type="file" id={id} accept={accept} onChange={onChange} />
      <FileLabel htmlFor={id}>
        <img src="/assets/admin/icons/ico_file_camera.svg" alt="" />
        <PretendardText
          style={{
            color: "#808991",
            fontSize: "15px",
            fontWeight: "600",
            lineHeight: "24.5px",
          }}
        >
          이미지 등록
        </PretendardText>
      </FileLabel>
    </>
  );
}

export default ImgInput;

const FileInput = styled.input.attrs((props) => {})`
  position: absolute;
  width: 0;
  height: 0;
  padding: 0;
  overflow: hidden;
  border: 0;
`;

const FileLabel = styled.label.attrs((props) => {})`
  display: flex;
  width: 90px;
  height: 90px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 3px;
  border: 1px dashed #ccced9;
  background: #fff;
  cursor: pointer;
`;
