import React from "react";
import styled from "styled-components";

function Table(props) {
  return <TableWrapper {...props} />;
}

export default Table;

const TableWrapper = styled.table.attrs((props) => {})`
  width: 1044px;
  border-top: 1px solid var(--gray-4, #b8bfc4);
  border-bottom: 1px solid var(--gray-4, #b8bfc4);
  border-spacing: 0px;
  border-collapse: collapse;
`;
