import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import Header from "../../../component/organisms/header";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import Sidebar from "../../../component/organisms/sidebar";
import DetailTop from "../../../component/atoms/layout/detail/detailTop";
import DetailPage from "../../../component/atoms/layout/detail/detailPage";
import DetailPageTitle from "../../../component/atoms/text/detail/detailPageTitle";
import DetailPageLayout from "../../../component/atoms/layout/detail/detailPageLayout";
import GoBack from "../../../component/molecules/goBack";
import EditBtn from "../../../component/atoms/button/editBtn";
import DetailPageForm from "../../../component/atoms/layout/detail/detailPageForm";
import DetailFormTitle from "../../../component/atoms/text/detail/detailFormTitle";
import DetailFormText from "../../../component/atoms/text/detail/detailFormText";
import { toast } from "react-toastify";
import { toastMsg } from "../../../util/toastMsg";
import { errorHandler } from "../../../util/error-handler";
import { route } from "../../../router/route";
import DelBtn from "../../../component/atoms/button/delBtn";
import Modal from "../../../component/templates/Modal";
import { modalMsg } from "../../../util/modalMsg";
import moment from "moment";
import FileImgPreview from "../../../component/molecules/fileImgPreview";
import TransactionApi from "../../../api/transaction.api";
import CategoryApi from "../../../api/category.api";
import Table from "../../../component/atoms/layout/table/table";
import Tr from "../../../component/atoms/layout/table/tr";
import Th from "../../../component/atoms/layout/table/th";
import Checkbox from "../../../component/atoms/input/checkbox";
import ThText from "../../../component/atoms/text/table/thText";
import Td from "../../../component/atoms/layout/table/td";
import TdText from "../../../component/atoms/text/table/tdText";
import DetailBtn from "../../../component/atoms/button/detailBtn";
import Pagenation from "../../../component/molecules/pagenation";
import Pagination2 from "../../../component/molecules/pagination2";
import MarketApi from "../../../api/market.api";
import PretendardText from "../../../component/atoms/text/pretendardText";
import MerchApi from "../../../api/merch.api";
import ReviewApi from "../../../api/review.api";
import TableDelBtn from "../../../component/atoms/button/tableDelBtn";
import TableFilterManager from "../../../component/atoms/layout/table/tableFilterManager";
import MarketRegisterDetailTab from "../../../component/templates/market/marketRegisterDetailTab";
import MarketDeleteDetailTab from "../../../component/templates/market/marketDeleteDetailTab";

function MarketDeleteDetailReview(props) {
  const navigate = useNavigate();
  const location = useLocation();
  let { id } = useParams();

  useEffect(() => {
    getList();
  }, [location]);

  // init
  const { page } = useParams();
  const [pagination, setPagination] = useState({
    size: 10, // 한 페이지에 표시될 게시글 수
    rangeSize: 5, // 하단 페이지 사이즈

    totalCount: 0,
    totalPages: 0,
    first: false,
    last: false,
  });
  const [list, setList] = useState([]);

  const getList = async () => {
    try {
      const data = {
        page: page - 1,
        size: pagination.size,
      };
      let list = (await ReviewApi.GetListDeleted(id, data)).data.data;
      setList(
        list.content.map((item) => {
          return {
            ...item,
            checked: false,
          };
        })
      );
      setPagination({
        ...pagination,
        totalCount: list.totalElements,
        totalPages: list.totalPages,
        first: list.first,
        last: list.last,
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  // ----- 활성/비활성
  const [filterOpen, setFilterOpen] = useState(-1);
  const [activeId, setActiveId] = useState(null);
  const [activeModalOpen, setActiveModalOpen] = useState(false);
  const [deactiveModalOpen, setDeactiveModalOpen] = useState(false);

  const activeFunc = async (activation) => {
    try {
      if (activation) {
        await ReviewApi.Hidden(activeId);
      } else {
        await ReviewApi.Visible(activeId);
      }
      toast(toastMsg.create);
      setFilterOpen(-1);
      setActiveModalOpen(false);
      setDeactiveModalOpen(false);
      getList();
    } catch (error) {
      errorHandler(error);
    }
  };

  // ----- 삭제
  const [delIdList, setDelIdList] = useState([]);
  const [delListModalOpen, setDelListModalOpen] = useState(false);

  const deleteFunc = async () => {
    try {
      const formData = new FormData();
      for (let index = 0; index < delIdList.length; index++) {
        const element = delIdList[index];
        formData.append("idList", element.id);
      }

      await ReviewApi.Delete(formData);
      toast(toastMsg.delete);
      setDelListModalOpen(false);
      setDelIdList([]);
      getList();
    } catch (error) {
      errorHandler(error);
    }
  };

  const [delId, setDelId] = useState(0);
  const [delModalOpen, setDelModalOpen] = useState(false);

  const deleteOneFunc = async () => {
    try {
      await ReviewApi.DeleteById(delId);
      toast(toastMsg.delete);
      setDelModalOpen(false);
      navigate(-1);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <MarketDeleteDetailTab />

          <>
            <DetailTop>
              <GoBack />
              <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
                <DelBtn
                  text={"선택항목 삭제"}
                  onClick={() => {
                    const delIdList = list.filter((item) => item.checked);
                    if (delIdList.length <= 0) {
                      return;
                    }
                    setDelIdList([...delIdList]);
                    setDelListModalOpen(true);
                  }}
                />
              </div>
            </DetailTop>

            <Table>
              <colgroup>
                <col width="90px" />
                <col width="241px" />
                <col width="165px" />
                <col width="152px" />
                <col width="93px" />
                <col width="136px" />
                <col width="80px" />
                <col width="80px" />
              </colgroup>
              <thead>
                <Tr style={{ height: "41px", background: "#f2f4f5" }}>
                  <Th style={{ paddingLeft: "20px" }}>
                    <Checkbox
                      checked={list.filter((item) => item.checked).length === list.length}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setList(
                            list.map((item) => {
                              return {
                                ...item,
                                checked: true,
                              };
                            })
                          );
                        } else {
                          setList(
                            list.map((item) => {
                              return {
                                ...item,
                                checked: false,
                              };
                            })
                          );
                        }
                      }}
                    />
                  </Th>
                  <Th>
                    <ThText>이메일</ThText>
                  </Th>
                  <Th>
                    <ThText>이름</ThText>
                  </Th>
                  <Th>
                    <ThText>작성일</ThText>
                  </Th>
                  <Th>
                    <ThText style={{ textAlign: "center", paddingRight: "" }}>별점</ThText>
                  </Th>
                  <Th>
                    <ThText style={{ textAlign: "center", paddingRight: "" }}>노출 상태</ThText>
                  </Th>
                  <Th>
                    <ThText style={{ textAlign: "center", paddingRight: "" }}>삭제하기</ThText>
                  </Th>
                  <Th>
                    <ThText style={{ textAlign: "center", paddingRight: "" }}>상세보기</ThText>
                  </Th>
                </Tr>
              </thead>
              <tbody>
                {list.map((item, index) => {
                  return (
                    <Tr style={{ height: "57px", background: "#fff" }} key={index}>
                      <Td style={{ paddingLeft: "20px" }}>
                        <Checkbox
                          checked={item.checked}
                          onChange={() => {
                            item.checked = !item.checked;
                            setList([...list]);
                          }}
                        />
                      </Td>
                      <Td>
                        <TdText>{item.writerInfo?.email}</TdText>
                      </Td>
                      <Td>
                        <TdText>{item.writerInfo?.name}</TdText>
                      </Td>
                      <Td>
                        <TdText>{moment(item.createdAt).format("YYYY/MM/DD")}</TdText>
                      </Td>

                      <Td>
                        <TdText style={{ textAlign: "center", paddingRight: "" }}>{item.score}</TdText>
                      </Td>

                      <Td>
                        <TableFilterManager
                          style={{ justifyContent: "center" }}
                          onClick={() => {
                            filterOpen === index ? setFilterOpen(-1) : setFilterOpen(index);
                          }}
                          isOpen={filterOpen === index}
                          selectedValue={item.hidden}
                          list={[
                            {
                              value: false,
                              title: "노출",
                              onClick: () => {
                                setActiveId(item.id);
                                setActiveModalOpen(true);
                              },
                            },
                            {
                              value: true,
                              title: "숨김",
                              onClick: () => {
                                setActiveId(item.id);
                                setDeactiveModalOpen(true);
                              },
                            },
                          ]}
                        />
                      </Td>
                      <Td>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <TableDelBtn
                            onClick={() => {
                              setDelId(item.id);
                              setDelModalOpen(true);
                            }}
                          />
                        </div>
                      </Td>
                      <Td>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <DetailBtn
                            onClick={() => {
                              navigate(route.market_delete_detail_review_detail + `/${id}/${item.id}?tab=3`);
                            }}
                          />
                        </div>
                      </Td>
                    </Tr>
                  );
                })}
              </tbody>
            </Table>
            <Pagenation
              route={route.market_delete_detail_review}
              params={`${id}`}
              queryString={`tab=3`}
              currentPage={page}
              totalPages={pagination.totalPages}
              rangeSize={pagination.rangeSize}
              first={pagination.first}
              last={pagination.last}
            />
          </>
        </DetailPageLayout>
      </MainLayout>

      {delListModalOpen ? (
        <Modal
          title={modalMsg.delete?.title}
          text={modalMsg.delete?.content}
          btnTitle={"삭제하기"}
          setModalOpen={setDelListModalOpen}
          onClick={() => {
            deleteFunc();
          }}
        />
      ) : (
        ""
      )}
      {delModalOpen ? (
        <Modal
          title={modalMsg.delete?.title}
          text={modalMsg.delete?.content}
          btnTitle={"삭제하기"}
          setModalOpen={setDelModalOpen}
          onClick={() => {
            deleteOneFunc();
          }}
        />
      ) : (
        ""
      )}

      {deactiveModalOpen ? (
        <Modal
          title={"숨김처리 하시겠습니까?"}
          setModalOpen={setDeactiveModalOpen}
          onClick={() => {
            activeFunc(true);
          }}
        />
      ) : (
        ""
      )}
      {activeModalOpen ? (
        <Modal
          title={"노출상태로 변경하시겠습니까?"}
          setModalOpen={setActiveModalOpen}
          onClick={() => {
            activeFunc(false);
          }}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default MarketDeleteDetailReview;
