import api from "./api.js";

export default class StyleApi {
  static async Create(data) {
    return await api.post(`admin/styleCategory`, data);
  }
  static async GetList(params) {
    return await api.get(`admin/styleCategorys`, params);
  }
  static async GetById(styleCategoryId) {
    return await api.get(`admin/styleCategory/${styleCategoryId}`);
  }
  static async Update(styleCategoryId, data) {
    return await api.put(`admin/styleCategory/${styleCategoryId}`, data);
  }
  static async Delete(data) {
    return await api.del(`admin/styleCategorys`, "", data);
  }
  static async DeleteById(styleCategoryId) {
    return await api.del(`admin/styleCategory/${styleCategoryId}`);
  }

  static async Activation(styleCategoryId) {
    return await api.put(`admin/styleCategory/activate/${styleCategoryId}`);
  }
  static async DeActivation(styleCategoryId) {
    return await api.put(`admin/styleCategory/deactivate/${styleCategoryId}`);
  }
}
