import api from "./api.js";

export default class NoticeApi {
  static async Create(data) {
    return await api.post(`admin/notice`, data);
  }
  static async GetList(params) {
    return await api.get(`admin/notice`, params);
  }
  static async GetById(noticeId) {
    return await api.get(`admin/notice/${noticeId}`);
  }
  static async Delete(data) {
    return await api.del(`admin/notices`, "", data);
  }
  static async DeleteById(noticeId) {
    return await api.del(`admin/notice/${noticeId}`);
  }
}
