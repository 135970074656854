import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import Header from "../../../component/organisms/header";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import Sidebar from "../../../component/organisms/sidebar";
import DetailTop from "../../../component/atoms/layout/detail/detailTop";
import DetailPage from "../../../component/atoms/layout/detail/detailPage";
import DetailPageTitle from "../../../component/atoms/text/detail/detailPageTitle";
import DetailPageLayout from "../../../component/atoms/layout/detail/detailPageLayout";
import GoBack from "../../../component/molecules/goBack";
import EditBtn from "../../../component/atoms/button/editBtn";
import DetailPageForm from "../../../component/atoms/layout/detail/detailPageForm";
import DetailFormTitle from "../../../component/atoms/text/detail/detailFormTitle";
import DetailFormText from "../../../component/atoms/text/detail/detailFormText";
import { toast } from "react-toastify";
import { toastMsg } from "../../../util/toastMsg";
import { errorHandler } from "../../../util/error-handler";
import { route } from "../../../router/route";
import DelBtn from "../../../component/atoms/button/delBtn";
import Modal from "../../../component/templates/Modal";
import { modalMsg } from "../../../util/modalMsg";
import moment from "moment";
import FileImgPreview from "../../../component/molecules/fileImgPreview";
import TransactionApi from "../../../api/transaction.api";
import CategoryApi from "../../../api/category.api";
import MarketApi from "../../../api/market.api";
import Table from "../../../component/atoms/layout/table/table";
import Tr from "../../../component/atoms/layout/table/tr";
import Th from "../../../component/atoms/layout/table/th";
import Checkbox from "../../../component/atoms/input/checkbox";
import ThText from "../../../component/atoms/text/table/thText";
import Td from "../../../component/atoms/layout/table/td";
import TdText from "../../../component/atoms/text/table/tdText";
import DetailBtn from "../../../component/atoms/button/detailBtn";
import Pagenation from "../../../component/molecules/pagenation";
import Pagination2 from "../../../component/molecules/pagination2";
import MarketDetailModal from "./marketDetailModal";

function MarketCategoryDetail(props) {
  const navigate = useNavigate();
  let { id } = useParams();

  const [data, setData] = useState({});

  // init
  const getData = async () => {
    try {
      const response = await CategoryApi.GetById(id);
      setData({
        ...response.data.data,
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const { page } = useParams();
  const [pagination, setPagination] = useState({
    size: 10, // 한 페이지에 표시될 게시글 수
    rangeSize: 5, // 하단 페이지 사이즈

    totalCount: 0,
    totalPages: 0,
    first: false,
    last: false,
  });
  const [list, setList] = useState([]);

  const getList = async () => {
    try {
      const data = {
        page: page - 1,
        size: pagination.size,
      };
      let list = (await MarketApi.GetList(data)).data.data;
      setList(
        list.content.map((item) => {
          return {
            ...item,
          };
        })
      );
      setPagination({
        ...pagination,
        totalCount: list.totalElements,
        totalPages: list.totalPages,
        first: list.first,
        last: list.last,
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    getList();
  }, [page]);

  // ----- 삭제
  const [delModalOpen, setDelModalOpen] = useState(false);

  const deleteFunc = async () => {
    try {
      await CategoryApi.DeleteById(id);
      toast(toastMsg.delete);
      setDelModalOpen(false);
      navigate(-1);
    } catch (error) {
      errorHandler(error);
    }
  };

  // ----- 상세
  const [market, setMarket] = useState({});
  const [marketDetailModalOpen, setMarketDetailModalOpen] = useState(false);

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <GoBack />
            <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
              <DelBtn onClick={() => setDelModalOpen(true)} />
              <EditBtn
                onClick={() => {
                  navigate(route.market_category_edit + `/${id}`);
                }}
              />
            </div>
          </DetailTop>

          <DetailPage style={{ minHeight: "360px", marginBottom: "20px" }}>
            <DetailPageTitle>상세보기</DetailPageTitle>
            <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
              <DetailPageForm>
                <DetailFormTitle>카테고리명</DetailFormTitle>
                <DetailFormText>{data.title}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>등록일</DetailFormTitle>
                <DetailFormText>{moment(data.createdAt).format("YYYY/MM/DD")}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>이미지</DetailFormTitle>
                <FileImgPreview
                  fileUrl={data.fileUrl}
                  fileName={data.originFileName}
                  serverFileName={data.serverFileName}
                />
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>상태</DetailFormTitle>
                <DetailFormText>{data.isActive ? "활성" : "비활성"}</DetailFormText>
              </DetailPageForm>
            </div>
          </DetailPage>
          <DetailPage style={{ minHeight: "836px" }}>
            <DetailPageTitle>해당 마켓</DetailPageTitle>
            <Table
              style={{
                width: "964px",
              }}
            >
              <colgroup>
                <col width="291px" />
                <col width="207px" />
                <col width="90px" />
                <col width="220px" />
                <col width="80px" />
              </colgroup>
              <thead>
                <Tr style={{ height: "41px", background: "#f2f4f5" }}>
                  <Th style={{ paddingLeft: "20px" }}>
                    <ThText>이메일</ThText>
                  </Th>
                  <Th>
                    <ThText>이름</ThText>
                  </Th>
                  <Th>
                    <ThText>가입일</ThText>
                  </Th>
                  <Th>
                    <ThText style={{ textAlign: "center", marginRight: "" }}>계정 상태</ThText>
                  </Th>
                  <Th>
                    <ThText style={{ textAlign: "center", paddingRight: "19px" }}>상세보기</ThText>
                  </Th>
                </Tr>
              </thead>
              <tbody>
                {list.map((item, index) => {
                  return (
                    <Tr style={{ height: "57px", background: "#fff" }} key={index}>
                      <Td>
                        <TdText
                          style={{
                            paddingLeft: "20px",
                            width: "291px",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            wordBreak: "break-all",
                          }}
                        >
                          {item.influencerInfo?.email}
                        </TdText>
                      </Td>
                      <Td>
                        <TdText
                          style={{
                            width: "207px",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            wordBreak: "break-all",
                          }}
                        >
                          {item.influencerInfo?.name}
                        </TdText>
                      </Td>
                      <Td>
                        <TdText>{moment(item.createdAt).format("YYYY/MM/DD")}</TdText>
                      </Td>
                      <Td>
                        <TdText style={{ textAlign: "center", color: "#262C31", fontWeight: "600" }}>
                          {item.influencerInfo?.isActive ? "활성" : "비활성"}
                        </TdText>
                      </Td>
                      <Td>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            paddingRight: "17px",
                          }}
                        >
                          <DetailBtn
                            onClick={() => {
                              setMarket(item);
                              setMarketDetailModalOpen(true);
                            }}
                          />
                        </div>
                      </Td>
                    </Tr>
                  );
                })}
              </tbody>
            </Table>
            <Pagenation
              style={{ width: "964px" }}
              route={route.market_category_detail}
              params={`${id}`}
              queryString={``}
              currentPage={page}
              totalPages={pagination.totalPages}
              rangeSize={pagination.rangeSize}
              first={pagination.first}
              last={pagination.last}
            />
          </DetailPage>
        </DetailPageLayout>
        {marketDetailModalOpen ? <MarketDetailModal data={market} setModalOpen={setMarketDetailModalOpen} /> : null}
      </MainLayout>
      {delModalOpen ? (
        <Modal
          title={modalMsg.delete?.title}
          text={modalMsg.delete?.content}
          btnTitle={"삭제하기"}
          setModalOpen={setDelModalOpen}
          onClick={() => {
            deleteFunc();
          }}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default MarketCategoryDetail;
