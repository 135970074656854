import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import Header from "../../../component/organisms/header";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import Sidebar from "../../../component/organisms/sidebar";
import DetailTop from "../../../component/atoms/layout/detail/detailTop";
import DetailPage from "../../../component/atoms/layout/detail/detailPage";
import DetailPageTitle from "../../../component/atoms/text/detail/detailPageTitle";
import DetailPageLayout from "../../../component/atoms/layout/detail/detailPageLayout";
import GoBack from "../../../component/molecules/goBack";
import EditBtn from "../../../component/atoms/button/editBtn";
import DetailPageForm from "../../../component/atoms/layout/detail/detailPageForm";
import DetailFormTitle from "../../../component/atoms/text/detail/detailFormTitle";
import DetailFormText from "../../../component/atoms/text/detail/detailFormText";
import { toast } from "react-toastify";
import { toastMsg } from "../../../util/toastMsg";
import { errorHandler } from "../../../util/error-handler";
import { route } from "../../../router/route";
import DelBtn from "../../../component/atoms/button/delBtn";
import Modal from "../../../component/templates/Modal";
import { modalMsg } from "../../../util/modalMsg";
import moment from "moment";
import FileImgPreview from "../../../component/molecules/fileImgPreview";
import ColumnApi from "../../../api/column.api";
import Table from "../../../component/atoms/layout/table/table";
import Tr from "../../../component/atoms/layout/table/tr";
import Th from "../../../component/atoms/layout/table/th";
import ThText from "../../../component/atoms/text/table/thText";
import CommentApi from "../../../api/comment.api";
import Td from "../../../component/atoms/layout/table/td";
import TdText from "../../../component/atoms/text/table/tdText";
import DetailBtn from "../../../component/atoms/button/detailBtn";
import Pagenation from "../../../component/molecules/pagenation";
import CommentDetailModal from "./commentDetailModal";
import TextFormEditor from "../../../component/atoms/input/textFormEditor";
import ReactQuill from "react-quill";

function ColumnDetail(props) {
  const navigate = useNavigate();
  const location = useLocation();
  let { id } = useParams();

  const [data, setData] = useState({
    title: "",
    file: "",
    content: "",
    instagramLink: "",
    isActive: "",

    fileUrl: "",
    originFileName: "",
    serverFileName: "",
  });

  // init
  const getData = async () => {
    try {
      const response = await ColumnApi.GetById(id);

      setData({
        ...response.data.data,
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  // ----- 삭제
  const [delModalOpen, setDelModalOpen] = useState(false);

  const deleteFunc = async () => {
    try {
      await ColumnApi.DeleteById(id);
      toast(toastMsg.delete);
      setDelModalOpen(false);
      navigate(-1);
    } catch (error) {
      errorHandler(error);
    }
  };

  const { page } = useParams();
  const [pagination, setPagination] = useState({
    size: 10, // 한 페이지에 표시될 게시글 수
    rangeSize: 5, // 하단 페이지 사이즈

    totalCount: 0,
    totalPages: 0,
    first: false,
    last: false,
  });
  const [list, setList] = useState([]);

  const getList = async () => {
    try {
      const data = {
        page: page - 1,
        size: pagination.size,
      };
      let list = (await CommentApi.GetList(data, id)).data.data;
      setList(
        list.content.map((item) => {
          return {
            ...item,
            checked: false,
          };
        })
      );
      setPagination({
        ...pagination,
        totalCount: list.totalElements,
        totalPages: list.totalPages,
        first: list.first,
        last: list.last,
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    getList();
  }, [location]);

  // ----- 상세
  const [comment, setComment] = useState({});
  const [commentDetailModalOpen, setCommentDetailModalOpen] = useState(false);

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <GoBack />
            <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
              <DelBtn onClick={() => setDelModalOpen(true)} />
              <EditBtn
                onClick={() => {
                  navigate(route.etc_column_edit + `/${id}`);
                }}
              />
            </div>
          </DetailTop>
          <DetailPage style={{ minHeight: "287px", marginBottom: "20px" }}>
            <DetailPageTitle>상세보기</DetailPageTitle>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "20px" }}
            >
              <DetailPageForm>
                <DetailFormTitle>제목</DetailFormTitle>
                <DetailFormText>{data.title}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>썸네일</DetailFormTitle>
                <FileImgPreview
                  fileUrl={data.fileUrl}
                  fileName={data.originFileName}
                  serverFileName={data.serverFileName}
                />
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>작성일</DetailFormTitle>
                <DetailFormText>
                  {moment(data.createdAt).format("YYYY/MM/DD")}
                </DetailFormText>
              </DetailPageForm>
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>내용</DetailFormTitle>                
                <StyledQuillContainer>
                  <ReactQuill
                    value={data.content}
                    readOnly={true}
                    theme="snow"
                    modules={{ toolbar: false }}
                    style={{
                      maxWidth: "730px",
                      height: "auto",
                      backgroundColor: "white",
                      minHeight: "200px",
                      overflow: "auto",
                    }}
                  />
                </StyledQuillContainer>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>인스타그램 링크</DetailFormTitle>
                <DetailFormText>{data.instagramLink}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>추천 콘텐츠1</DetailFormTitle>
                <DetailFormText>{data.columnBoard1?.title}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>추천 콘텐츠2</DetailFormTitle>
                <DetailFormText>{data.columnBoard2?.title}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>상태</DetailFormTitle>
                <DetailFormText>
                  {data.isActive ? "게시" : "숨김"}
                </DetailFormText>
              </DetailPageForm>
            </div>
          </DetailPage>

          <DetailPage style={{ minHeight: "287px" }}>
            <DetailPageTitle>댓글</DetailPageTitle>
            <Table style={{ width: "964px" }}>
              <colgroup>
                <col width="20px" />
                <col width="244px" />
                <col width="473px" />
                <col width="95px" />
                <col width="78px" />
              </colgroup>
              <thead>
                <Tr style={{ height: "41px", background: "#f2f4f5" }}>
                  <Th></Th>
                  <Th>
                    <ThText>이메일</ThText>
                  </Th>
                  <Th>
                    <ThText>내용</ThText>
                  </Th>
                  <Th>
                    <ThText>작성일</ThText>
                  </Th>
                  <Th>
                    <ThText
                      style={{ textAlign: "center", paddingRight: "21px" }}
                    >
                      상세보기
                    </ThText>
                  </Th>
                </Tr>
              </thead>
              <tbody>
                {list.map((item, index) => {
                  return (
                    <Tr
                      style={{ height: "57px", background: "#fff" }}
                      key={index}
                    >
                      <Td></Td>
                      <Td>
                        <TdText>{item.commonMember?.email}</TdText>
                      </Td>
                      <Td>
                        <TdText
                          style={{
                            width: "473px",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            wordBreak: "break-all",
                          }}
                        >
                          {item.content}
                        </TdText>
                      </Td>
                      <Td>
                        <TdText>
                          {moment(item.createdAt).format("YYYY/MM/DD")}
                        </TdText>
                      </Td>
                      <Td>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            paddingRight: "17px",
                          }}
                        >
                          <DetailBtn
                            onClick={() => {
                              setComment(item);
                              setCommentDetailModalOpen(true);
                            }}
                          />
                        </div>
                      </Td>
                    </Tr>
                  );
                })}
              </tbody>
            </Table>
            <Pagenation
              style={{
                width: "964px",
              }}
              route={route.etc_column_detail}
              params={id}
              queryString={``}
              currentPage={page}
              totalPages={pagination.totalPages}
              rangeSize={pagination.rangeSize}
              first={pagination.first}
              last={pagination.last}
            />
          </DetailPage>
        </DetailPageLayout>
        {commentDetailModalOpen ? (
          <CommentDetailModal
            data={comment}
            setModalOpen={setCommentDetailModalOpen}
            getList={getList}
          />
        ) : null}
      </MainLayout>
      {delModalOpen ? (
        <Modal
          title={modalMsg.delete?.title}
          text={modalMsg.delete?.content}
          btnTitle={"삭제하기"}
          setModalOpen={setDelModalOpen}
          onClick={() => {
            deleteFunc();
          }}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default ColumnDetail;

const StyledQuillContainer = styled.div`
  .ql-container.ql-snow {
    border: none;
  }

  .ql-editor {
    padding: 0;
  }
`;
