import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import MainInput from "../../component/atoms/input/mainInput";
import PretendardText from "../../component/atoms/text/pretendardText";
import { route } from "../../router/route";
import { errorHandler } from "../../util/error-handler";
import { regExpression } from "../../util/reg-expresstion";
import ManagerApi from "../../api/manager.api";
// import ManagerApi from "../../api/managerApi";

function Login(props) {
  const navigate = useNavigate();

  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });

  const handleLogin = async () => {
    if (!(loginData.email && loginData.password)) {
      return;
    }
    if (!regExpression.email.test(loginData.email)) {
      toast("올바른 이메일 형식이 아닙니다.");
      return;
    }
    if (!regExpression.password.test(loginData.password)) {
      toast("올바른 비밀번호 형식이 아닙니다.");
      return;
    }
    let obj = { account: loginData.email, password: loginData.password };
    try {
      let response = await ManagerApi.Login(obj);
      sessionStorage.setItem("id", response.data.data.id);
      sessionStorage.setItem("token", response.data.data.accessToken);
      response = (await ManagerApi.GetById(response.data.data.id)).data.data;
      sessionStorage.setItem("name", response.name);
      sessionStorage.setItem("accessMenu", response.accessMenu);
      sessionStorage.setItem("managerAuthority", response.managerAuthority);
      sessionStorage.setItem("email", response.email);
      navigate(route.home);
    } catch (error) {
      if (error.response.status === 401) {
        return toast(`${error.response.data.message}`);
      }
      if (error.response.status === 404) {
        return toast(`존재하지 않는 회원입니다.`);
      }
      errorHandler(error);
    }
  };

  return (
    <LoginWrapper>
      <LoginBox>
        <PretendardText
          style={{
            fontSize: "30px",
            fontWeight: "700",
            lineHeight: "41.5px",
            letterSpacing: "-0.21px",
            color: "#000",
            marginBottom: "40px",
          }}
        >
          로그인
        </PretendardText>
        <LoginInputWrapper>
          <LoginInputForm>
            <PretendardText
              style={{
                fontSize: "15px",
                fontWeight: "400",
                lineHeight: "22px",
                color: "#333",
              }}
            >
              이메일
            </PretendardText>
            <MainInput
              type={"email"}
              placeholder={"이메일을 입력해 주세요."}
              style={{ width: "343px" }}
              onChange={(e) => {
                setLoginData({ ...loginData, email: e.target.value });
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleLogin();
                }
              }}
            />
          </LoginInputForm>
          <LoginInputForm>
            <PretendardText
              style={{
                fontSize: "15px",
                fontWeight: "400",
                lineHeight: "22px",
                color: "#333",
              }}
            >
              비밀번호
            </PretendardText>
            <MainInput
              type={"password"}
              placeholder={"비밀번호를 입력해 주세요."}
              style={{ width: "343px" }}
              onChange={(e) => setLoginData({ ...loginData, password: e.target.value })}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleLogin();
                }
              }}
            />
          </LoginInputForm>
        </LoginInputWrapper>
        <LoginBtnWrapper>
          <LoginBtn
            disabled={!(loginData.email && loginData.password)}
            onClick={() => {
              handleLogin();
            }}
          >
            로그인
          </LoginBtn>
          <JoinBtn onClick={() => navigate(route.join)}>회원가입</JoinBtn>
        </LoginBtnWrapper>
      </LoginBox>
    </LoginWrapper>
  );
}

export default Login;

const LoginWrapper = styled.div.attrs((props) => {})`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoginBox = styled.div.attrs((props) => {})`
  width: 518px;
  height: 574px;
  padding: 86px 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 16px;
  background: #fff;
  box-shadow: 4px 8px 36px 0px rgba(0, 0, 0, 0.05);
`;

const LoginInputWrapper = styled.div.attrs((props) => {})`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-bottom: 40px;
`;

const LoginInputForm = styled.div.attrs((props) => {})`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 3px;
`;

const LoginBtnWrapper = styled.div.attrs((props) => {})`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

const LoginBtn = styled.button.attrs((props) => {})`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => (props.disabled ? "#BABABA" : "#27BEFF")};
  font-family: Pretendard;
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
  color: #fff;
  border-radius: 3px;

  &:hover {
    background-color: #009bde;
  }
  &:disabled:hover {
    background-color: #bababa;
  }
`;

const JoinBtn = styled.button.attrs((props) => {})`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #bababa;
  background-color: #fff;
  font-family: Pretendard;
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
  color: #939393;
  border-radius: 3px;

  &:hover {
    background-color: #e6e8eb;
  }
`;
