import React, { useEffect, useMemo, useState } from "react";
import Header from "../../../component/organisms/header";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import Sidebar from "../../../component/organisms/sidebar";
import DetailTop from "../../../component/atoms/layout/detail/detailTop";
import DetailPage from "../../../component/atoms/layout/detail/detailPage";
import DetailPageTitle from "../../../component/atoms/text/detail/detailPageTitle";
import DetailPageLayout from "../../../component/atoms/layout/detail/detailPageLayout";
import GoBack from "../../../component/molecules/goBack";
import DetailPageForm from "../../../component/atoms/layout/detail/detailPageForm";
import DetailFormTitle from "../../../component/atoms/text/detail/detailFormTitle";
import MainInput from "../../../component/atoms/input/mainInput";
import SaveBtn from "../../../component/atoms/button/saveBtn";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { toastMsg } from "../../../util/toastMsg";
import { errorHandler } from "../../../util/error-handler";
import { nullCheck } from "../../../util/check";
import Modal from "../../../component/templates/Modal";
import { modalMsg } from "../../../util/modalMsg";
import TextArea from "../../../component/atoms/input/textarea";
import ImgPreview from "../../../component/molecules/imgPreview";
import ImgInput from "../../../component/atoms/input/imgInput";
import PretendardText from "../../../component/atoms/text/pretendardText";
import Checkbox from "../../../component/atoms/input/checkbox";
import DetailFormText from "../../../component/atoms/text/detail/detailFormText";
import styled from "styled-components";
import Radio from "../../../component/atoms/input/radio";
import CategoryApi from "../../../api/category.api";
import StyleApi from "../../../api/style.api";

function StyleEdit(props) {
  const navigate = useNavigate();
  let { id } = useParams();

  const [data, setData] = useState({
    title: "",
    categoryId: "",
    file: "",
    isActive: "",
    deleteFile: false,

    fileUrl: "",
    originFileName: "",
    serverFileName: "",
  });

  // init
  const getData = async () => {
    try {
      const response = await StyleApi.GetById(id);
      setData({
        ...response.data.data,
        deleteFile: false,
        categoryId: response.data.data.category.id,
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const [categoryList, setCategoryList] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const getCategoryList = async () => {
    try {
      let list = (await CategoryApi.GetListAll(data)).data.data;
      setCategoryList(
        list.map((item) => {
          return {
            ...item,
          };
        })
      );
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    getCategoryList();
  }, []);

  const categoryTitle = useMemo(() => {
    return categoryList?.find((item) => item.id === data?.categoryId)?.title;
  }, [categoryList, data]);

  //이미지 추가, 삭제
  const inputFile = (e) => {
    const file = e.target.files[0];

    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = (value) => {
      setData({
        ...data,
        file: file,
        fileUrl: value.target.result,
        deleteFile: true,
      });
    };
    e.target.value = "";
  };

  const deleteFile = () => {
    setData({ ...data, file: "", fileUrl: "", deleteFile: true });
  };

  //저장하기
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const saveFunc = async () => {
    try {
      if (nullCheck(data.title) || nullCheck(data.categoryId) || nullCheck(data.fileUrl)) {
        setSaveModalOpen(false);
        return toast("필수사항을 모두 입력해주세요.");
      }

      const formData = new FormData();
      formData.append("title", data.title);
      formData.append("categoryId", data.categoryId);

      formData.append("deleteFile", data.deleteFile);

      if (data.file) {
        formData.append("file", data.file);
      }

      formData.append("isActive", data.isActive);

      await StyleApi.Update(id, formData);
      setSaveModalOpen(false);
      navigate(-1);
      toast(toastMsg.create);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <GoBack />
          </DetailTop>
          <DetailPage style={{ minHeight: "375px" }}>
            <DetailPageTitle>수정하기</DetailPageTitle>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                marginBottom: "79px",
              }}
            >
              <DetailPageForm>
                <DetailFormTitle>제목*</DetailFormTitle>
                <MainInput value={data.title} type={"text"} placeholder={"제목을 입력해주세요"} onChange={(e) => setData({ ...data, title: e.target.value })} />
              </DetailPageForm>
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>이미지*</DetailFormTitle>
                {data.fileUrl ? (
                  <ImgPreview
                    fileUrl={data.fileUrl}
                    onClick={() => {
                      deleteFile();
                    }}
                  />
                ) : (
                  <ImgInput
                    id={"file"}
                    accept={"image/*"}
                    onChange={(e) => {
                      inputFile(e);
                    }}
                  />
                )}
              </DetailPageForm>
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>카테고리*</DetailFormTitle>
                <div style={{ position: "relative" }}>
                  <SelectBoxWrapper>
                    <DetailFormText style={{ color: `${data.categoryId ? "#262c31" : "#808991"}` }}>{categoryTitle || "카테고리를 선택해 주세요."}</DetailFormText>
                    <img
                      alt=""
                      src="/assets/admin/icons/selectbox-ico.svg"
                      onClick={() => {
                        if (isOpen === 1) {
                          setIsOpen(false);
                        } else setIsOpen(1);
                      }}
                      style={{ cursor: "pointer" }}
                    />
                  </SelectBoxWrapper>
                  {isOpen === 1 ? (
                    <SubMenuWrapper>
                      {categoryList.map((item, index) => {
                        return (
                          <SubText
                            key={index}
                            onClick={() => {
                              setData({ ...data, categoryId: item.id });
                              setIsOpen(false);
                            }}
                          >
                            {item.title}
                          </SubText>
                        );
                      })}
                    </SubMenuWrapper>
                  ) : (
                    ""
                  )}
                </div>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>상태*</DetailFormTitle>
                <div style={{ display: "flex", gap: "19px" }}>
                  <Radio
                    id={"POST"}
                    valueText={"게시"}
                    checked={data.isActive}
                    onChange={() => {
                      setData({ ...data, isActive: true });
                    }}
                  />
                  <Radio
                    id={"HIDE"}
                    valueText={"숨김"}
                    checked={!data.isActive}
                    onChange={() => {
                      setData({ ...data, isActive: false });
                    }}
                  />
                </div>
              </DetailPageForm>
            </div>
            <SaveBtn
              text={"저장하기"}
              style={{ bottom: "40px", right: "40px" }}
              onClick={() => {
                setSaveModalOpen(true);
              }}
            />
          </DetailPage>
        </DetailPageLayout>
      </MainLayout>
      {saveModalOpen ? <Modal title={modalMsg.update?.title} text={modalMsg.update?.content} setModalOpen={setSaveModalOpen} onClick={saveFunc} /> : ""}
    </>
  );
}

export default StyleEdit;

const CheckboxWrapper = styled.div.attrs((props) => {})`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const SelectBoxWrapper = styled.div.attrs((props) => {})`
  width: 338px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px 15px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  background: #fff;
`;

const SubMenuWrapper = styled.div.attrs((props) => {})`
  width: 338px;
  height: 356px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  background: #fff;
  box-shadow: 0px 5px 15px 0px rgba(217, 217, 217, 0.7);
  position: absolute;
  top: 56px;
  padding: 10px;
  overflow-y: auto;
  z-index: 5;

  &::-webkit-scrollbar {
    width: 17px;
  }
  &::-webkit-scrollbar-thumb {
    background: #b0b8c1; /* 스크롤바의 색상 */
    border-radius: 9px;
    background-clip: padding-box;
    border: 7px solid transparent;
  }

  &::-webkit-scrollbar-track {
    background: transparent; /*스크롤바 뒷 배경 색상*/
  }
`;

const SubText = styled(PretendardText).attrs((props) => {})`
  color: #808991;
  font-size: 15px;
  font-weight: 500;
  line-height: 21.5px;
  width: 100%;
  height: 42px;
  padding: 10px;
  cursor: pointer;
`;
