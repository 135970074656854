import React from "react";
import styled from "styled-components";

function SaveBtn({ style, onClick, text }) {
  return (
    <>
      <SaveBtnWrapper onClick={onClick} style={{ ...style }}>
        {text || "저장하기"}
      </SaveBtnWrapper>
    </>
  );
}

export default SaveBtn;

const SaveBtnWrapper = styled.button.attrs((props) => {})`
  display: flex;
  padding: 12px 20px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid #636c73;
  background: #636c73;
  position: absolute;
  font-family: Pretendard;
  font-size: 15px;
  font-weight: 600;
  line-height: 24.5px;
  color: #fff;
  cursor: pointer;

  &:hover {
    border: 1px solid #262c31;
    background: #262c31;
  }
`;
