import React from "react";
import styled from "styled-components";
import TableTitle from "../../../component/atoms/text/table/tableTitle";
import DetailPageForm from "../../../component/atoms/layout/detail/detailPageForm";
import DetailFormTitle from "../../../component/atoms/text/detail/detailFormTitle";
import DetailFormText from "../../../component/atoms/text/detail/detailFormText";
import moment from "moment";

function ReportModal({ data, setModalOpen }) {
  return (
    <ModalWrapper>
      <ModalBox>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "30px",
          }}
        >
          <TableTitle>신고 상세</TableTitle>
          <img
            style={{
              cursor: "pointer",
            }}
            src="/assets/admin/icons/ico_popup_close.svg"
            alt=""
            onClick={() => setModalOpen(false)}
          />
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <DetailPageForm>
            <DetailFormTitle>이메일</DetailFormTitle>
            <DetailFormText>{data.commonMemberInfo?.email}</DetailFormText>
          </DetailPageForm>
          <DetailPageForm>
            <DetailFormTitle>작성일</DetailFormTitle>
            <DetailFormText>{moment(data.createdAt).format("YYYY/MM/DD")}</DetailFormText>
          </DetailPageForm>
          <DetailPageForm>
            <DetailFormTitle>신고 사유</DetailFormTitle>
            <DetailFormText>{data.reportReason === "ETC" ? data.etc : data.reportReasonTitle}</DetailFormText>
          </DetailPageForm>
        </div>
      </ModalBox>
    </ModalWrapper>
  );
}

export default ReportModal;

const ModalWrapper = styled.div.attrs((props) => {})`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00000060;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 99;
`;

const ModalBox = styled.div.attrs((props) => {})`
  width: 1064px;
  min-height: 307px;
  height: auto;
  padding: 50px;
  border: 1px solid #d7dbe0;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
`;
