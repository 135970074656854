import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import Header from "../../organisms/header";
import MainLayout from "../../atoms/layout/mainLayout";
import Sidebar from "../../organisms/sidebar";
import DetailTop from "../../atoms/layout/detail/detailTop";
import DetailPage from "../../atoms/layout/detail/detailPage";
import DetailPageTitle from "../../atoms/text/detail/detailPageTitle";
import DetailPageLayout from "../../atoms/layout/detail/detailPageLayout";
import GoBack from "../../molecules/goBack";
import EditBtn from "../../atoms/button/editBtn";
import DetailPageForm from "../../atoms/layout/detail/detailPageForm";
import DetailFormTitle from "../../atoms/text/detail/detailFormTitle";
import DetailFormText from "../../atoms/text/detail/detailFormText";
import { toast } from "react-toastify";
import { toastMsg } from "../../../util/toastMsg";
import { errorHandler } from "../../../util/error-handler";
import { route } from "../../../router/route";
import DelBtn from "../../atoms/button/delBtn";
import Modal from "../Modal";
import { modalMsg } from "../../../util/modalMsg";
import moment from "moment";
import FileImgPreview from "../../molecules/fileImgPreview";
import TransactionApi from "../../../api/transaction.api";
import CategoryApi from "../../../api/category.api";
import Table from "../../atoms/layout/table/table";
import Tr from "../../atoms/layout/table/tr";
import Th from "../../atoms/layout/table/th";
import Checkbox from "../../atoms/input/checkbox";
import ThText from "../../atoms/text/table/thText";
import Td from "../../atoms/layout/table/td";
import TdText from "../../atoms/text/table/tdText";
import DetailBtn from "../../atoms/button/detailBtn";
import Pagenation from "../../molecules/pagenation";
import Pagination2 from "../../molecules/pagination2";
import MarketApi from "../../../api/market.api";
import PretendardText from "../../atoms/text/pretendardText";
import MerchApi from "../../../api/merch.api";
import ReviewApi from "../../../api/review.api";
import TableDelBtn from "../../atoms/button/tableDelBtn";
import TableFilterManager from "../../atoms/layout/table/tableFilterManager";

function MarketDeleteDetailTab(props) {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  let { id } = useParams();

  const tab = useMemo(() => {
    return Number(searchParams.get("tab"));
  }, [searchParams]);

  return (
    <>
      <div
        style={{
          display: "flex",
          marginBottom: "20px",
          gap: "10px",
        }}
      >
        <PretendardText
          style={{
            height: "63px",
            color: tab === 1 ? "#27BEFF" : "#ADB4BA",
            fontWeight: "700",
            fontSize: "18px",
            lineHeight: "25px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "19px 11.5px",
            borderBottom: tab === 1 ? "2px solid #27BEFF" : "",
            cursor: "pointer",
          }}
          onClick={() => {
            navigate(route.market_delete_detail_basic + `/${id}?tab=1`);
          }}
        >
          마켓 기본 정보
        </PretendardText>
        <PretendardText
          style={{
            height: "63px",
            color: tab === 2 ? "#27BEFF" : "#ADB4BA",
            fontWeight: "700",
            fontSize: "18px",
            lineHeight: "25px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "19px 11.5px",
            borderBottom: tab === 2 ? "2px solid #27BEFF" : "",
            cursor: "pointer",
          }}
          onClick={() => {
            navigate(route.market_delete_detail_merch + `/1/${id}?tab=2`);
          }}
        >
          등록된 상품
        </PretendardText>
        <PretendardText
          style={{
            height: "63px",
            color: tab === 3 ? "#27BEFF" : "#ADB4BA",
            fontWeight: "700",
            fontSize: "18px",
            lineHeight: "25px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "19px 11.5px",
            borderBottom: tab === 3 ? "2px solid #27BEFF" : "",
            cursor: "pointer",
          }}
          onClick={() => {
            navigate(route.market_delete_detail_review + `/1/${id}?tab=3`);
          }}
        >
          상품 리뷰
        </PretendardText>
      </div>
    </>
  );
}

export default MarketDeleteDetailTab;
