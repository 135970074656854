import React from "react";
import styled from "styled-components";

const Tr = (props) => {
  return <TrWrapper {...props} />;
};

export default Tr;

const TrWrapper = styled.tr.attrs((props) => {})``;
