import api from "./api.js";

export default class OpenSourceApi {
  static async Create(data) {
    return await api.post(`admin/openSource`, data);
  }
  static async GetList(params) {
    return await api.get(`admin/openSource`, params);
  }
  static async GetById(openSourceId) {
    return await api.get(`admin/openSource/${openSourceId}`);
  }
  static async Update(openSourceId, data) {
    return await api.put(`admin/openSource/${openSourceId}`, data);
  }
  static async Delete(data) {
    return await api.del(`admin/openSources`, "", data);
  }
}
