import React, { useEffect, useState } from "react";
import Header from "../../../component/organisms/header";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import Sidebar from "../../../component/organisms/sidebar";
import DetailTop from "../../../component/atoms/layout/detail/detailTop";
import DetailPage from "../../../component/atoms/layout/detail/detailPage";
import DetailPageTitle from "../../../component/atoms/text/detail/detailPageTitle";
import DetailPageLayout from "../../../component/atoms/layout/detail/detailPageLayout";
import GoBack from "../../../component/molecules/goBack";
import DetailPageForm from "../../../component/atoms/layout/detail/detailPageForm";
import DetailFormTitle from "../../../component/atoms/text/detail/detailFormTitle";
import MainInput from "../../../component/atoms/input/mainInput";
import SaveBtn from "../../../component/atoms/button/saveBtn";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { toastMsg } from "../../../util/toastMsg";
import { errorHandler } from "../../../util/error-handler";
import { nullCheck } from "../../../util/check";
import Modal from "../../../component/templates/Modal";
import { modalMsg } from "../../../util/modalMsg";
import TextArea from "../../../component/atoms/input/textarea";
import ImgPreview from "../../../component/molecules/imgPreview";
import ImgInput from "../../../component/atoms/input/imgInput";
import PretendardText from "../../../component/atoms/text/pretendardText";
import Checkbox from "../../../component/atoms/input/checkbox";
import DetailFormText from "../../../component/atoms/text/detail/detailFormText";
import styled from "styled-components";
import Radio from "../../../component/atoms/input/radio";
import MemberApi from "../../../api/member.api";

function MemberEdit(props) {
  const navigate = useNavigate();
  let { id } = useParams();

  const [data, setData] = useState({
    age: "",
    name: "",
    nickName: "",
    agreeToSms: true,
    agreeToService: true,
    agreeToAd: true,
  });

  // init
  const getData = async () => {
    try {
      const response = await MemberApi.GetById(id);
      const data = response.data.data;
      setData({
        ...data,
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  //저장하기
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const saveFunc = async () => {
    try {
      if (nullCheck(data.name) || nullCheck(data.nickName)) {
        setSaveModalOpen(false);
        return toast("필수사항을 모두 입력해주세요.");
      }

      const requestDto = {
        name: data.name,
        nickName: data.nickName,
        agreeToSms: data.agreeToSms,
        agreeToService: data.agreeToService,
        agreeToAd: data.agreeToAd,
      };

      await MemberApi.UpdateCommon(id, requestDto);
      setSaveModalOpen(false);
      navigate(-1);
      toast(toastMsg.update);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <GoBack />
          </DetailTop>
          <DetailPage style={{ minHeight: "375px" }}>
            <DetailPageTitle>수정하기</DetailPageTitle>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                marginBottom: "79px",
              }}
            >
              <DetailPageForm>
                <DetailFormTitle>이메일</DetailFormTitle>
                <MainInput value={data.email} disabled={true} />
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>성별*</DetailFormTitle>
                <div style={{ display: "flex", gap: "19px" }}>
                  <Radio id={"MAN"} valueText={"남성"} name={"gender"} checked={data.gender === "MALE"} />
                  <Radio id={"WOMAN"} valueText={"여성"} name={"gender"} checked={data.gender === "FEMALE"} />
                </div>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>나이</DetailFormTitle>
                <MainInput type={"text"} value={data.age} disabled={true} />
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>이름*</DetailFormTitle>
                <MainInput type={"text"} placeholder={"이름을 입력해주세요"} value={data.name} onChange={(e) => setData({ ...data, name: e.target.value })} />
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>닉네임*</DetailFormTitle>
                <MainInput type={"text"} placeholder={"닉네임을 입력해주세요"} value={data.nickName} onChange={(e) => setData({ ...data, nickName: e.target.value })} />
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>휴대폰 번호</DetailFormTitle>
                <MainInput value={data.phoneNumber} disabled={true} />
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>생년월일</DetailFormTitle>
                <MainInput value={data.birth} disabled={true} />
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>추천 인플루언서</DetailFormTitle>
                <MainInput value={data.recInfluencer} disabled={true} />
              </DetailPageForm>
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>약관 동의</DetailFormTitle>
                <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                  <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                    <DetailFormText>릴텍 회원이용약관 (필수)</DetailFormText>
                    <Checkbox checked={true} />
                  </div>
                  <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                    <DetailFormText>개인정보 수집 및 이용동의 (필수)</DetailFormText>
                    <Checkbox checked={true} />
                  </div>
                  <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                    <DetailFormText>개인정보 제 3자 제공 동의 (필수)</DetailFormText>
                    <Checkbox checked={true} />
                  </div>
                </div>
              </DetailPageForm>
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>알림 수신 동의</DetailFormTitle>
                <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                  <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                    <DetailFormText>이메일 및 SMS 마케팅정보수신 동의 (선택)</DetailFormText>
                    <Checkbox
                      checked={data.agreeToSms}
                      onChange={(e) => {
                        setData({ ...data, agreeToSms: e.target.checked });
                      }}
                    />
                  </div>
                  <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                    <DetailFormText>서비스 알림 수신 동의 (선택)</DetailFormText>
                    <Checkbox
                      checked={data.agreeToService}
                      onChange={(e) => {
                        setData({ ...data, agreeToService: e.target.checked });
                      }}
                    />
                  </div>
                  <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                    <DetailFormText>광고성 알림 수신 동의 (선택)</DetailFormText>
                    <Checkbox
                      checked={data.agreeToAd}
                      onChange={(e) => {
                        setData({ ...data, agreeToAd: e.target.checked });
                      }}
                    />
                  </div>
                </div>
              </DetailPageForm>
            </div>
            <SaveBtn
              text={"저장하기"}
              style={{ bottom: "40px", right: "40px" }}
              onClick={() => {
                setSaveModalOpen(true);
              }}
            />
          </DetailPage>
        </DetailPageLayout>
      </MainLayout>
      {saveModalOpen ? <Modal title={modalMsg.update?.title} text={modalMsg.update?.content} setModalOpen={setSaveModalOpen} onClick={saveFunc} /> : ""}
    </>
  );
}

export default MemberEdit;

const CheckboxWrapper = styled.div.attrs((props) => {})`
  display: flex;
  align-items: center;
  gap: 8px;
`;
