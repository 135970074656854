import React, { useEffect, useState } from "react";
import styled from "styled-components";
import TableTitle from "../../../component/atoms/text/table/tableTitle";
import DetailPageForm from "../../../component/atoms/layout/detail/detailPageForm";
import DetailFormTitle from "../../../component/atoms/text/detail/detailFormTitle";
import DetailFormText from "../../../component/atoms/text/detail/detailFormText";
import moment from "moment";
import SaveBtn from "../../../component/atoms/button/saveBtn";
import TextArea from "../../../component/atoms/input/textarea";
import { errorHandler } from "../../../util/error-handler";
import AskApi from "../../../api/ask.api";
import { toast } from "react-toastify";
import { toastMsg } from "../../../util/toastMsg";
import { nullCheck } from "../../../util/check";

function AskModal({ data, setModalOpen }) {
  const [isEdit, setIsEdit] = useState(false);

  const [answer, setAnswer] = useState("");

  useEffect(() => {
    if (data.answer) {
      setAnswer(data.answer);
    }
  }, [data.answer]);

  const saveFunc = async () => {
    try {
      if (nullCheck(answer)) {
        toast("답변을 입력해주세요.");
        return;
      }
      const requestDto = {
        answer,
      };

      await AskApi.Update(data.id, requestDto);
      toast(toastMsg.create);
      setIsEdit(false);
      const response = await AskApi.GetById(data.id);
      setAnswer(response.data.data.answer);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <ModalWrapper>
      <ModalBox>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "30px",
          }}
        >
          <TableTitle>문의 상세</TableTitle>
          <img
            style={{
              cursor: "pointer",
            }}
            src="/assets/admin/icons/ico_popup_close.svg"
            alt=""
            onClick={() => setModalOpen(false)}
          />
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <DetailPageForm>
            <DetailFormTitle>이메일</DetailFormTitle>
            <DetailFormText>{data.commonMemberInfo?.email}</DetailFormText>
          </DetailPageForm>
          <DetailPageForm>
            <DetailFormTitle>제목</DetailFormTitle>
            <DetailFormText>{data.title}</DetailFormText>
          </DetailPageForm>
          <DetailPageForm>
            <DetailFormTitle>작성일</DetailFormTitle>
            <DetailFormText>
              {moment(data.createdAt).format("YYYY/MM/DD")}
            </DetailFormText>
          </DetailPageForm>
          <DetailPageForm style={{ alignItems: "flex-start" }}>
            <DetailFormTitle>내용</DetailFormTitle>
            <DetailFormText>{data.content}</DetailFormText>
          </DetailPageForm>
          <div
            style={{ width: "100%", height: "5px", backgroundColor: "#F8F8F8" }}
          ></div>

          <DetailPageForm
            style={{ alignItems: "flex-start", marginBottom: "89px" }}
          >
            <DetailFormTitle>답변</DetailFormTitle>
            {isEdit ? (
              <TextArea
                value={answer}
                placeholder={"내용을 입력해 주세요."}
                onChange={(e) => {
                  setAnswer(e.target.value);
                }}
              />
            ) : (
              <DetailFormText>{answer}</DetailFormText>
            )}
          </DetailPageForm>
        </div>
        {isEdit ? (
          <SaveBtn
            text={"답변하기"}
            style={{ bottom: "50px", right: "50px" }}
            onClick={() => {
              saveFunc();
            }}
          />
        ) : (
          <SaveBtn
            text={nullCheck(answer) ? "답변하기" : "수정하기"}
            style={{ bottom: "50px", right: "50px" }}
            onClick={() => setIsEdit(true)}
          />
        )}
      </ModalBox>
    </ModalWrapper>
  );
}

export default AskModal;

const ModalWrapper = styled.div.attrs((props) => {})`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00000060;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 30;
`;

const ModalBox = styled.div.attrs((props) => {})`
  width: 1064px;
  min-height: 307px;
  height: auto;
  padding: 50px;
  border: 1px solid #d7dbe0;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
  position: relative;
`;
