import React from "react";
import styled from "styled-components";
import PretendardText from "../pretendardText";

const TableTitle = (props) => {
  return <TableTitleWrapper {...props} />;
};

export default TableTitle;

const TableTitleWrapper = styled(PretendardText).attrs((props) => {})`
  font-size: 32px;
  font-weight: 600;
  line-height: 44.16px;
  letter-spacing: -0.32px;
  color: #000;
  margin-bottom: 10px;
`;
