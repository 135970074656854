// 정규식 모음
export const regExpression = {
  // 비밀번호 - 영문 숫자 특수기호 조합 8자리 ~ 16자리
  password:
    /^(?!((?:[A-Z]+)|(?:[a-z]+)|(?:[~!@#$%^&*()_+=]+)|(?:[0-9]+))$)[A-Za-z\d~!@#$%^&*()_+=]{8,16}$/,

  // 이메일
  email: /^[a-zA-Z0-9+-_.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,

};
