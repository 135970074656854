import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import Header from "../../../component/organisms/header";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import Sidebar from "../../../component/organisms/sidebar";
import DetailTop from "../../../component/atoms/layout/detail/detailTop";
import DetailPage from "../../../component/atoms/layout/detail/detailPage";
import DetailPageTitle from "../../../component/atoms/text/detail/detailPageTitle";
import DetailPageLayout from "../../../component/atoms/layout/detail/detailPageLayout";
import GoBack from "../../../component/molecules/goBack";
import EditBtn from "../../../component/atoms/button/editBtn";
import DetailPageForm from "../../../component/atoms/layout/detail/detailPageForm";
import DetailFormTitle from "../../../component/atoms/text/detail/detailFormTitle";
import DetailFormText from "../../../component/atoms/text/detail/detailFormText";
import { toast } from "react-toastify";
import { toastMsg } from "../../../util/toastMsg";
import { errorHandler } from "../../../util/error-handler";
import { route } from "../../../router/route";
import DelBtn from "../../../component/atoms/button/delBtn";
import Modal from "../../../component/templates/Modal";
import { modalMsg } from "../../../util/modalMsg";
import Checkbox from "../../../component/atoms/input/checkbox";
import MemberApi from "../../../api/member.api";

function MemberDetail(props) {
  const navigate = useNavigate();
  let { id } = useParams();

  const [data, setData] = useState({
    email: "",
    gender: "",
    age: "",
    name: "",
    nickName: "",
    phoneNumber: "",
    birth: "",
    recInfluencer: "",
    agreeToSms: true,
    agreeToService: true,
    agreeToAd: true,
  });

  // init
  const getData = async () => {
    try {
      const response = await MemberApi.GetById(id);
      setData({
        ...response.data.data,
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  // ----- 삭제
  const [delModalOpen, setDelModalOpen] = useState(false);

  const deleteFunc = async () => {
    try {
      await MemberApi.DeleteById(id);
      toast(toastMsg.delete);
      setDelModalOpen(false);
      navigate(-1);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <GoBack />
            <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
              <DelBtn onClick={() => setDelModalOpen(true)} />
              <EditBtn
                onClick={() => {
                  navigate(route.member_member_edit + `/${id}`);
                }}
              />
            </div>
          </DetailTop>
          <DetailPage style={{ minHeight: "287px" }}>
            <DetailPageTitle>기본정보</DetailPageTitle>
            <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
              <DetailPageForm>
                <DetailFormTitle>이메일</DetailFormTitle>
                <DetailFormText>{data.email}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>성별</DetailFormTitle>
                <DetailFormText>{data.genderTitle}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>나이</DetailFormTitle>
                <DetailFormText>{data.age}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>이름</DetailFormTitle>
                <DetailFormText>{data.name}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>닉네임</DetailFormTitle>
                <DetailFormText>{data.nickName}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>휴대폰 번호</DetailFormTitle>
                <DetailFormText>{data.phoneNumber}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>생년월일</DetailFormTitle>
                <DetailFormText>{data.birth}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>추천 인플루언서</DetailFormTitle>
                <DetailFormText>{data.recInfluencer}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>약관 동의</DetailFormTitle>
                <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                  <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                    <DetailFormText>릴텍 회원이용약관 (필수)</DetailFormText>
                    <Checkbox checked={true} />
                  </div>
                  <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                    <DetailFormText>개인정보 수집 및 이용동의 (필수)</DetailFormText>
                    <Checkbox checked={true} />
                  </div>
                  <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                    <DetailFormText>개인정보 제 3자 제공 동의 (필수)</DetailFormText>
                    <Checkbox checked={true} />
                  </div>
                </div>
              </DetailPageForm>
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>알림 수신 동의</DetailFormTitle>
                <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                  <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                    <DetailFormText>이메일 및 SMS 마케팅정보수신 동의 (선택)</DetailFormText>
                    <Checkbox checked={data.agreeToSms} />
                  </div>
                  <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                    <DetailFormText>서비스 알림 수신 동의 (선택)</DetailFormText>
                    <Checkbox checked={data.agreeToService} />
                  </div>
                  <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                    <DetailFormText>광고성 알림 수신 동의 (선택)</DetailFormText>
                    <Checkbox checked={data.agreeToAd} />
                  </div>
                </div>
              </DetailPageForm>
            </div>
          </DetailPage>
        </DetailPageLayout>
      </MainLayout>
      {delModalOpen ? (
        <Modal
          title={modalMsg.delete?.title}
          text={modalMsg.delete?.content}
          btnTitle={"삭제하기"}
          setModalOpen={setDelModalOpen}
          onClick={() => {
            deleteFunc();
          }}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default MemberDetail;
