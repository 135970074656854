import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import Header from "../../../component/organisms/header";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import Sidebar from "../../../component/organisms/sidebar";
import TableLayout from "../../../component/atoms/layout/table/tableLayout";
import TableTitle from "../../../component/atoms/text/table/tableTitle";
import TableTop from "../../../component/atoms/layout/table/tableTop";
import TableSearchBtn from "../../../component/atoms/layout/table/tableSearchBtn";
import TableCount from "../../../component/molecules/tableCount";
import SearchBar from "../../../component/atoms/input/searchBar";
import Table from "../../../component/atoms/layout/table/table";
import Tr from "../../../component/atoms/layout/table/tr";
import Checkbox from "../../../component/atoms/input/checkbox";
import Th from "../../../component/atoms/layout/table/th";
import Td from "../../../component/atoms/layout/table/td";
import Pagenation from "../../../component/molecules/pagenation";
import { toast } from "react-toastify";
import { toastMsg } from "../../../util/toastMsg";
import moment from "moment";
import Modal from "../../../component/templates/Modal";
import { errorHandler } from "../../../util/error-handler";
import { route } from "../../../router/route";
import TableFilterManager from "../../../component/atoms/layout/table/tableFilterManager";
import ThText from "../../../component/atoms/text/table/thText";
import TdText from "../../../component/atoms/text/table/tdText";
import DelBtn from "../../../component/atoms/button/delBtn";
import DetailBtn from "../../../component/atoms/button/detailBtn";
import PretendardText from "../../../component/atoms/text/pretendardText";
import { modalMsg } from "../../../util/modalMsg";
import AddBtn from "../../../component/atoms/button/addBtn";
import TableDelBtn from "../../../component/atoms/button/tableDelBtn";
import ReportModal from "./reportModal";
import ReportApi from "../../../api/report.api";

function Report(props) {
  const navigate = useNavigate();
  const location = useLocation();

  const { page } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [pagination, setPagination] = useState({
    size: 10, // 한 페이지에 표시될 게시글 수
    rangeSize: 5, // 하단 페이지 사이즈

    totalCount: 0,
    totalPages: 0,
    first: false,
    last: false,
  });
  const [list, setList] = useState([]);
  const [keyword, setKeyword] = useState("");

  const getList = async () => {
    try {
      const data = {
        keyword: searchParams.get("keyword"),
        page: page - 1,
        size: pagination.size,
      };
      let list = (await ReportApi.GetList(data)).data.data;
      setList(
        list.content.map((item) => {
          return {
            ...item,
            checked: false,
          };
        })
      );
      setPagination({
        ...pagination,
        totalCount: list.totalElements,
        totalPages: list.totalPages,
        first: list.first,
        last: list.last,
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    setKeyword(searchParams.get("keyword") ? searchParams.get("keyword") : "");
    getList();
  }, [location]);

  // ----- 삭제
  const [delId, setDelId] = useState(0);
  const [delModalOpen, setDelModalOpen] = useState(false);

  const deleteOneFunc = async () => {
    try {
      await ReportApi.DeleteById(delId);
      toast(toastMsg.delete);
      setDelModalOpen(false);
      setDelId(0);
      getList();
    } catch (error) {
      errorHandler(error);
    }
  };

  // 상세
  const [selectedData, setSelectedData] = useState({});
  const [detailModalOpen, setDetailModalOpen] = useState(false);

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <TableLayout>
          <TableTitle>신고 내역 관리</TableTitle>
          <TableTop>
            <TableCount text={"항목"} count={pagination.totalCount} />
            <TableSearchBtn>
              <SearchBar
                value={keyword}
                onChange={(e) => {
                  setKeyword(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    navigate(`${route.service_report}/1?keyword=${keyword}`, { replace: true });
                  }
                }}
                onClick={() => {
                  navigate(`${route.service_report}/1?keyword=${keyword}`, { replace: true });
                }}
              />
            </TableSearchBtn>
          </TableTop>
          <Table>
            <colgroup>
              <col width="205px" />
              <col width="446px" />
              <col width="151px" />
              <col width="144px" />
              <col width="77px" />
            </colgroup>
            <thead>
              <Tr style={{ height: "41px", background: "#f2f4f5" }}>
                <Th>
                  <ThText
                    style={{
                      paddingLeft: "20px",
                    }}
                  >
                    이메일
                  </ThText>
                </Th>
                <Th>
                  <ThText>신고사유</ThText>
                </Th>
                <Th>
                  <ThText>작성일</ThText>
                </Th>
                <Th>
                  <ThText style={{ textAlign: "center", paddingRight: "95px" }}>삭제하기</ThText>
                </Th>
                <Th>
                  <ThText style={{ textAlign: "center", paddingRight: "20px" }}>상세보기</ThText>
                </Th>
              </Tr>
            </thead>
            <tbody>
              {list.map((item, index) => {
                return (
                  <Tr style={{ height: "57px", background: "#fff" }} key={index}>
                    <Td>
                      <TdText
                        style={{
                          width: "145px",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          wordBreak: "break-all",
                          paddingLeft: "20px",
                        }}
                      >
                        {item.commonMemberInfo?.email}
                      </TdText>
                    </Td>
                    <Td>
                      <TdText
                        style={{
                          width: "386px",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          wordBreak: "break-all",
                        }}
                      >
                        {item.reportReason === "ETC" ? item.etc : item.reportReasonTitle}
                      </TdText>
                    </Td>
                    <Td>
                      <TdText>{moment(item.createdAt).format("YYYY/MM/DD")}</TdText>
                    </Td>
                    <Td>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          paddingRight: "95px",
                        }}
                      >
                        <TableDelBtn
                          onClick={() => {
                            setDelId(item.id);
                            setDelModalOpen(true);
                          }}
                        />
                      </div>
                    </Td>
                    <Td>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          paddingRight: "20px",
                        }}
                      >
                        <DetailBtn
                          onClick={() => {
                            setSelectedData(item);
                            setDetailModalOpen(true);
                          }}
                        />
                      </div>
                    </Td>
                  </Tr>
                );
              })}
            </tbody>
          </Table>
          <Pagenation
            route={route.service_report}
            queryString={`keyword=${keyword}`}
            currentPage={page}
            totalPages={pagination.totalPages}
            rangeSize={pagination.rangeSize}
            first={pagination.first}
            last={pagination.last}
          />
        </TableLayout>
      </MainLayout>
      {detailModalOpen ? <ReportModal data={selectedData} setModalOpen={setDetailModalOpen} /> : ""}

      {delModalOpen ? (
        <Modal
          title={modalMsg.delete?.title}
          text={modalMsg.delete?.content}
          btnTitle={"삭제하기"}
          setModalOpen={setDelModalOpen}
          onClick={() => {
            deleteOneFunc();
          }}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default Report;
