import React, { useMemo } from "react";
import styled from "styled-components";
import TdText from "../../text/table/tdText";
import PretendardText from "../../text/pretendardText";
import { nullCheck } from "../../../../util/check";

function TableFilterManager({ style, onClick, isOpen, list, selectedValue, isApproved }) {
  const selectedTitle = useMemo(() => {
    return list.find((item) => item.value === selectedValue)?.title;
  }, [list, selectedValue]);

  return (
    <div>
      <TableFilterWrapper
        style={{
          ...style,
        }}
      >
        <TdText
          style={{
            color: "#262C31",
            fontWeight: "600",
          }}
        >
          {nullCheck(isApproved) || isApproved ? selectedTitle : "비활성"}
        </TdText>
        <img src="/assets/admin/icons/ico_arrow_down.svg" style={{ cursor: "pointer" }} onClick={onClick} alt="" />
      </TableFilterWrapper>
      {isOpen ? (
        <SubMenuWrapper>
          {list.map((item, index) => {
            return (
              <SubMenuTextWrapper
                key={index}
                onClick={() => {
                  if (item.value === selectedValue) {
                    return;
                  }
                  item.onClick();
                }}
              >
                <SubMenuText>{item.title}</SubMenuText>
                {item.value === selectedValue ? <img src="/assets/admin/icons/ico_filter_check.svg" alt="" /> : ""}
              </SubMenuTextWrapper>
            );
          })}
        </SubMenuWrapper>
      ) : (
        ""
      )}
    </div>
  );
}

export default TableFilterManager;

const TableFilterWrapper = styled.div.attrs((props) => {})`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const SubMenuWrapper = styled.div.attrs((props) => {})`
  min-width: 115px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  background: #fff;
  position: absolute;
  top: 48px;
  left: 0px;
  z-index: 2;
  box-shadow: 0px 4px 20px 0px rgba(10, 13, 20, 0.15);
`;

const SubMenuTextWrapper = styled.div.attrs((props) => {})`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 10px;
  border-radius: 3px;
  cursor: pointer;

  &:hover {
    background: #f2f4f5;
  }
`;

const SubMenuText = styled(PretendardText).attrs((props) => {})`
  color: #262c31;
  font-size: 15px;
  font-weight: 500;
  line-height: 21.5px;
`;
