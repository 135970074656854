import React from "react";
import styled from "styled-components";

function TableTop(props) {
  return (
    <>
      <TableTopWrapper
        style={{
          ...props.style,
        }}
      >
        {props.children}
      </TableTopWrapper>
    </>
  );
}

export default TableTop;

const TableTopWrapper = styled.div.attrs((props) => {})`
  width: 1044px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;
