import api from "./api.js";

export default class AlarmApi {
  static async Create(data) {
    return await api.post(`admin/alarm`, data);
  }
  static async GetList(params) {
    return await api.get(`admin/alarm`, params);
  }
  static async GetById(alarmId) {
    return await api.get(`admin/alarm/${alarmId}`);
  }
  static async Delete(data) {
    return await api.del(`admin/alarms`, "", data);
  }
  static async DeleteById(alarmId) {
    return await api.del(`admin/alarm/${alarmId}`);
  }
}
