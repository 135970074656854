import api from "./api.js";

export default class AskApi {
  static async GetList(params) {
    return await api.get(`admin/inquiries`, params);
  }
  static async GetById(inquiryId) {
    return await api.get(`admin/inquiry/${inquiryId}`);
  }
  static async Update(inquiryId, data) {
    return await api.put(`admin/inquiry/answer/${inquiryId}`, data);
  }
}
