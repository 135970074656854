import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Router from "./router/router";

function App() {
  return (
    <div className="App">
      <Router />
      <ToastContainer
        className="toast"
        position="top-center"
        autoClose={2000}
        hideProgressBar={true}
      />
    </div>
  );
}

export default App;
