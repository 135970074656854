import React from "react";
import styled from "styled-components";

function Radio({ id, checked, name, value, valueText, onChange, textStyle }) {
  return (
    <>
      <RadioWrapper>
        <RadioInput
          name={name}
          type="radio"
          id={id}
          value={value}
          checked={checked}
          onChange={onChange}
        />
        <RadioId htmlFor={id} style={{ ...textStyle }} checked={checked}>
          {valueText}
        </RadioId>
      </RadioWrapper>
    </>
  );
}

export default Radio;

const RadioWrapper = styled.div.attrs((props) => {})`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const RadioInput = styled.input.attrs((props) => {})`
  padding: 0;
  appearance: none;
  vertical-align: text-bottom;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 1px solid #ccd1d7;
  display: flex;
  background: url("/assets/admin/icons/ico_radio_unchecked.svg") center center no-repeat;
  cursor: pointer;

  &:checked {
    background: url("/assets/admin/icons/ico_radio_checked.svg") center center no-repeat;
    border: none;
  }
`;

const RadioId = styled.label.attrs((props) => {})`
  color: ${(props) => (props.checked ? "#262c31" : "#808991")};
  font-family: Pretendard;
  font-style: normal;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.048px;
  cursor: pointer;
`;
