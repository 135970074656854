import React, { useEffect, useState } from "react";
import Header from "../../../component/organisms/header";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import Sidebar from "../../../component/organisms/sidebar";
import DetailTop from "../../../component/atoms/layout/detail/detailTop";
import DetailPage from "../../../component/atoms/layout/detail/detailPage";
import DetailPageTitle from "../../../component/atoms/text/detail/detailPageTitle";
import DetailPageLayout from "../../../component/atoms/layout/detail/detailPageLayout";
import GoBack from "../../../component/molecules/goBack";
import DetailPageForm from "../../../component/atoms/layout/detail/detailPageForm";
import DetailFormTitle from "../../../component/atoms/text/detail/detailFormTitle";
import MainInput from "../../../component/atoms/input/mainInput";
import SaveBtn from "../../../component/atoms/button/saveBtn";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { toastMsg } from "../../../util/toastMsg";
import { errorHandler } from "../../../util/error-handler";
import { nullCheck } from "../../../util/check";
import Modal from "../../../component/templates/Modal";
import { modalMsg } from "../../../util/modalMsg";
import TextArea from "../../../component/atoms/input/textarea";
import DateInput from "../../../component/atoms/input/dateInput";
import ImgPreview from "../../../component/molecules/imgPreview";
import ImgInput from "../../../component/atoms/input/imgInput";
import PopupApi from "../../../api/popup.api";

function PopupEdit(props) {
  const navigate = useNavigate();
  let { id } = useParams();

  const [data, setData] = useState({
    title: "",
    startDate: "",
    endDate: "",
    file: "",
    deleteFile: false,

    fileUrl: "",
  });

  // init
  const getData = async () => {
    try {
      const response = await PopupApi.GetById(id);
      setData({
        ...response.data.data,
        deleteFile: false,
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  //이미지 추가, 삭제
  const inputFile = (e) => {
    const file = e.target.files[0];

    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = (value) => {
      setData({
        ...data,
        file: file,
        fileUrl: value.target.result,
        deleteFile: true,
      });
    };
    e.target.value = "";
  };

  const deleteFile = () => {
    setData({ ...data, file: "", fileUrl: "", deleteFile: true });
  };

  //저장하기
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const saveFunc = async () => {
    try {
      if (
        nullCheck(data.title) ||
        nullCheck(data.startDate) ||
        nullCheck(data.endDate) ||
        nullCheck(data.fileUrl)
      ) {
        setSaveModalOpen(false);
        return toast("필수사항을 모두 입력해주세요.");
      }

      const formData = new FormData();
      formData.append("title", data.title);
      formData.append("startDate", data.startDate);
      formData.append("endDate", data.endDate);
      formData.append("deleteFile", data.deleteFile);
      if (data.file) {
        formData.append("file", data.file);
      }

      await PopupApi.Update(id, formData);
      setSaveModalOpen(false);
      navigate(-1);
      toast(toastMsg.update);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <GoBack />
          </DetailTop>
          <DetailPage style={{ minHeight: "375px" }}>
            <DetailPageTitle>수정하기</DetailPageTitle>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                marginBottom: "79px",
              }}
            >
              <DetailPageForm>
                <DetailFormTitle>팝업명*</DetailFormTitle>
                <MainInput
                  type={"text"}
                  placeholder={"팝업명을 입력해주세요"}
                  value={data.title}
                  onChange={(e) => setData({ ...data, title: e.target.value })}
                />
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>게시기간*</DetailFormTitle>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <DateInput
                    value={data.startDate}
                    onChange={(e) =>
                      setData({ ...data, startDate: e.target.value })
                    }
                  />
                  <img src="/assets/admin/icons/ico_date_devider.svg" alt="" />
                  <DateInput
                    value={data.endDate}
                    onChange={(e) =>
                      setData({ ...data, endDate: e.target.value })
                    }
                  />
                </div>
              </DetailPageForm>
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>이미지*</DetailFormTitle>
                {data.fileUrl ? (
                  <ImgPreview
                    fileUrl={data.fileUrl}
                    onClick={() => {
                      deleteFile();
                    }}
                  />
                ) : (
                  <ImgInput
                    id={"file"}
                    accept={"image/*"}
                    onChange={(e) => {
                      inputFile(e);
                    }}
                  />
                )}
              </DetailPageForm>
            </div>
            <SaveBtn
              text={"수정하기"}
              style={{ bottom: "40px", right: "40px" }}
              onClick={() => {
                setSaveModalOpen(true);
              }}
            />
          </DetailPage>
        </DetailPageLayout>
      </MainLayout>
      {saveModalOpen ? (
        <Modal
          title={modalMsg.update?.title}
          text={modalMsg.update?.content}
          setModalOpen={setSaveModalOpen}
          onClick={saveFunc}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default PopupEdit;
