import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Header from "../../../component/organisms/header";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import Sidebar from "../../../component/organisms/sidebar";
import TableLayout from "../../../component/atoms/layout/table/tableLayout";
import TableTitle from "../../../component/atoms/text/table/tableTitle";
import DetailPage from "../../../component/atoms/layout/detail/detailPage";
import DetailPageForm from "../../../component/atoms/layout/detail/detailPageForm";
import DetailTop from "../../../component/atoms/layout/detail/detailTop";
import DetailFormTitle from "../../../component/atoms/text/detail/detailFormTitle";
import DetailFormText from "../../../component/atoms/text/detail/detailFormText";
import EditBtn from "../../../component/atoms/button/editBtn";
import { errorHandler } from "../../../util/error-handler";
import { toast } from "react-toastify";
import { toastMsg } from "../../../util/toastMsg";
import { route } from "../../../router/route";
import DetailPageTitle from "../../../component/atoms/text/detail/detailPageTitle";
import BaseApi from "../../../api/base.api";
import MarketApi from "../../../api/market.api";
import SaveBtn from "../../../component/atoms/button/saveBtn";
import { nullCheck } from "../../../util/check";
import Modal from "../../../component/templates/Modal";
import { modalMsg } from "../../../util/modalMsg";
import TextArea from "../../../component/atoms/input/textarea";
import MainInput from "../../../component/atoms/input/mainInput";

function MarketSetting(props) {
  const navigate = useNavigate();

  // init
  const getData = async () => {
    try {
      const response = await MarketApi.GetAccount();
      setAccountInfo({
        ...response.data.data,
      });
      const response2 = await MarketApi.GetRefundPolicy();
      setRefundPolicyInfo({
        ...response2.data.data,
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const [refundPolicyInfo, setRefundPolicyInfo] = useState({});
  const [accountInfo, setAccountInfo] = useState({});

  const [editFlag1, setEditFlag1] = useState(false);
  const [editFlag2, setEditFlag2] = useState(false);

  // 저장하기
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const saveFunc = async () => {
    try {
      if (nullCheck(refundPolicyInfo.content)) {
        setSaveModalOpen(false);
        return toast("필수사항을 모두 입력해주세요.");
      }

      const requestDto = {
        content: refundPolicyInfo.content,
      };

      await MarketApi.UpdateRefundPolicy(requestDto);
      setSaveModalOpen(false);
      setEditFlag1(false);
      toast(toastMsg.create);
    } catch (error) {
      errorHandler(error);
    }
  };

  const [saveAccountModalOpen, setSaveAccountModalOpen] = useState(false);
  const saveAccountFunc = async () => {
    try {
      if (nullCheck(accountInfo.account) || nullCheck(accountInfo.accountOwner) || nullCheck(accountInfo.bank)) {
        setSaveAccountModalOpen(false);
        return toast("필수사항을 모두 입력해주세요.");
      }

      const requestDto = {
        account: accountInfo.account,
        accountOwner: accountInfo.accountOwner,
        bank: accountInfo.bank,
      };

      await MarketApi.UpdateAccount(requestDto);
      setSaveAccountModalOpen(false);
      setEditFlag2(false);
      toast(toastMsg.create);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <TableLayout>
          <DetailTop>
            <TableTitle>마켓 설정</TableTitle>
          </DetailTop>
          <DetailPage style={{ minHeight: editFlag1 ? "347px" : "314px", marginBottom: "20px" }}>
            <DetailPageTitle>릴텍 환불 정책</DetailPageTitle>
            <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>내용</DetailFormTitle>
                {editFlag1 ? (
                  <TextArea
                    style={{
                      minHeight: "133px",
                    }}
                    placeholder={"약관내용을 입력해주세요"}
                    value={refundPolicyInfo.content}
                    onChange={(e) => setRefundPolicyInfo({ ...refundPolicyInfo, content: e.target.value })}
                  />
                ) : (
                  <DetailFormText>{refundPolicyInfo.content}</DetailFormText>
                )}
              </DetailPageForm>
            </div>
            {editFlag1 ? (
              <SaveBtn
                style={{ bottom: "40px", right: "40px" }}
                onClick={() => {
                  setSaveModalOpen(true);
                }}
              />
            ) : (
              <EditBtn
                text={"수정하기"}
                style={{ bottom: "40px", right: "40px", position: "absolute" }}
                onClick={() => {
                  setEditFlag1(true);
                }}
              />
            )}
          </DetailPage>

          <DetailPage style={{ minHeight: editFlag2 ? "398px" : "322px" }}>
            <DetailPageTitle>입금 계좌 정보</DetailPageTitle>
            <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
              <DetailPageForm>
                <DetailFormTitle>예금주명</DetailFormTitle>
                {editFlag2 ? (
                  <MainInput
                    type={"text"}
                    placeholder={"예금주명을 입력해주세요"}
                    value={accountInfo.accountOwner}
                    onChange={(e) => setAccountInfo({ ...accountInfo, accountOwner: e.target.value })}
                  />
                ) : (
                  <DetailFormText>{accountInfo.accountOwner}</DetailFormText>
                )}
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>입금은행</DetailFormTitle>
                {editFlag2 ? (
                  <MainInput
                    type={"text"}
                    placeholder={"입금은행을 입력해주세요"}
                    value={accountInfo.bank}
                    onChange={(e) => setAccountInfo({ ...accountInfo, bank: e.target.value })}
                  />
                ) : (
                  <DetailFormText>{accountInfo.bank}</DetailFormText>
                )}
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>입금계좌</DetailFormTitle>
                {editFlag2 ? (
                  <MainInput
                    type={"text"}
                    placeholder={"입금계좌을 입력해주세요"}
                    value={accountInfo.account}
                    onChange={(e) => setAccountInfo({ ...accountInfo, account: e.target.value })}
                  />
                ) : (
                  <DetailFormText>{accountInfo.account}</DetailFormText>
                )}
              </DetailPageForm>
            </div>
            {editFlag2 ? (
              <SaveBtn
                style={{ bottom: "40px", right: "40px" }}
                onClick={() => {
                  setSaveAccountModalOpen(true);
                }}
              />
            ) : (
              <EditBtn
                text={"수정하기"}
                style={{ bottom: "40px", right: "40px", position: "absolute" }}
                onClick={() => {
                  setEditFlag2(true);
                }}
              />
            )}
          </DetailPage>
        </TableLayout>
      </MainLayout>

      {saveModalOpen ? (
        <Modal
          title={modalMsg.update?.title}
          text={modalMsg.update?.content}
          setModalOpen={setSaveModalOpen}
          onClick={saveFunc}
        />
      ) : (
        ""
      )}

      {saveAccountModalOpen ? (
        <Modal
          title={modalMsg.update?.title}
          text={modalMsg.update?.content}
          setModalOpen={setSaveAccountModalOpen}
          onClick={saveAccountFunc}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default MarketSetting;
