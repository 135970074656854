import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { route } from "../../router/route";
import PretendardText from "../atoms/text/pretendardText";

const Header = (props) => {
  const navigate = useNavigate();

  return (
    <HeaderWrapper>
      <HeaderInfo>
        <img src="/assets/admin/icons/ico_profile.svg" alt="" />
        <PretendardText
          style={{
            fontSize: "14px",
            fontWeight: "600",
            lineHeight: "32.5px",
            letterSpacing: "-0.054px",
            color: "#262C31",
          }}
        >
          {sessionStorage.getItem("name") || "관리자 이메일"}
        </PretendardText>
      </HeaderInfo>
      <LogoutBtn
        onClick={() => {
          navigate(route.login);
          sessionStorage.clear();
        }}
      >
        로그아웃
      </LogoutBtn>
    </HeaderWrapper>
  );
};

export default Header;

const HeaderWrapper = styled.div.attrs((props) => {})`
  width: 100%;
  height: 73px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 15.8%;
  gap: 40px;
  position: absolute;
  z-index: 2;
  top: 0;
  border-bottom: 1px solid var(--gray-2, #e6e8eb);
`;

const HeaderInfo = styled.div.attrs((props) => {})`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const LogoutBtn = styled.button.attrs((props) => {})`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 88px;
  height: 41px;
  border-radius: 22px;
  background: #f2f4f5;
  color: #636c73;
  font-family: Pretendard;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 24.5px;

  &:hover {
    background: #d1d5d9;
  }
`;
