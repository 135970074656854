import React from "react";
import styled from "styled-components";

function TableSearchBtn(props) {
  return (
    <>
      <TableSearchBtnWrapper
        style={{
          ...props.style,
        }}
      >
        {props.children}
      </TableSearchBtnWrapper>
    </>
  );
}

export default TableSearchBtn;

const TableSearchBtnWrapper = styled.div.attrs((props) => {})`
  display: flex;
  align-items: center;
  gap: 10px;
`;
