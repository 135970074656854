import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import TableTitle from "../../../component/atoms/text/table/tableTitle";
import DetailPageForm from "../../../component/atoms/layout/detail/detailPageForm";
import DetailFormTitle from "../../../component/atoms/text/detail/detailFormTitle";
import DetailFormText from "../../../component/atoms/text/detail/detailFormText";
import moment from "moment";
import SaveBtn from "../../../component/atoms/button/saveBtn";
import TextArea from "../../../component/atoms/input/textarea";
import { errorHandler } from "../../../util/error-handler";
import AskApi from "../../../api/ask.api";
import { toast } from "react-toastify";
import { toastMsg } from "../../../util/toastMsg";
import { nullCheck } from "../../../util/check";
import FileImgPreview from "../../../component/molecules/fileImgPreview";
import DelBtn from "../../../component/atoms/button/delBtn";
import ColumnApi from "../../../api/column.api";
import Modal from "../../../component/templates/Modal";
import { modalMsg } from "../../../util/modalMsg";
import CommentApi from "../../../api/comment.api";

function CommentDetailModal({ data, setModalOpen, getList }) {
  // ----- 삭제
  const [delModalOpen, setDelModalOpen] = useState(false);

  const deleteFunc = async () => {
    try {
      await CommentApi.DeleteById(data.id);
      toast(toastMsg.delete);
      setDelModalOpen(false);
      setModalOpen(false);
      getList();
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <ModalWrapper>
      <ModalBox>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "30px",
          }}
        >
          <TableTitle>댓글 상세</TableTitle>
          <img
            style={{
              cursor: "pointer",
            }}
            src="/assets/admin/icons/ico_popup_close.svg"
            alt=""
            onClick={() => setModalOpen(false)}
          />
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <DetailPageForm>
            <DetailFormTitle>이메일</DetailFormTitle>
            <DetailFormText>{data.commonMember?.email}</DetailFormText>
          </DetailPageForm>
          <DetailPageForm>
            <DetailFormTitle>작성일</DetailFormTitle>
            <DetailFormText>{moment(data.createdAt).format("YYYY/MM/DD")}</DetailFormText>
          </DetailPageForm>
          <DetailPageForm>
            <DetailFormTitle>내용</DetailFormTitle>
            <DetailFormText>{data.content}</DetailFormText>
          </DetailPageForm>
          <DelBtn style={{ marginLeft: "auto" }} onClick={() => setDelModalOpen(true)} />
        </div>
      </ModalBox>

      {delModalOpen ? (
        <Modal
          title={modalMsg.delete?.title}
          text={modalMsg.delete?.content}
          btnTitle={"삭제하기"}
          setModalOpen={setDelModalOpen}
          onClick={() => {
            deleteFunc();
          }}
        />
      ) : (
        ""
      )}
    </ModalWrapper>
  );
}

export default CommentDetailModal;

const ModalWrapper = styled.div.attrs((props) => {})`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00000060;
  position: absolute;
  top: 0;
  z-index: 30;
  padding-top: 31px;
`;

const ModalBox = styled.div.attrs((props) => {})`
  width: 1064px;
  max-height: 400px;
  padding: 50px;
  border: 1px solid #d7dbe0;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
  position: relative;
`;
