import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CategoryApi from "../../../api/category.api";
import { errorHandler } from "../../../util/error-handler";
import { nullCheck } from "../../../util/check";
import { toast } from "react-toastify";
import Header from "../../../component/organisms/header";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import Sidebar from "../../../component/organisms/sidebar";
import DetailPageLayout from "../../../component/atoms/layout/detail/detailPageLayout";
import DetailTop from "../../../component/atoms/layout/detail/detailTop";
import GoBack from "../../../component/molecules/goBack";
import DetailPage from "../../../component/atoms/layout/detail/detailPage";
import DetailPageTitle from "../../../component/atoms/text/detail/detailPageTitle";
import DetailPageForm from "../../../component/atoms/layout/detail/detailPageForm";
import DetailFormTitle from "../../../component/atoms/text/detail/detailFormTitle";
import MainInput from "../../../component/atoms/input/mainInput";
import PretendardText from "../../../component/atoms/text/pretendardText";
import TextArea from "../../../component/atoms/input/textarea";
import Checkbox from "../../../component/atoms/input/checkbox";
import DetailFormText from "../../../component/atoms/text/detail/detailFormText";
import ImgPreview from "../../../component/molecules/imgPreview";
import ImgInput from "../../../component/atoms/input/imgInput";
import Radio from "../../../component/atoms/input/radio";
import SaveBtn from "../../../component/atoms/button/saveBtn";
import Modal from "../../../component/templates/Modal";
import { modalMsg } from "../../../util/modalMsg";
import styled from "styled-components";
import { route } from "../../../router/route";
import MarketApi from "../../../api/market.api";
import { toastMsg } from "../../../util/toastMsg";
import MarketRegisterDetailTab from "../../../component/templates/market/marketRegisterDetailTab";

function MarketRegisterEditBasic(props) {
  const navigate = useNavigate();
  let { id } = useParams();

  const [data, setData] = useState({});

  // init
  const getData = async () => {
    try {
      const response = await MarketApi.GetById(id);
      setData({
        ...response.data.data,
        deleteBgFile: false,
        deleteLogoFile: false,
        deleteProfileFile: false,
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const [categoryList, setCategoryList] = useState([]);

  const getCategoryList = async () => {
    try {
      let list = (await CategoryApi.GetListAll(data)).data.data;
      setCategoryList(
        list.map((item) => {
          return {
            ...item,
          };
        })
      );
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    getCategoryList();
  }, []);

  //이미지 추가, 삭제
  const inputFile = (e, type) => {
    const file = e.target.files[0];

    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = (value) => {
      switch (type) {
        case "bg":
          setData({
            ...data,
            bgFile: file,
            bgFileUrl: value.target.result,
            deleteBgFile: true,
          });
          break;
        case "logo":
          setData({
            ...data,
            logoFile: file,
            logoFileUrl: value.target.result,
            deleteLogoFile: true,
          });
          break;
        case "profile":
          setData({
            ...data,
            profileFile: file,
            profileFileUrl: value.target.result,
            deleteProfileFile: true,
          });
          break;
        default:
          break;
      }
    };
    e.target.value = "";
  };

  const deleteFile = (type) => {
    switch (type) {
      case "bg":
        setData({ ...data, bgFile: "", bgFileUrl: "", deleteBgFile: true });
        break;
      case "logo":
        setData({
          ...data,
          logoFile: "",
          logoFileUrl: "",
          deleteLogoFile: true,
        });
        break;
      case "profile":
        setData({
          ...data,
          profileFile: "",
          profileFileUrl: "",
          deleteProfileFile: true,
        });
        break;
      default:
        break;
    }
  };

  //저장하기
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const saveFunc = async () => {
    try {
      if (nullCheck(data.name) || nullCheck(data.categoryIdList) || nullCheck(data.explanation)) {
        setSaveModalOpen(false);
        return toast("필수사항을 모두 입력해주세요.");
      }
      const formData = new FormData();
      formData.append("isActive", data.isActive);
      formData.append("name", data.name);
      formData.append("deleteBgFile", data.deleteBgFile);
      formData.append("deleteLogoFile", data.deleteLogoFile);
      formData.append("deleteProfileFile", data.deleteProfileFile);
      formData.append("isRecommended", data.isRecommended);
      formData.append("snsId", data.snsId);
      formData.append("snsLink", data.snsLink);
      formData.append("snsType", data.snsType);
      formData.append("explanation", data.explanation);
      formData.append("useAd", data.useAd);

      for (let index = 0; index < data.categoryIdList.length; index++) {
        const element = data.categoryIdList[index];
        formData.append("categoryIdList", element);
      }

      if (data.bgFile) {
        formData.append("bgFile", data.bgFile);
      }

      if (data.logoFile) {
        formData.append("logoFile", data.logoFile);
      }

      if (data.profileFile) {
        formData.append("profileFile", data.profileFile);
      }

      await MarketApi.Update(id, formData);
      setSaveModalOpen(false);
      navigate(-1);
      toast(toastMsg.create);
    } catch (error) {
      errorHandler(error);
    }
  };

  // tab
  const [tab, setTab] = useState(0);

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <MarketRegisterDetailTab />

          <DetailTop>
            <GoBack />
          </DetailTop>
          <DetailPage style={{ minHeight: "1499px" }}>
            <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
              <DetailPageTitle>수정하기</DetailPageTitle>
              <DetailPageForm>
                <DetailFormTitle>이메일</DetailFormTitle>
                <DetailFormText>{data.influencerInfo?.email}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>이름</DetailFormTitle>
                <DetailFormText>{data.influencerInfo?.name}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>닉네임</DetailFormTitle>
                <DetailFormText>{data.influencerInfo?.nickName}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>상태*</DetailFormTitle>
                <div style={{ display: "flex", gap: "19px" }}>
                  <Radio
                    id={"ACTIVE"}
                    valueText={"활성"}
                    checked={data.isActive}
                    onChange={() => {
                      setData({ ...data, isActive: true });
                    }}
                  />
                  <Radio
                    id={"DEACTIVE"}
                    valueText={"비활성"}
                    checked={!data.isActive}
                    onChange={() => {
                      setData({ ...data, isActive: false });
                    }}
                  />
                </div>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>마켓명*</DetailFormTitle>
                <MainInput type={"text"} placeholder={"마켓명을 입력해주세요"} value={data.name} onChange={(e) => setData({ ...data, name: e.target.value })} />
              </DetailPageForm>
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>
                  카테고리*
                  <br />
                  <span
                    style={{
                      fontSize: "12px",
                      fontWeight: "400",
                      lineHeight: "24.5px",
                      color: "#ADB4BA",
                    }}
                  >
                    (최대 3개)
                  </span>
                </DetailFormTitle>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "11px",
                  }}
                >
                  {categoryList.map((item, index) => {
                    return (
                      <CheckboxWrapper key={index}>
                        <Checkbox
                          style={{
                            width: "18px",
                            height: "18px",
                          }}
                          checked={data.categoryIdList?.includes(item.id)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              if (data.categoryIdList.length === 3) {
                                toast("최대 3개까지 선택가능합니다.");
                                return;
                              }
                              data.categoryIdList.push(item.id);
                            } else {
                              data.categoryIdList = data.categoryIdList.filter((categoryId) => categoryId !== item.id);
                            }
                            setData({ ...data });
                          }}
                        />
                        <DetailFormText style={{ color: "#808991", fontWeight: "500" }}>{item.title}</DetailFormText>
                      </CheckboxWrapper>
                    );
                  })}
                </div>
              </DetailPageForm>
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>
                  프로필 이미지*
                  <br />
                  <span
                    style={{
                      fontSize: "12px",
                      fontWeight: "400",
                      lineHeight: "24.5px",
                      color: "#ADB4BA",
                    }}
                  >
                    *권장 사이즈 160 X 160
                  </span>
                </DetailFormTitle>
                {data.profileFileUrl ? (
                  <ImgPreview
                    fileUrl={data.profileFileUrl}
                    onClick={() => {
                      deleteFile("profile");
                    }}
                  />
                ) : (
                  <ImgInput
                    id={"file"}
                    accept={"image/*"}
                    onChange={(e) => {
                      inputFile(e, "profile");
                    }}
                  />
                )}
              </DetailPageForm>
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>
                  배경 이미지*
                  <br />
                  <span
                    style={{
                      fontSize: "12px",
                      fontWeight: "400",
                      lineHeight: "24.5px",
                      color: "#ADB4BA",
                    }}
                  >
                    *권장 사이즈 750 X 750
                  </span>
                </DetailFormTitle>
                {data.bgFileUrl ? (
                  <ImgPreview
                    fileUrl={data.bgFileUrl}
                    onClick={() => {
                      deleteFile("bg");
                    }}
                  />
                ) : (
                  <ImgInput
                    id={"file"}
                    accept={"image/*"}
                    onChange={(e) => {
                      inputFile(e, "bg");
                    }}
                  />
                )}
              </DetailPageForm>
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>
                  로고 이미지*
                  <br />
                  <span
                    style={{
                      fontSize: "12px",
                      fontWeight: "400",
                      lineHeight: "24.5px",
                      color: "#ADB4BA",
                    }}
                  >
                    *권장 사이즈 300 X 80
                  </span>
                </DetailFormTitle>
                {data.bgFileUrl ? (
                  <ImgPreview
                    fileUrl={data.logoFileUrl}
                    onClick={() => {
                      deleteFile("logo");
                    }}
                  />
                ) : (
                  <ImgInput
                    id={"file"}
                    accept={"image/*"}
                    onChange={(e) => {
                      inputFile(e, "logo");
                    }}
                  />
                )}
              </DetailPageForm>
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>
                  마켓 요약 설명*
                  <br />
                  <span
                    style={{
                      fontSize: "12px",
                      fontWeight: "400",
                      lineHeight: "24.5px",
                      color: "#ADB4BA",
                    }}
                  >
                    (최대 30자)
                  </span>
                </DetailFormTitle>
                <TextArea
                  style={{
                    minHeight: "133px",
                  }}
                  placeholder={"브랜드 설명을 입력해주세요"}
                  value={data.explanation}
                  onChange={(e) => setData({ ...data, explanation: e.target.value })}
                  maxLength={30}
                />
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>SNS 유형</DetailFormTitle>
                <div style={{ display: "flex", gap: "20px" }}>
                  <Radio
                    id={"INSTAGRAM"}
                    valueText={"인스타그램"}
                    checked={data.snsType === "INSTAGRAM"}
                    onChange={() => {
                      setData({ ...data, snsType: "INSTAGRAM" });
                    }}
                  />
                  <Radio
                    id={"FACEBOOK"}
                    valueText={"페이스북"}
                    checked={data.snsType === "FACEBOOK"}
                    onChange={() => {
                      setData({ ...data, snsType: "FACEBOOK" });
                    }}
                  />
                  <Radio
                    id={"TWEETER"}
                    valueText={"트위터"}
                    checked={data.snsType === "TWEETER"}
                    onChange={() => {
                      setData({ ...data, snsType: "TWEETER" });
                    }}
                  />
                  <Radio
                    id={"YOUTUBE"}
                    valueText={"유튜브"}
                    checked={data.snsType === "YOUTUBE"}
                    onChange={() => {
                      setData({ ...data, snsType: "YOUTUBE" });
                    }}
                  />
                  <Radio
                    id={"NAVER"}
                    valueText={"네이버블로그"}
                    checked={data.snsType === "NAVER"}
                    onChange={() => {
                      setData({ ...data, snsType: "NAVER" });
                    }}
                  />
                </div>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>SNS 아이디</DetailFormTitle>
                <MainInput type={"text"} placeholder={"SNS아이디를 입력해주세요"} value={data.snsId} onChange={(e) => setData({ ...data, snsId: e.target.value })} />
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>SNS 링크</DetailFormTitle>
                <MainInput type={"text"} placeholder={"SNS 링크를 입력해주세요"} value={data.snsLink} onChange={(e) => setData({ ...data, snsLink: e.target.value })} />
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>추천마켓 여부</DetailFormTitle>
                <div style={{ display: "flex", gap: "19px" }}>
                  <Radio
                    id={"YES"}
                    valueText={"예"}
                    checked={data.isRecommended}
                    onChange={() => {
                      setData({ ...data, isRecommended: true });
                    }}
                  />
                  <Radio
                    id={"NO"}
                    valueText={"아니오"}
                    checked={!data.isRecommended}
                    onChange={() => {
                      setData({ ...data, isRecommended: false });
                    }}
                  />
                </div>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>광고 게시 여부</DetailFormTitle>
                <div style={{ display: "flex", gap: "19px" }}>
                  <Radio
                    id={"YES2"}
                    valueText={"예"}
                    checked={data.useAd}
                    onChange={() => {
                      setData({ ...data, useAd: true });
                    }}
                  />
                  <Radio
                    id={"NO2"}
                    valueText={"아니오"}
                    checked={!data.useAd}
                    onChange={() => {
                      setData({ ...data, useAd: false });
                    }}
                  />
                </div>
              </DetailPageForm>
            </div>

            <SaveBtn
              text={"저장하기"}
              style={{ bottom: "40px", right: "40px" }}
              onClick={() => {
                setSaveModalOpen(true);
              }}
            />
          </DetailPage>
        </DetailPageLayout>
      </MainLayout>
      {saveModalOpen ? <Modal title={modalMsg.update?.title} text={modalMsg.update?.content} setModalOpen={setSaveModalOpen} onClick={saveFunc} /> : ""}
    </>
  );
}

export default MarketRegisterEditBasic;

const CheckboxWrapper = styled.div.attrs((props) => {})`
  display: flex;
  align-items: center;
  gap: 8px;
`;
