import api from "./api.js";

export default class ManagerApi {
  static async GetList(params) {
    return await api.get(`admin/managers`, params);
  }
  static async GetById(managerId) {
    return await api.get(`admin/manager/${managerId}`);
  }
  static async Update(managerId, data) {
    return await api.put(`admin/manager/${managerId}`, data);
  }
  static async Delete(data) {
    return await api.del(`admin/managers`, "", data);
  }

  static async Approve(managerId, data) {
    return await api.put(`admin/manager/approve/${managerId}`, data);
  }
  static async Activation(managerId, data) {
    return await api.put(`admin/manager/activation/${managerId}`, data);
  }

  static async Join(data) {
    return await api.post(`manager`, data);
  }
  static async Login(data) {
    return await api.post(`login/manager`, data);
  }
}
