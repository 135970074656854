import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { route } from "../../router/route";
import PretendardText from "../atoms/text/pretendardText";

const Sidebar = (props) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(0);

  //경로 저장
  const location = useLocation();
  const [path, setPath] = useState(null);
  const [menus, setMenus] = useState([
    {
      title: "홈",
      link: route.home,
      type: "noList",
      subMenus: [],
      isView: true,
    },
    {
      title: "사용자 관리",
      link: route.member,
      subMenus: [
        {
          title: "일반회원 관리",
          link: route.member_member,
          isView: true,
        },
        {
          title: "인플루언서 관리",
          link: route.member_influencer,
          isView: true,
        },
        {
          title: "탈퇴회원 관리",
          link: route.member_leave,
          isView: true,
        },
      ],
      isView: true,
    },
    {
      title: "마켓 관리",
      link: route.market,
      subMenus: [
        {
          title: "등록된 마켓 관리",
          link: route.market_register,
          isView: true,
        },
        {
          title: "삭제된 마켓 관리",
          link: route.market_delete,
          isView: true,
        },
        {
          title: "카테고리 관리",
          link: route.market_category,
          isView: true,
        },
        {
          title: "마켓 설정",
          link: route.market_setting,
          isView: true,
        },
      ],
      isView: true,
    },
    {
      title: "거래 관리",
      link: route.transaction,
      subMenus: [
        {
          title: "거래내역 관리",
          link: route.transaction_purchase,
          isView: true,
        },
        {
          title: "환불내역 관리",
          link: route.transaction_refund,
          isView: true,
        },
        {
          title: "정산 요청 관리",
          link: route.transaction_settle,
          isView: true,
        },
      ],
      isView: true,
    },
    {
      title: "기타 관리",
      link: route.etc,
      subMenus: [
        {
          title: "칼럼 관리",
          link: route.etc_column,
          isView: true,
        },
        {
          title: "스타일 추천 관리",
          link: route.etc_style,
          isView: true,
        },
      ],
      isView: true,
    },
    {
      title: "서비스 관리",
      link: route.service,
      subMenus: [
        {
          title: "알림 관리",
          link: route.service_alarm,
          isView: true,
        },
        {
          title: "1:1 문의 관리",
          link: route.service_ask,
          isView: true,
        },
        {
          title: "신고 내역 관리",
          link: route.service_report,
          isView: true,
        },
        {
          title: "공지사항 관리",
          link: route.service_notice,
          isView: true,
        },
        {
          title: "FAQ 관리",
          link: route.service_faq,
          isView: true,
        },
        {
          title: "팝업 관리",
          link: route.service_popup,
          isView: true,
        },
        {
          title: "약관 관리",
          link: route.service_terms,
          isView: true,
        },
        {
          title: "오픈소스 관리",
          link: route.service_openSource,
          isView: true,
        },
        {
          title: "기본 관리",
          link: route.service_basic,
          isView: true,
        },
      ],
      isView: true,
    },
    {
      title: "관리자회원",
      link: route.manager,
      subMenus: [],
      isView: true,
    },
  ]);

  useEffect(() => {
    setPath(location.pathname);
  }, [location]);

  useEffect(() => {
    setPath(location.pathname);
    if (sessionStorage.getItem("managerAuthority") !== "ROOT") {
      const accessMenu = sessionStorage.getItem("accessMenu");
      for (let index = 0; index < menus.length; index++) {
        const element = menus[index];
        if (element.subMenus.length > 0) {
          for (let index2 = 0; index2 < element.subMenus.length; index2++) {
            const element2 = element.subMenus[index2];
            if (!accessMenu?.includes(element2.link)) {
              element2.isView = false;
            }
          }
          if (element.subMenus.filter((item) => !item.isView).length === element.subMenus.length) {
            element.isView = false;
          }
        } else {
          if (!accessMenu?.includes(element.link)) {
            element.isView = false;
          }
        }
      }
      setMenus([...menus]);
    }
  }, [location]);

  return (
    <SidebarWrapper style={{ ...props.style }}>
      <ProfileWrapper>
        <img src="/assets/admin/icons/ico_profile02.svg" />
        <SidebarPretendardText
          style={{
            color: "#fff",
            fontSize: "14px",
            fontWeight: "600",
            letterSpacing: "-0.042px",
            cursor: "unset",
          }}
        >
          {sessionStorage.getItem("name") || "관리자 이메일"}
        </SidebarPretendardText>
      </ProfileWrapper>
      <NavListWrapper>
        {menus.map((item, index) => {
          return (
            <div key={index}>
              {item.isView ? (
                <NavWrapper
                  onClick={() => {
                    if (item.subMenus.length > 0) {
                      isOpen === item.link ? setIsOpen(0) : setIsOpen(item.link);
                    } else {
                      navigate(item.type === "noList" ? item.link : item.link + `/1`);
                      sessionStorage.removeItem("tab");
                    }
                  }}
                >
                  <SidebarPretendardText
                    style={{
                      color: `${path?.includes(item.link) || isOpen === item.link ? "#27BEFF" : ""}`,
                    }}
                  >
                    {item.title}
                  </SidebarPretendardText>
                  {item.subMenus.length > 0 ? <img src={`/assets/admin/icons/ico_sidebar_${isOpen === item.link || path?.includes(item.link) ? "minus" : "plus"}.svg`} /> : null}
                </NavWrapper>
              ) : null}
              <SubmenuListWrapper>
                {item.subMenus.length > 0
                  ? item.subMenus.map((item2, index2) => {
                      return path?.includes(item.link) || isOpen === item.link ? (
                        <div key={index2}>
                          {item2.isView ? (
                            <SubmenuWrapper
                              onClick={() => {
                                navigate(item2.link + `/1`);
                                sessionStorage.removeItem("tab");
                              }}
                              style={{
                                backgroundColor: `${path?.includes(item2.link) ? "#ffffff10" : ""}`,
                                cursor: "pointer",
                              }}
                            >
                              <SubPretendardText
                                style={{
                                  color: `${path?.includes(item2.link) ? "#FFF" : ""}`,
                                }}
                              >
                                {item2.title}
                              </SubPretendardText>
                            </SubmenuWrapper>
                          ) : null}
                        </div>
                      ) : null;
                    })
                  : null}
              </SubmenuListWrapper>
            </div>
          );
        })}
      </NavListWrapper>
    </SidebarWrapper>
  );
};

export default Sidebar;

const SidebarWrapper = styled.div.attrs((props) => {})`
  width: 265px;
  min-height: 100vh;
  height: auto;
  background-color: #2e313e;
  padding-top: 83px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  position: relative;
  z-index: 5;
`;

const ProfileWrapper = styled.div.attrs((props) => {})`
  width: 100%;
  height: 58px;
  padding: 10px 25px;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const NavListWrapper = styled.div.attrs((props) => {})`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
`;

const NavWrapper = styled.div.attrs((props) => {})`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 25px;
  cursor: pointer;
`;

const SidebarPretendardText = styled(PretendardText).attrs((props) => {})`
  color: #f2f4f5;
  font-size: 18px;
  font-weight: 600;
  line-height: 32.5px;
  letter-spacing: -0.054px;
  cursor: pointer;
`;

const SubmenuListWrapper = styled.div.attrs((props) => {})`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 13.5px;
`;

const SubmenuWrapper = styled.div.attrs((props) => {})`
  width: 236px;
  height: 46px;
  display: flex;
  align-items: center;
  padding: 14px 25px;
  border-radius: 8px;
`;

const SubPretendardText = styled(PretendardText).attrs((props) => {})`
  color: #778088;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.048px;
  cursor: pointer;
`;
