import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import Header from "../../../component/organisms/header";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import Sidebar from "../../../component/organisms/sidebar";
import DetailTop from "../../../component/atoms/layout/detail/detailTop";
import DetailPage from "../../../component/atoms/layout/detail/detailPage";
import DetailPageTitle from "../../../component/atoms/text/detail/detailPageTitle";
import DetailPageLayout from "../../../component/atoms/layout/detail/detailPageLayout";
import GoBack from "../../../component/molecules/goBack";
import EditBtn from "../../../component/atoms/button/editBtn";
import DetailPageForm from "../../../component/atoms/layout/detail/detailPageForm";
import DetailFormTitle from "../../../component/atoms/text/detail/detailFormTitle";
import DetailFormText from "../../../component/atoms/text/detail/detailFormText";
import { toast } from "react-toastify";
import { toastMsg } from "../../../util/toastMsg";
import { errorHandler } from "../../../util/error-handler";
import { route } from "../../../router/route";
import DelBtn from "../../../component/atoms/button/delBtn";
import Modal from "../../../component/templates/Modal";
import { modalMsg } from "../../../util/modalMsg";
import moment from "moment";
import FileImgPreview from "../../../component/molecules/fileImgPreview";
import TransactionApi from "../../../api/transaction.api";
import CategoryApi from "../../../api/category.api";
import Table from "../../../component/atoms/layout/table/table";
import Tr from "../../../component/atoms/layout/table/tr";
import Th from "../../../component/atoms/layout/table/th";
import Checkbox from "../../../component/atoms/input/checkbox";
import ThText from "../../../component/atoms/text/table/thText";
import Td from "../../../component/atoms/layout/table/td";
import TdText from "../../../component/atoms/text/table/tdText";
import DetailBtn from "../../../component/atoms/button/detailBtn";
import Pagenation from "../../../component/molecules/pagenation";
import Pagination2 from "../../../component/molecules/pagination2";
import MarketApi from "../../../api/market.api";
import PretendardText from "../../../component/atoms/text/pretendardText";
import MerchApi from "../../../api/merch.api";
import ReviewApi from "../../../api/review.api";
import TableDelBtn from "../../../component/atoms/button/tableDelBtn";
import TableFilterManager from "../../../component/atoms/layout/table/tableFilterManager";
import TextArea from "../../../component/atoms/input/textarea";
import MainInput from "../../../component/atoms/input/mainInput";
import ImgPreview from "../../../component/molecules/imgPreview";
import ImgInput from "../../../component/atoms/input/imgInput";
import Radio from "../../../component/atoms/input/radio";
import SaveBtn from "../../../component/atoms/button/saveBtn";
import MarketRegisterDetailTab from "../../../component/templates/market/marketRegisterDetailTab";
import { nullCheck } from "../../../util/check";

function MarketRegisterDetailMerchEdit(props) {
  const navigate = useNavigate();
  let { merchId } = useParams();

  const [data, setData] = useState({});

  // init
  const getData = async () => {
    try {
      const response = await MerchApi.GetById(merchId);
      setData({
        ...response.data.data,
        deleteImageIdList: [],
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  //이미지 추가, 삭제
  const inputFiles = (e) => {
    const file = e.target.files[0];
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = (obj) => {
      const temp = {
        file: file,
        fileUrl: obj.target.result,
        originFileName: file.name,
      };
      data.images.push(temp);
      setData({ ...data });
    };

    e.target.value = "";
  };

  const deleteFiles = (index) => {
    const element = data.images[index];
    console.log(element);
    if (element.id) {
      data.deleteImageIdList.push(element.id);
    }
    data.images.splice(index, 1);
    setData({ ...data });
  };

  //저장하기
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const saveFunc = async () => {
    try {
      if (
        nullCheck(data.name) ||
        nullCheck(data.explanation) ||
        nullCheck(data.price) ||
        nullCheck(data.deliveryFee) ||
        nullCheck(data.chatLink) ||
        nullCheck(data.content)
      ) {
        setSaveModalOpen(false);
        return toast("필수사항을 모두 입력해주세요.");
      }
      const formData = new FormData();
      formData.append("name", data.name);
      formData.append("chatLink", data.chatLink);
      formData.append("content", data.content);
      formData.append("deliveryFee", data.deliveryFee);
      formData.append("explanation", data.explanation);
      formData.append("merchStatus", data.merchStatus);
      formData.append("price", data.price);

      if (data.deleteImageIdList.length > 0) {
        for (let index = 0; index < data.deleteImageIdList.length; index++) {
          const element = data.deleteImageIdList[index];
          formData.append("deleteImageIdList", element);
        }
      }

      for (let index = 0; index < data.images.length; index++) {
        const element = data.images[index];
        if (element.file) {
          formData.append("files", element.file);
        }
      }

      await MerchApi.Update(merchId, formData);
      setSaveModalOpen(false);
      navigate(-1);
      toast(toastMsg.create);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <MarketRegisterDetailTab />

          <>
            <DetailTop>
              <GoBack />
            </DetailTop>

            <DetailPage style={{ minHeight: "841px", marginBottom: "20px" }}>
              <DetailPageTitle>상세보기</DetailPageTitle>
              <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                <DetailPageForm>
                  <DetailFormTitle>상품명*</DetailFormTitle>
                  <MainInput
                    type={"text"}
                    placeholder={"상품명을 입력해주세요"}
                    value={data.name}
                    onChange={(e) => setData({ ...data, name: e.target.value })}
                  />
                </DetailPageForm>
                <DetailPageForm style={{ alignItems: "flex-start" }}>
                  <DetailFormTitle>
                    상품 요약 설명*
                    <br />
                    <span
                      style={{
                        fontSize: "12px",
                        fontWeight: "400",
                        lineHeight: "24.5px",
                        color: "#ADB4BA",
                      }}
                    >
                      (최대 30자)
                    </span>
                  </DetailFormTitle>
                  <TextArea
                    style={{
                      minHeight: "133px",
                    }}
                    placeholder={"상품 설명을 입력해주세요"}
                    value={data.explanation}
                    onChange={(e) => setData({ ...data, explanation: e.target.value })}
                    maxLength={30}
                  />
                </DetailPageForm>
                <DetailPageForm>
                  <DetailFormTitle>금액*</DetailFormTitle>
                  <MainInput
                    type={"text"}
                    placeholder={"금액을 입력해주세요"}
                    value={data.price}
                    onChange={(e) => setData({ ...data, price: e.target.value.replace(/[^0-9]/g, "") })}
                  />
                </DetailPageForm>
                <DetailPageForm>
                  <DetailFormTitle>배송비*</DetailFormTitle>
                  <MainInput
                    type={"text"}
                    placeholder={"배송비를 입력해주세요"}
                    value={data.deliveryFee}
                    onChange={(e) => setData({ ...data, deliveryFee: e.target.value.replace(/[^0-9]/g, "") })}
                  />
                </DetailPageForm>
                <DetailPageForm>
                  <DetailFormTitle>채팅 링크*</DetailFormTitle>
                  <MainInput
                    type={"text"}
                    placeholder={"채팅 링크를 입력해주세요"}
                    value={data.chatLink}
                    onChange={(e) => setData({ ...data, chatLink: e.target.value })}
                  />
                </DetailPageForm>
                <DetailPageForm>
                  <DetailFormTitle>상품 내용*</DetailFormTitle>
                  <MainInput
                    type={"text"}
                    placeholder={"상품 내용을 입력해주세요"}
                    value={data.content}
                    onChange={(e) => setData({ ...data, content: e.target.value })}
                  />
                </DetailPageForm>
                <DetailPageForm style={{ alignItems: "flex-start" }}>
                  <DetailFormTitle>
                    상품 이미지*
                    <br />
                    <span
                      style={{
                        fontSize: "12px",
                        fontWeight: "400",
                        lineHeight: "24.5px",
                        color: "#ADB4BA",
                      }}
                    >
                      *권장 사이즈 120 X 120
                    </span>
                  </DetailFormTitle>
                  <div style={{ display: "flex", gap: "5px", flexWrap: "wrap", width: "744px" }}>
                    <ImgInput
                      id={"FILE"}
                      accept={"image/*"}
                      onChange={(e) => {
                        inputFiles(e);
                      }}
                    />
                    {data.images?.map((item, index) => {
                      return (
                        <ImgPreview
                          key={index}
                          fileUrl={item.fileUrl}
                          onClick={() => {
                            deleteFiles(index);
                          }}
                        />
                      );
                    })}
                  </div>
                </DetailPageForm>
                <DetailPageForm>
                  <DetailFormTitle>상품 상태*</DetailFormTitle>
                  <div style={{ display: "flex", gap: "19px" }}>
                    <Radio
                      id={"ON_SALE"}
                      valueText={"판매중"}
                      checked={data.merchStatus === "ON_SALE"}
                      onChange={() => {
                        setData({ ...data, merchStatus: "ON_SALE" });
                      }}
                    />
                    <Radio
                      id={"SOLD_OUT"}
                      valueText={"판매완료"}
                      checked={data.merchStatus === "SOLD_OUT"}
                      onChange={() => {
                        setData({ ...data, merchStatus: "SOLD_OUT" });
                      }}
                    />
                    <Radio
                      id={"HIDDEN"}
                      valueText={"숨김"}
                      checked={data.merchStatus === "HIDDEN"}
                      onChange={() => {
                        setData({ ...data, merchStatus: "HIDDEN" });
                      }}
                    />
                  </div>
                </DetailPageForm>
              </div>

              <SaveBtn
                text={"저장하기"}
                style={{ bottom: "40px", right: "40px" }}
                onClick={() => {
                  setSaveModalOpen(true);
                }}
              />
            </DetailPage>
          </>
        </DetailPageLayout>
      </MainLayout>
      {saveModalOpen ? (
        <Modal
          title={modalMsg.update?.title}
          text={modalMsg.update?.content}
          setModalOpen={setSaveModalOpen}
          onClick={saveFunc}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default MarketRegisterDetailMerchEdit;
