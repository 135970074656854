import api from "./api.js";

export default class LeaveApi {
  static async GetList(params) {
    return await api.get(`admin/resigned`, params);
  }
  static async GetById(leaveId) {
    return await api.get(`admin/resigned_member/${leaveId}`);
  }
  static async DeleteById(leaveId) {
    return await api.del(`admin/resigned/${leaveId}`);
  }
}
