import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import Header from "../../component/organisms/header";
import MainLayout from "../../component/atoms/layout/mainLayout";
import Sidebar from "../../component/organisms/sidebar";
import DetailTop from "../../component/atoms/layout/detail/detailTop";
import DetailPage from "../../component/atoms/layout/detail/detailPage";
import DetailPageTitle from "../../component/atoms/text/detail/detailPageTitle";
import DetailPageLayout from "../../component/atoms/layout/detail/detailPageLayout";
import GoBack from "../../component/molecules/goBack";
import EditBtn from "../../component/atoms/button/editBtn";
import DetailPageForm from "../../component/atoms/layout/detail/detailPageForm";
import DetailFormTitle from "../../component/atoms/text/detail/detailFormTitle";
import DetailFormText from "../../component/atoms/text/detail/detailFormText";
import { toast } from "react-toastify";
import { toastMsg } from "../../util/toastMsg";
import { errorHandler } from "../../util/error-handler";
import { route } from "../../router/route";
import ManagerApi from "../../api/manager.api";

function ManagerDetail(props) {
  const navigate = useNavigate();

  let { id } = useParams();

  // init
  const getData = async () => {
    try {
      const response = await ManagerApi.GetById(id);
      setData({
        ...response.data.data,
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const [data, setData] = useState({
    name: "",
    email: "",
    managerAuthority: "",
  });

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <GoBack />
            {sessionStorage.getItem("managerAuthority") === "ROOT" ||
            Number(sessionStorage.getItem("id")) === Number(id) ? (
              <EditBtn
                onClick={() => {
                  if (sessionStorage.getItem("managerAuthority") === "ROOT") {
                    navigate(route.manager_edit_root + `/${id}`);
                  } else {
                    navigate(route.manager_edit_admin + `/${id}`);
                  }
                }}
              />
            ) : null}
          </DetailTop>
          <DetailPage style={{ height: "250px" }}>
            <DetailPageTitle>상세보기</DetailPageTitle>
            <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
              <DetailPageForm>
                <DetailFormTitle>이름</DetailFormTitle>
                <DetailFormText>{data.name}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>이메일</DetailFormTitle>
                <DetailFormText>{data.email}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>관리자 유형</DetailFormTitle>
                <DetailFormText>{data.managerAuthority}</DetailFormText>
              </DetailPageForm>
            </div>
          </DetailPage>
        </DetailPageLayout>
      </MainLayout>
    </>
  );
}

export default ManagerDetail;
