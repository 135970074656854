import api from "./api.js";

export default class CountApi {
  static async GetMemberCount() {
    return await api.get(`admin/member/count`);
  }
  static async GetVisitorCount(data) {
    return await api.get(`admin/visitors`, data);
  }

  static async GetPurchaseRatio(data) {
    return await api.get(`admin/purchase/ratio`, data);
  }
  static async GetStoreRatio(data) {
    return await api.get(`admin/store/ratio`, data);
  }
  static async GetMemberAgeRatio(data) {
    return await api.get(`admin/member/age/ratio`, data);
  }

  static async GetMarketLoginCountRanks(params) {
    return await api.get(`admin/markets/login`, params);
  }
  static async GetMarketSaleCountRanks(params) {
    return await api.get(`admin/markets/sale`, params);
  }
}
