import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import Header from "../../../component/organisms/header";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import Sidebar from "../../../component/organisms/sidebar";
import TableLayout from "../../../component/atoms/layout/table/tableLayout";
import TableTitle from "../../../component/atoms/text/table/tableTitle";
import TableTop from "../../../component/atoms/layout/table/tableTop";
import TableSearchBtn from "../../../component/atoms/layout/table/tableSearchBtn";
import TableCount from "../../../component/molecules/tableCount";
import SearchBar from "../../../component/atoms/input/searchBar";
import Table from "../../../component/atoms/layout/table/table";
import Tr from "../../../component/atoms/layout/table/tr";
import Checkbox from "../../../component/atoms/input/checkbox";
import Th from "../../../component/atoms/layout/table/th";
import Td from "../../../component/atoms/layout/table/td";
import Pagenation from "../../../component/molecules/pagenation";
import { toast } from "react-toastify";
import { toastMsg } from "../../../util/toastMsg";
import moment from "moment";
import Modal from "../../../component/templates/Modal";
import { errorHandler } from "../../../util/error-handler";
import { route } from "../../../router/route";
import TableFilterManager from "../../../component/atoms/layout/table/tableFilterManager";
import ThText from "../../../component/atoms/text/table/thText";
import TdText from "../../../component/atoms/text/table/tdText";
import DelBtn from "../../../component/atoms/button/delBtn";
import DetailBtn from "../../../component/atoms/button/detailBtn";
import PretendardText from "../../../component/atoms/text/pretendardText";
import { modalMsg } from "../../../util/modalMsg";
import ExcelBtn from "../../../component/atoms/button/excelBtn";
import TableDelBtn from "../../../component/atoms/button/tableDelBtn";
import MemberApi from "../../../api/member.api";
import { saveAs } from "file-saver";
import ExcelApi from "../../../api/excel.api";

function Influencer(props) {
  const navigate = useNavigate();
  const location = useLocation();

  const { page } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [pagination, setPagination] = useState({
    size: 10, // 한 페이지에 표시될 게시글 수
    rangeSize: 5, // 하단 페이지 사이즈

    totalCount: 0,
    totalPages: 0,
    first: false,
    last: false,
  });
  const [list, setList] = useState([]);
  const [keyword, setKeyword] = useState("");

  const getList = async () => {
    try {
      const data = {
        keyword: searchParams.get("keyword"),
        page: page - 1,
        size: pagination.size,
        memberType: "INFLUENCER",
      };
      let list = (await MemberApi.GetList(data)).data.data;
      setList(
        list.content.map((item) => {
          return {
            ...item,
            checked: false,
          };
        })
      );
      setPagination({
        ...pagination,
        totalCount: list.totalElements,
        totalPages: list.totalPages,
        first: list.first,
        last: list.last,
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    setKeyword(searchParams.get("keyword") ? searchParams.get("keyword") : "");
    getList();
  }, [location]);

  // ----- 활성/비활성
  const [filterOpen, setFilterOpen] = useState(-1);
  const [activeId, setActiveId] = useState(null);
  const [activeModalOpen, setActiveModalOpen] = useState(false);
  const [deactiveModalOpen, setDeactiveModalOpen] = useState(false);

  const activeFunc = async (activation) => {
    try {
      if (activation) {
        await MemberApi.Activation(activeId);
      } else {
        await MemberApi.DeActivation(activeId);
      }
      toast(toastMsg.create);
      setFilterOpen(-1);
      setActiveModalOpen(false);
      setDeactiveModalOpen(false);
      getList();
    } catch (error) {
      errorHandler(error);
    }
  };

  // ----- 삭제
  const [delId, setDelId] = useState(0);
  const [delModalOpen, setDelModalOpen] = useState(false);

  const deleteOneFunc = async () => {
    try {
      await MemberApi.DeleteById(delId);
      toast(toastMsg.delete);
      setDelModalOpen(false);
      setDelId(0);
      getList();
    } catch (error) {
      errorHandler(error);
    }
  };

  // ----- 승인
  const [approveModalOpen, setApproveModalOpen] = useState(false);
  const [approveCancelModalOpen, setApproveCancelModalOpen] = useState(false);
  const [approveId, setApproveId] = useState(0);

  const approveFunc = async (approve) => {
    if (approve) {
      await MemberApi.Approve(approveId);
    } else {
      await MemberApi.ApproveCancel(approveId);
    }

    toast(approve ? "승인되었습니다." : "승인취소되었습니다.");
    setApproveId(0);
    setApproveModalOpen(false);
    setApproveCancelModalOpen(false);
    getList();
  };

  //------------엑셀 생성
  const downloadExcel = async (excelData) => {
    const Excel = require("exceljs");
    const workbook = new Excel.Workbook();
    workbook.addWorksheet("Sheet One");
    const sheetOne = workbook.getWorksheet("Sheet One");

    sheetOne.columns = [
      { header: "이메일", key: "email", width: 40 },
      { header: "이름", key: "name", width: 40 },
      { header: "가입일", key: "createdAt", width: 40 },
      { header: "계정 상태", key: "isActive", width: 40 },
    ];

    excelData.map((item) => {
      // 행 추가
      sheetOne.addRow(item);
    });
    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), "인플루언서 관리.xlsx");
  };

  const getExcelAll = async () => {
    try {
      const excelArr = [];
      const response = (await ExcelApi.GetInfluencerAll()).data.data;

      response.map((item) => {
        const activeTitle = item.isActive ? "활성" : "비활성";
        const createDate = moment(item.createdAt).format("YYYY/MM/DD");
        excelArr.unshift({ ...item, isActive: activeTitle, createdAt: createDate });
      });

      downloadExcel(excelArr);
    } catch (error) {
      errorHandler(error);
    }
  };

  const getExcelChecked = async () => {
    const filter = list.filter((item) => item.checked);
    if (filter.length <= 0) {
      return toast("항목을 선택해주세요");
    }
    let queryString = filter.map((idList) => `idList=${idList.id}`).join("&");

    try {
      const excelArr = [];
      const response = (await ExcelApi.GetMemberSelected(queryString)).data.data;

      response.map((item) => {
        const activeTitle = item.isActive ? "활성" : "비활성";
        const createDate = moment(item.createdAt).format("YYYY/MM/DD");
        excelArr.unshift({ ...item, isActive: activeTitle, createdAt: createDate });
      });

      downloadExcel(excelArr);
      // getList();
    } catch (error) {
      errorHandler(error);
    }
  };
  //------------액셀 부분 끝

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <TableLayout>
          <TableTitle>인플루언서 관리</TableTitle>
          <TableTop>
            <TableCount text={"이용자"} count={pagination.totalCount} />
            <TableSearchBtn>
              <SearchBar
                value={keyword}
                onChange={(e) => {
                  setKeyword(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    navigate(`${route.member_influencer}/1?keyword=${keyword}`, { replace: true });
                  }
                }}
                onClick={() => {
                  navigate(`${route.member_influencer}/1?keyword=${keyword}`, { replace: true });
                }}
              />
              <ExcelBtn onSelectClick={getExcelChecked} onAllClick={getExcelAll} />
            </TableSearchBtn>
          </TableTop>
          <Table>
            <colgroup>
              <col width="90px" />
              <col width="241px" />
              <col width="156px" />
              <col width="183px" />
              <col width="124px" />
              <col width="172px" />
              <col width="78px" />
            </colgroup>
            <thead>
              <Tr style={{ height: "41px", background: "#f2f4f5" }}>
                <Th style={{ paddingLeft: "20px" }}>
                  <Checkbox
                    checked={list.filter((item) => item.checked).length === list.length}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setList(
                          list.map((item) => {
                            return {
                              ...item,
                              checked: true,
                            };
                          })
                        );
                      } else {
                        setList(
                          list.map((item) => {
                            return {
                              ...item,
                              checked: false,
                            };
                          })
                        );
                      }
                    }}
                  />
                </Th>
                <Th>
                  <ThText>이메일</ThText>
                </Th>
                <Th>
                  <ThText>이름</ThText>
                </Th>
                <Th>
                  <ThText>가입일</ThText>
                </Th>
                <Th>
                  <ThText>계정 상태</ThText>
                </Th>
                <Th>
                  <ThText style={{ textAlign: "center", paddingRight: "58px" }}>
                    계정 삭제/승인
                  </ThText>
                </Th>
                <Th>
                  <ThText style={{ textAlign: "center", paddingRight: "20px" }}>상세보기</ThText>
                </Th>
              </Tr>
            </thead>
            <tbody>
              {list.map((item, index) => {
                return (
                  <Tr style={{ height: "57px", background: "#fff" }} key={index}>
                    <Td style={{ paddingLeft: "20px" }}>
                      <Checkbox
                        checked={item.checked}
                        onChange={() => {
                          item.checked = !item.checked;
                          setList([...list]);
                        }}
                      />
                    </Td>
                    <Td>
                      <TdText
                        style={{
                          width: "181px",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          wordBreak: "break-all",
                        }}
                      >
                        {item.email}
                      </TdText>
                    </Td>
                    <Td>
                      <TdText>{item.name}</TdText>
                    </Td>
                    <Td>
                      <TdText>{moment(item.createdAt).format("YYYY/MM/DD")}</TdText>
                    </Td>
                    <Td>
                      <TableFilterManager
                        onClick={() => {
                          filterOpen === index ? setFilterOpen(-1) : setFilterOpen(index);
                        }}
                        isOpen={filterOpen === index}
                        selectedValue={item.isActive}
                        isApproved={item.isApproved}
                        list={[
                          {
                            value: true,
                            title: "활성",
                            onClick: () => {
                              setActiveId(item.id);
                              setActiveModalOpen(true);
                            },
                          },
                          {
                            value: false,
                            title: "비활성",
                            onClick: () => {
                              setActiveId(item.id);
                              setDeactiveModalOpen(true);
                            },
                          },
                        ]}
                      />
                    </Td>
                    <Td>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "5px",
                          paddingRight: "58px",
                        }}
                      >
                        <TableDelBtn onClick={() => setDelModalOpen(true)} />
                        {item.influencerApprove ? (
                          <PretendardText
                            style={{
                              fontSize: "14px",
                              fontWeight: "600",
                              lineHeight: "22.75px",
                              color: "#262C31",
                              border: "1px solid #DFE1E4",
                              borderRadius: "4px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              padding: "3px 8px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setApproveId(item.id);
                              setApproveCancelModalOpen(true);
                            }}
                          >
                            승인취소
                          </PretendardText>
                        ) : (
                          <PretendardText
                            style={{
                              fontSize: "14px",
                              fontWeight: "600",
                              lineHeight: "22.75px",
                              color: "#262C31",
                              border: "1px solid #DFE1E4",
                              borderRadius: "4px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              padding: "3px 8px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setApproveId(item.id);
                              setApproveModalOpen(true);
                            }}
                          >
                            승인하기
                          </PretendardText>
                        )}
                      </div>
                    </Td>
                    <Td>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          paddingRight: "20px",
                        }}
                      >
                        <DetailBtn
                          onClick={() => {
                            navigate(route.member_influencer_detail + `/${item.id}`);
                          }}
                        />
                      </div>
                    </Td>
                  </Tr>
                );
              })}
            </tbody>
          </Table>
          <Pagenation
            route={route.member_influencer}
            queryString={`keyword=${keyword}`}
            currentPage={page}
            totalPages={pagination.totalPages}
            rangeSize={pagination.rangeSize}
            first={pagination.first}
            last={pagination.last}
          />
        </TableLayout>

        {deactiveModalOpen ? (
          <Modal
            title={modalMsg.deactivate?.title}
            setModalOpen={setDeactiveModalOpen}
            onClick={() => {
              activeFunc(false);
            }}
          />
        ) : (
          ""
        )}
        {activeModalOpen ? (
          <Modal
            title={modalMsg.activate?.title}
            setModalOpen={setActiveModalOpen}
            onClick={() => {
              activeFunc(true);
            }}
          />
        ) : (
          ""
        )}
        {approveModalOpen ? (
          <Modal
            title={"승인하시겠습니까?"}
            text={
              <>
                인플루언서의 내용을 확인하셨나요?
                <br />
                승인이 완료되면 마켓이 생성됩니다.
              </>
            }
            setModalOpen={setApproveModalOpen}
            onClick={() => {
              approveFunc(true);
            }}
          />
        ) : (
          ""
        )}
        {approveCancelModalOpen ? (
          <Modal
            title={"승인을 취소하시겠습니까?"}
            text={
              <>
                승인 취소 시 생성된 마켓은 비활성화됩니다.
                <br />
                그래도 승인을 취소하시겠습니까?
              </>
            }
            setModalOpen={setApproveCancelModalOpen}
            onClick={() => {
              approveFunc(false);
            }}
          />
        ) : (
          ""
        )}
        {delModalOpen ? (
          <Modal
            title={modalMsg.delete?.title}
            text={modalMsg.delete?.content}
            btnTitle={"삭제하기"}
            setModalOpen={setDelModalOpen}
            onClick={() => {
              deleteOneFunc();
            }}
          />
        ) : (
          ""
        )}
      </MainLayout>
    </>
  );
}

export default Influencer;
