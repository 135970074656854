import React from "react";
import styled from "styled-components";

const Td = (props) => {
  return <TdWrapper {...props} />;
};

export default Td;

const TdWrapper = styled.td.attrs((props) => {})`
  text-align: left;
  height: 57px;
  position: relative;
`;
