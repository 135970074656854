import React from "react";
import styled from "styled-components";

const Th = (props) => {
  return <ThWrapper {...props} />;
};

export default Th;

const ThWrapper = styled.th.attrs((props) => {})`
  text-align: left;
`;
