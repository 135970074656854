import CommonApi from "../api/common.api";
import { errorHandler } from "./error-handler";

export async function fileDownload(serverFileName, originFileName) {
  try {
    const data = {
      serverFileName: serverFileName,
      originalFileName: originFileName,
    };
    const response = await CommonApi.FileDownload(data);
    download(response, originFileName);
  } catch (error) {
    errorHandler(error);
  }
}

function download(response, filename) {
  const url = window.URL.createObjectURL(
    new Blob([response.data], {
      type: response.headers["content-type"],
    })
  );
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${filename}`);
  link.style.cssText = "display:none";
  document.body.appendChild(link);
  link.click();
  link.remove();
}
