import React from "react";
import styled from "styled-components";

function TableLayout(props) {
  return (
    <>
      <TableLayoutWrapper
        style={{
          ...props.style,
        }}
      >
        {props.children}
      </TableLayoutWrapper>
    </>
  );
}

export default TableLayout;

const TableLayoutWrapper = styled.div.attrs((props) => {})`
  width: 1044px;
  height: auto;
  margin-top: 142px;
  margin-bottom: 132px;
`;
