import React, { useEffect, useState } from "react";
import Header from "../../../component/organisms/header";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import Sidebar from "../../../component/organisms/sidebar";
import DetailTop from "../../../component/atoms/layout/detail/detailTop";
import DetailPage from "../../../component/atoms/layout/detail/detailPage";
import DetailPageTitle from "../../../component/atoms/text/detail/detailPageTitle";
import DetailPageLayout from "../../../component/atoms/layout/detail/detailPageLayout";
import GoBack from "../../../component/molecules/goBack";
import DetailPageForm from "../../../component/atoms/layout/detail/detailPageForm";
import DetailFormTitle from "../../../component/atoms/text/detail/detailFormTitle";
import MainInput from "../../../component/atoms/input/mainInput";
import SaveBtn from "../../../component/atoms/button/saveBtn";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { toastMsg } from "../../../util/toastMsg";
import { errorHandler } from "../../../util/error-handler";
import { nullCheck } from "../../../util/check";
import Modal from "../../../component/templates/Modal";
import { modalMsg } from "../../../util/modalMsg";
import TextArea from "../../../component/atoms/input/textarea";
import ImgPreview from "../../../component/molecules/imgPreview";
import ImgInput from "../../../component/atoms/input/imgInput";
import PretendardText from "../../../component/atoms/text/pretendardText";
import Checkbox from "../../../component/atoms/input/checkbox";
import DetailFormText from "../../../component/atoms/text/detail/detailFormText";
import styled from "styled-components";
import Radio from "../../../component/atoms/input/radio";
import CategoryApi from "../../../api/category.api";
import ColumnApi from "../../../api/column.api";
import TextFormEditor from "../../../component/atoms/input/textFormEditor";
import { useInView } from "react-intersection-observer";

function ColumnEdit(props) {
  const navigate = useNavigate();
  let { id } = useParams();

  const [dataForm, setDataForm] = useState({
    title: "",
    file: "",
    instagramLink: "",
    isActive: "",
    deleteFile: false,

    fileUrl: "",
    originFileName: "",
    serverFileName: "",

    recommendId1: "",
    recommendTitle1: "",
    recommendId2: "",
    recommendTitle2: "",
  });

  const [content, setContent] = useState("");

  // init
  const getData = async () => {
    try {
      const response = await ColumnApi.GetById(id);
      setDataForm({
        ...response.data.data,
        deleteFile: false,
        recommendTitle1: response.data.data.columnBoard1?.title,
        recommendId1: response.data.data.columnBoard1?.id,
        recommendTitle2: response.data.data.columnBoard2?.title,
        recommendId2: response.data.data.columnBoard2?.id,
      });
      setContent(response.data.data.content);
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  //이미지 추가, 삭제
  const inputFile = (e) => {
    const file = e.target.files[0];

    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = (value) => {
      setDataForm({
        ...dataForm,
        file: file,
        fileUrl: value.target.result,
        deleteFile: true,
      });
    };
    e.target.value = "";
  };

  const deleteFile = () => {
    setDataForm({ ...dataForm, file: "", fileUrl: "", deleteFile: true });
  };

  //저장하기
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const saveFunc = async () => {
    try {
      if (nullCheck(dataForm.title) || nullCheck(dataForm.fileUrl) || nullCheck(dataForm.content) || nullCheck(dataForm.instagramLink)) {
        setSaveModalOpen(false);
        return toast("필수사항을 모두 입력해주세요.");
      }

      const formData = new FormData();
      formData.append("title", dataForm.title);
      formData.append("content", content);
      formData.append("instagramLink", dataForm.instagramLink);
      formData.append("deleteFile", dataForm.deleteFile);

      if (dataForm.file) {
        formData.append("file", dataForm.file);
      }

      formData.append("isActive", dataForm.isActive);
      if (dataForm.recommendId1) {
        formData.append("recommendId1", dataForm.recommendId1);
      }
      if (dataForm.recommendId2) {
        formData.append("recommendId2", dataForm.recommendId2);
      }

      await ColumnApi.Update(id, formData);
      setSaveModalOpen(false);
      navigate(-1);
      toast(toastMsg.create);
    } catch (error) {
      errorHandler(error);
    }
  };

  // 에디터
  const [position, setPosition] = useState(0);
  const [isBlurEditor, setIsBlurEditor] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  const [columnList, setColumnList] = useState([]);
  const [pagination, setPagination] = useState({
    size: 5, // 한 페이지에 표시될 게시글 수
    rangeSize: 5, // 하단 페이지 사이즈

    totalCount: 0,
    totalPages: 0,
    first: false,
    last: false,
  });
  const [page, setPage] = useState(1);

  const getList = async (page) => {
    try {
      setIsProgress(true);
      const data = {
        page: page - 1,
        size: pagination.size,
      };
      let list = (await ColumnApi.GetList(data)).data.data;
      const newList = list.content.map((item) => {
        return {
          ...item,
          checked: false,
        };
      });
      if (list.first) {
        setColumnList([...newList]);
      } else {
        setColumnList([...columnList, ...newList]);
      }

      setPagination({
        ...pagination,
        totalCount: list.totalElements,
        totalPages: list.totalPages,
        first: list.first,
        last: list.last,
      });
      setIsProgress(false);
    } catch (error) {
      errorHandler(error);
    }
  };

  const [ref, inView] = useInView();
  const [isProgress, setIsProgress] = useState(false);

  useEffect(() => {
    if (inView && !isProgress && !pagination.last) {
      setPage(page + 1);
      getList(page + 1);
    }
  }, [inView, isProgress]);

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <GoBack />
          </DetailTop>
          <DetailPage style={{ minHeight: "375px" }}>
            <DetailPageTitle>수정하기</DetailPageTitle>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                marginBottom: "79px",
              }}
            >
              <DetailPageForm>
                <DetailFormTitle>제목*</DetailFormTitle>
                <MainInput value={dataForm.title} type={"text"} placeholder={"제목을 입력해주세요"} onChange={(e) => setDataForm({ ...dataForm, title: e.target.value })} />
              </DetailPageForm>
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>썸네일*</DetailFormTitle>
                {dataForm.fileUrl ? (
                  <ImgPreview
                    fileUrl={dataForm.fileUrl}
                    onClick={() => {
                      deleteFile();
                    }}
                  />
                ) : (
                  <ImgInput
                    id={"file"}
                    accept={"image/*"}
                    onChange={(e) => {
                      inputFile(e);
                    }}
                  />
                )}
              </DetailPageForm>
              <DetailPageForm style={{ alignItems: "start" }}>
                <DetailFormTitle>내용*</DetailFormTitle>
                <TextFormEditor
                  placeholder={"상세설명을 입력하세요"}
                  value={content}
                  onChange={(contents) => {
                    setContent(contents);
                  }}
                  editorHeight={280}
                  style={{
                    width: "730px",
                    marginBottom: "12px",
                  }}
                  position={position}
                  isBlur={isBlurEditor}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                />
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>인스타그램 링크*</DetailFormTitle>
                <MainInput value={dataForm.instagramLink} type={"text"} placeholder={"링크를 입력해 주세요."} onChange={(e) => setDataForm({ ...dataForm, instagramLink: e.target.value })} />
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>추천 콘텐츠1</DetailFormTitle>
                <div style={{ position: "relative" }}>
                  <SelectBoxWrapper>
                    <DetailFormText style={{ color: `${dataForm.recommendId1 ? "#262c31" : "#808991"}` }}>{dataForm.recommendTitle1 || "칼럼을 선택해 주세요."}</DetailFormText>
                    <img
                      alt=""
                      src="/assets/admin/icons/selectbox-ico.svg"
                      onClick={() => {
                        setColumnList([]);
                        if (isOpen === 1) {
                          setIsOpen(false);
                        } else {
                          setPage(1);
                          getList(1);
                          setIsOpen(1);
                        }
                      }}
                      style={{ cursor: "pointer" }}
                    />
                  </SelectBoxWrapper>
                  {isOpen === 1 ? (
                    <SubMenuWrapper>
                      {columnList.map((item, index) => {
                        return (
                          <SubText
                            key={index}
                            onClick={() => {
                              setDataForm({ ...dataForm, recommendId1: item.id, recommendTitle1: item.title });
                              setIsOpen(false);
                            }}
                          >
                            {item.title}
                          </SubText>
                        );
                      })}
                      <div ref={ref}></div>
                    </SubMenuWrapper>
                  ) : (
                    ""
                  )}
                </div>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>추천 콘텐츠2</DetailFormTitle>
                <div style={{ position: "relative" }}>
                  <SelectBoxWrapper>
                    <DetailFormText style={{ color: `${dataForm.recommendId2 ? "#262c31" : "#808991"}` }}>{dataForm.recommendTitle2 || "칼럼을 선택해 주세요."}</DetailFormText>
                    <img
                      alt=""
                      src="/assets/admin/icons/selectbox-ico.svg"
                      onClick={() => {
                        setColumnList([]);
                        if (isOpen === 2) {
                          setIsOpen(false);
                        } else {
                          setPage(1);
                          getList(1);
                          setIsOpen(2);
                        }
                      }}
                      style={{ cursor: "pointer" }}
                    />
                  </SelectBoxWrapper>
                  {isOpen === 2 ? (
                    <SubMenuWrapper>
                      {columnList.map((item, index) => {
                        return (
                          <SubText
                            key={index}
                            onClick={() => {
                              setDataForm({ ...dataForm, recommendId2: item.id, recommendTitle2: item.title });
                              setIsOpen(false);
                            }}
                          >
                            {item.title}
                          </SubText>
                        );
                      })}
                      <div ref={ref}></div>
                    </SubMenuWrapper>
                  ) : (
                    ""
                  )}
                </div>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>상태*</DetailFormTitle>
                <div style={{ display: "flex", gap: "19px" }}>
                  <Radio
                    id={"POST"}
                    valueText={"게시"}
                    checked={dataForm.isActive}
                    onChange={() => {
                      setDataForm({ ...dataForm, isActive: true });
                    }}
                  />
                  <Radio
                    id={"HIDE"}
                    valueText={"숨김"}
                    checked={!dataForm.isActive}
                    onChange={() => {
                      setDataForm({ ...dataForm, isActive: false });
                    }}
                  />
                </div>
              </DetailPageForm>
            </div>
            <SaveBtn
              text={"저장하기"}
              style={{ bottom: "40px", right: "40px" }}
              onClick={() => {
                setSaveModalOpen(true);
              }}
            />
          </DetailPage>
        </DetailPageLayout>
      </MainLayout>
      {saveModalOpen ? <Modal title={modalMsg.update?.title} text={modalMsg.update?.content} setModalOpen={setSaveModalOpen} onClick={saveFunc} /> : ""}
    </>
  );
}

export default ColumnEdit;

const SelectBoxWrapper = styled.div.attrs((props) => {})`
  width: 338px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px 15px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  background: #fff;
`;

const SubMenuWrapper = styled.div.attrs((props) => {})`
  width: 338px;
  height: 250px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  background: #fff;
  box-shadow: 0px 5px 15px 0px rgba(217, 217, 217, 0.7);
  position: absolute;
  top: 56px;
  padding: 10px;
  overflow-y: auto;
  z-index: 5;

  &::-webkit-scrollbar {
    width: 17px;
  }
  &::-webkit-scrollbar-thumb {
    background: #b0b8c1; /* 스크롤바의 색상 */
    border-radius: 9px;
    background-clip: padding-box;
    border: 7px solid transparent;
  }

  &::-webkit-scrollbar-track {
    background: transparent; /*스크롤바 뒷 배경 색상*/
  }
`;

const SubText = styled(PretendardText).attrs((props) => {})`
  color: #808991;
  font-size: 15px;
  font-weight: 500;
  line-height: 21.5px;
  width: 100%;
  height: 42px;
  padding: 10px;
  cursor: pointer;
`;
