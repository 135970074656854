import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import Header from "../../../component/organisms/header";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import Sidebar from "../../../component/organisms/sidebar";
import DetailTop from "../../../component/atoms/layout/detail/detailTop";
import DetailPage from "../../../component/atoms/layout/detail/detailPage";
import DetailPageTitle from "../../../component/atoms/text/detail/detailPageTitle";
import DetailPageLayout from "../../../component/atoms/layout/detail/detailPageLayout";
import GoBack from "../../../component/molecules/goBack";
import EditBtn from "../../../component/atoms/button/editBtn";
import DetailPageForm from "../../../component/atoms/layout/detail/detailPageForm";
import DetailFormTitle from "../../../component/atoms/text/detail/detailFormTitle";
import DetailFormText from "../../../component/atoms/text/detail/detailFormText";
import { toast } from "react-toastify";
import { toastMsg } from "../../../util/toastMsg";
import { errorHandler } from "../../../util/error-handler";
import { route } from "../../../router/route";
import DelBtn from "../../../component/atoms/button/delBtn";
import Modal from "../../../component/templates/Modal";
import { modalMsg } from "../../../util/modalMsg";
import moment from "moment";
import FileImgPreview from "../../../component/molecules/fileImgPreview";
import TransactionApi from "../../../api/transaction.api";

function SettleDetail(props) {
  const navigate = useNavigate();
  let { id } = useParams();

  const [data, setData] = useState({
    purchaseInfo: {
      createdAt: "",
      updatedAt: "",
      id: "",
      merchInfo: "",
      purchaseStatus: "",
      purchaseStatusTitle: "",
      accountOwner: "",
      bank: "",
      account: "",
      merchNum: "",
      buyerNum: "",
      marketNum: "",
      marketName: "",
      merchName: "",
      amount: "",
      sellerNum: "",
      calculated: "",
      calculateId: "",
      deliveryFee: "",
      price: "",
      images: "",
    },
    accountOwner: "",
    bank: "",
    account: "",
    isCompleted: "",
  });

  // init
  const getData = async () => {
    try {
      const response = await TransactionApi.GetById(id);
      setData({
        ...response.data.data,
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  // ----- 삭제
  const [delModalOpen, setDelModalOpen] = useState(false);

  const deleteFunc = async () => {
    try {
      await TransactionApi.DeleteById(id);
      toast(toastMsg.delete);
      setDelModalOpen(false);
      navigate(-1);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <GoBack />
            <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
              <DelBtn onClick={() => setDelModalOpen(true)} />
            </div>
          </DetailTop>
          <DetailPage style={{ minHeight: "340px", marginBottom: "20px" }}>
            <DetailPageTitle>판매정보</DetailPageTitle>
            <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
              <DetailPageForm>
                <DetailFormTitle>상품명</DetailFormTitle>
                <DetailFormText>{data.purchaseInfo?.merchName}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>상품금액</DetailFormTitle>
                <DetailFormText>{Number(data.purchaseInfo?.price).toLocaleString("ko-KR")}원</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>배송비</DetailFormTitle>
                <DetailFormText>{Number(data.purchaseInfo?.deliveryFee).toLocaleString("ko-KR")}원</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>총 판매금액</DetailFormTitle>
                <DetailFormText>{Number(data.purchaseInfo?.amount).toLocaleString("ko-KR")}원</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>거래상태</DetailFormTitle>
                <DetailFormText>{data.purchaseInfo?.purchaseStatusTitle}</DetailFormText>
              </DetailPageForm>
            </div>
          </DetailPage>
          <DetailPage style={{ minHeight: "295px" }}>
            <DetailPageTitle>정산 정보</DetailPageTitle>
            <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
              <DetailPageForm>
                <DetailFormTitle>은행명</DetailFormTitle>
                <DetailFormText>{data.bank}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>계좌번호</DetailFormTitle>
                <DetailFormText>{data.account}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>예금주명</DetailFormTitle>
                <DetailFormText>{data.accountOwner}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>정산 상태</DetailFormTitle>
                <DetailFormText>{data.isCompleted ? "정산완료" : "정산요청"}</DetailFormText>
              </DetailPageForm>
            </div>
          </DetailPage>
        </DetailPageLayout>
      </MainLayout>
      {delModalOpen ? (
        <Modal
          title={modalMsg.delete?.title}
          text={modalMsg.delete?.content}
          btnTitle={"삭제하기"}
          setModalOpen={setDelModalOpen}
          onClick={() => {
            deleteFunc();
          }}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default SettleDetail;
