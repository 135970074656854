const prefix = "/admin";

export const route = {
  default: prefix,
  login: prefix + "/login",
  join: prefix + "/join",
  join_complete: prefix + "/join/complete",

  // 홈
  home: prefix + "/home",

  // 사용자 관리
  member: prefix + "/member",
  member_member: prefix + "/member/member",
  member_member_detail: prefix + "/member/member/detail",
  member_member_edit: prefix + "/member/member/edit",
  member_influencer: prefix + "/member/influencer",
  member_influencer_detail: prefix + "/member/influencer/detail",
  member_influencer_edit: prefix + "/member/influencer/edit",
  member_leave: prefix + "/member/leave",

  // 마켓 관리
  market: prefix + "/market",
  market_register: prefix + "/market/register",
  market_register_detail_basic: prefix + "/market/register/detail/basic",
  market_register_detail_merch: prefix + "/market/register/detail/merch",
  market_register_detail_review: prefix + "/market/register/detail/review",
  market_register_detail_merch_detail: prefix + "/market/register/detail/merch/detail",
  market_register_detail_review_detail: prefix + "/market/register/detail/review/detail",
  market_register_edit_basic: prefix + "/market/register/edit/basic",
  market_register_detail_merch_edit: prefix + "/market/register/detail/merch/edit",

  market_delete: prefix + "/market/delete",
  market_delete_detail_basic: prefix + "/market/delete/detail/basic",
  market_delete_detail_merch: prefix + "/market/delete/detail/merch",
  market_delete_detail_review: prefix + "/market/delete/detail/review",
  market_delete_detail_merch_detail: prefix + "/market/delete/detail/merch/detail",
  market_delete_detail_review_detail: prefix + "/market/delete/detail/review/detail",
  market_category: prefix + "/market/category",
  market_category_add: prefix + "/market/category/add",
  market_category_edit: prefix + "/market/category/edit",
  market_category_detail: prefix + "/market/category/detail",
  market_setting: prefix + "/market/setting",

  // 거래 관리
  transaction: prefix + "/transaction",
  transaction_purchase: prefix + "/transaction/purchase",
  transaction_purchase_detail: prefix + "/transaction/purchase/detail",
  transaction_settle: prefix + "/transaction/settle",
  transaction_settle_detail: prefix + "/transaction/settle/detail",
  transaction_refund: prefix + "/transaction/refund",
  transaction_refund_detail: prefix + "/transaction/refund/detail",

  // 기타관리
  etc: prefix + "/etc",
  etc_ads: prefix + "/etc/ads",
  etc_ads_detail: prefix + "/etc/ads/detail",
  etc_ads_add: prefix + "/etc/ads/add",
  etc_ads_edit: prefix + "/etc/ads/edit",

  etc_column: prefix + "/etc/column",
  etc_column_detail: prefix + "/etc/column/detail",
  etc_column_add: prefix + "/etc/column/add",
  etc_column_edit: prefix + "/etc/column/edit",

  etc_style: prefix + "/etc/style",
  etc_style_detail: prefix + "/etc/style/detail",
  etc_style_add: prefix + "/etc/style/add",
  etc_style_edit: prefix + "/etc/style/edit",

  // 서비스 관리
  service: prefix + "/service",
  service_alarm: prefix + "/service/alarm",
  service_alarm_detail: prefix + "/service/alarm/detail",
  service_alarm_add: prefix + "/service/alarm/add",
  service_ask: prefix + "/service/ask",
  service_report: prefix + "/service/report",
  service_notice: prefix + "/service/notice",
  service_notice_detail: prefix + "/service/notice/detail",
  service_notice_add: prefix + "/service/notice/add",
  service_faq: prefix + "/service/faq",
  service_faq_detail: prefix + "/service/faq/detail",
  service_faq_edit: prefix + "/service/faq/edit",
  service_faq_add: prefix + "/service/faq/add",
  service_popup: prefix + "/service/popup",
  service_popup_detail: prefix + "/service/popup/detail",
  service_popup_edit: prefix + "/service/popup/edit",
  service_popup_add: prefix + "/service/popup/add",
  service_terms: prefix + "/service/terms",
  service_terms_detail: prefix + "/service/terms/detail",
  service_terms_edit: prefix + "/service/terms/edit",
  service_terms_add: prefix + "/service/terms/add",
  service_openSource: prefix + "/service/opensoucre",
  service_openSource_detail: prefix + "/service/opensoucre/detail",
  service_openSource_edit: prefix + "/service/opensoucre/edit",
  service_basic: prefix + "/service/basic",
  service_basic_edit: prefix + "/service/basic/edit",

  // 관리자회원 관리
  manager: prefix + "/manager",
  manager_detail: prefix + "/manager/detail",
  manager_edit_admin: prefix + "/manager/edit/admin",
  manager_edit_root: prefix + "/manager/edit/root",
};
