import React, { useEffect, useState } from "react";
import Header from "../../component/organisms/header";
import MainLayout from "../../component/atoms/layout/mainLayout";
import Sidebar from "../../component/organisms/sidebar";
import DetailTop from "../../component/atoms/layout/detail/detailTop";
import DetailPage from "../../component/atoms/layout/detail/detailPage";
import DetailPageTitle from "../../component/atoms/text/detail/detailPageTitle";
import DetailPageLayout from "../../component/atoms/layout/detail/detailPageLayout";
import GoBack from "../../component/molecules/goBack";
import DetailPageForm from "../../component/atoms/layout/detail/detailPageForm";
import DetailFormTitle from "../../component/atoms/text/detail/detailFormTitle";
import MainInput from "../../component/atoms/input/mainInput";
import SaveBtn from "../../component/atoms/button/saveBtn";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { toastMsg } from "../../util/toastMsg";
import { errorHandler } from "../../util/error-handler";
import { nullCheck } from "../../util/check";
import Modal from "../../component/templates/Modal";
import { modalMsg } from "../../util/modalMsg";
import TextArea from "../../component/atoms/input/textarea";
import ImgPreview from "../../component/molecules/imgPreview";
import ImgInput from "../../component/atoms/input/imgInput";
import PretendardText from "../../component/atoms/text/pretendardText";
import Checkbox from "../../component/atoms/input/checkbox";
import DetailFormText from "../../component/atoms/text/detail/detailFormText";
import styled from "styled-components";
import Radio from "../../component/atoms/input/radio";
import AdsApi from "../../api/ads.api";
import CategoryApi from "../../api/category.api";

function AdsEdit(props) {
  const navigate = useNavigate();
  let { id } = useParams();

  const [data, setData] = useState({
    title: "",
    brand: "",
    brandExplanation: "",
    categoryIdList: [],
    file: "",
    link: "",
    isActive: "",
    deleteFile: false,

    fileUrl: "",
    originFileName: "",
    serverFileName: "",
  });

  // init
  const getData = async () => {
    try {
      const response = await AdsApi.GetById(id);
      setData({
        ...response.data.data,
        deleteFile: false,
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const [categoryList, setCategoryList] = useState([]);

  const getCategoryList = async () => {
    try {
      let list = (await CategoryApi.GetListAll(data)).data.data;
      setCategoryList(
        list.map((item) => {
          return {
            ...item,
          };
        })
      );
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    getCategoryList();
  }, []);

  //이미지 추가, 삭제
  const inputFile = (e) => {
    const file = e.target.files[0];

    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = (value) => {
      setData({
        ...data,
        file: file,
        fileUrl: value.target.result,
        deleteFile: true,
      });
    };
    e.target.value = "";
  };

  const deleteFile = () => {
    setData({ ...data, file: "", fileUrl: "", deleteFile: true });
  };

  //저장하기
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const saveFunc = async () => {
    try {
      if (
        nullCheck(data.title) ||
        nullCheck(data.brand) ||
        nullCheck(data.brandExplanation) ||
        nullCheck(data.categoryIdList) ||
        nullCheck(data.fileUrl) ||
        nullCheck(data.link)
      ) {
        setSaveModalOpen(false);
        return toast("필수사항을 모두 입력해주세요.");
      }

      const formData = new FormData();
      formData.append("title", data.title);
      formData.append("brand", data.brand);
      formData.append("brandExplanation", data.brandExplanation);

      if (data.categoryIdList.length > 0) {
        for (let index = 0; index < data.categoryIdList.length; index++) {
          const element = data.categoryIdList[index];
          formData.append("categoryIdList", element);
        }
      }

      if (data.file) {
        formData.append("file", data.file);
      }

      formData.append("link", data.link);
      formData.append("isActive", data.isActive);

      await AdsApi.Update(id, formData);
      setSaveModalOpen(false);
      navigate(-1);
      toast(toastMsg.create);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <GoBack />
          </DetailTop>
          <DetailPage style={{ minHeight: "375px" }}>
            <DetailPageTitle>수정하기</DetailPageTitle>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                marginBottom: "79px",
              }}
            >
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>광고명*</DetailFormTitle>
                <div>
                  <MainInput
                    type={"text"}
                    placeholder={"광고명을 입력해주세요"}
                    value={data.title}
                    onChange={(e) =>
                      setData({ ...data, title: e.target.value })
                    }
                  />
                  <PretendardText
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                      lineHeight: "22.75px",
                      color: "#808991",
                      marginTop: "3px",
                    }}
                  >
                    광고명은 관리자 페이지에서만 사용됩니다.
                  </PretendardText>
                </div>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>브랜드명*</DetailFormTitle>
                <MainInput
                  type={"text"}
                  placeholder={"브랜드명을 입력해주세요"}
                  value={data.brand}
                  onChange={(e) => setData({ ...data, brand: e.target.value })}
                />
              </DetailPageForm>
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>
                  브랜드 설명*
                  <br />
                  <span
                    style={{
                      fontSize: "12px",
                      fontWeight: "400",
                      lineHeight: "24.5px",
                      color: "#ADB4BA",
                    }}
                  >
                    (최대 30자)
                  </span>
                </DetailFormTitle>
                <TextArea
                  style={{
                    minHeight: "133px",
                  }}
                  placeholder={"브랜드 설명을 입력해주세요"}
                  value={data.brandExplanation}
                  onChange={(e) =>
                    setData({ ...data, brandExplanation: e.target.value })
                  }
                  maxLength={30}
                />
              </DetailPageForm>
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>
                  카테고리*
                  <br />
                  <span
                    style={{
                      fontSize: "12px",
                      fontWeight: "400",
                      lineHeight: "24.5px",
                      color: "#ADB4BA",
                    }}
                  >
                    (최대 3개)
                  </span>
                </DetailFormTitle>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "11px",
                  }}
                >
                  {categoryList.map((item, index) => {
                    return (
                      <CheckboxWrapper key={index}>
                        <Checkbox
                          style={{
                            width: "18px",
                            height: "18px",
                          }}
                          checked={data.categoryIdList?.includes(item.id)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              if (data.categoryIdList.length === 3) {
                                toast("최대 3개까지 선택가능합니다.");
                                return;
                              }
                              data.categoryIdList.push(item.id);
                            } else {
                              data.categoryIdList = data.categoryIdList.filter(
                                (categoryId) => categoryId !== item.id
                              );
                            }
                            setData({ ...data });
                          }}
                        />
                        <DetailFormText
                          style={{ color: "#808991", fontWeight: "500" }}
                        >
                          {item.title}
                        </DetailFormText>
                      </CheckboxWrapper>
                    );
                  })}
                </div>
              </DetailPageForm>
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>
                  썸네일*
                  <br />
                  <span
                    style={{
                      fontSize: "12px",
                      fontWeight: "400",
                      lineHeight: "24.5px",
                      color: "#ADB4BA",
                    }}
                  >
                    *권장 사이즈 434 X 606
                  </span>
                </DetailFormTitle>
                {data.fileUrl ? (
                  <ImgPreview
                    fileUrl={data.fileUrl}
                    onClick={() => {
                      deleteFile();
                    }}
                  />
                ) : (
                  <ImgInput
                    id={"file"}
                    accept={"image/*"}
                    onChange={(e) => {
                      inputFile(e);
                    }}
                  />
                )}
              </DetailPageForm>
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>링크*</DetailFormTitle>
                <div>
                  <MainInput
                    type={"text"}
                    placeholder={"링크를 삽입해 주세요"}
                    value={data.link}
                    onChange={(e) => setData({ ...data, link: e.target.value })}
                  />
                  <PretendardText
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                      lineHeight: "22.75px",
                      color: "#808991",
                      marginTop: "3px",
                    }}
                  >
                    클릭 시 새 창 이동 될 브랜드 링크를 삽입해 주세요.
                  </PretendardText>
                </div>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>상태*</DetailFormTitle>
                <div style={{ display: "flex", gap: "19px" }}>
                  <Radio
                    id={"POST"}
                    valueText={"게시"}
                    checked={data.isActive}
                    onChange={() => {
                      setData({ ...data, isActive: true });
                    }}
                  />
                  <Radio
                    id={"HIDE"}
                    valueText={"숨김"}
                    checked={!data.isActive}
                    onChange={() => {
                      setData({ ...data, isActive: false });
                    }}
                  />
                </div>
              </DetailPageForm>
            </div>
            <SaveBtn
              text={"저장하기"}
              style={{ bottom: "40px", right: "40px" }}
              onClick={() => {
                setSaveModalOpen(true);
              }}
            />
          </DetailPage>
        </DetailPageLayout>
      </MainLayout>
      {saveModalOpen ? (
        <Modal
          title={modalMsg.update?.title}
          text={modalMsg.update?.content}
          setModalOpen={setSaveModalOpen}
          onClick={saveFunc}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default AdsEdit;

const CheckboxWrapper = styled.div.attrs((props) => {})`
  display: flex;
  align-items: center;
  gap: 8px;
`;
