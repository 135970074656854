import api, { _axios } from "./api";

export default class CommonApi {
  static async FileDownload(data) {
    return await _axios.post(`file/download`, data, {
      responseType: "blob",
    });
  }

  static async fileUpload(data) {
    return await api.fileForm(`file`, data, "post");
  }
}
