import api from "./api.js";

export default class ReviewApi {
  static async GetList(marketId, params) {
    return await api.get(`admin/reviews/${marketId}`, params);
  }
  static async GetListDeleted(marketId, params) {
    return await api.get(`admin/reviews/deleted/${marketId}`, params);
  }
  static async GetById(marketId) {
    return await api.get(`admin/review/${marketId}`);
  }
  static async Delete(data) {
    return await api.del(`admin/reviews`, "", data);
  }
  static async DeleteById(reviewId) {
    return await api.del(`admin/review/${reviewId}`);
  }

  static async Hidden(reviewId) {
    return await api.put(`admin/review/hidden/${reviewId}`);
  }
  static async Visible(reviewId) {
    return await api.put(`admin/review/visible/${reviewId}`);
  }
}
