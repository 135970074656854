import api from "./api.js";

export default class ExcelApi {
  //일반회원
  static async GetMemberAll() {
    return await api.get(`admin/member/excel/all`);
  }
  static async GetMemberSelected(queryString) {
    return await api.get(`admin/member/excel/selected?${queryString}`);
  }

  //인플루언서 회원
  static async GetInfluencerAll() {
    return await api.get(`admin/member/excel/influencer/all`);
  }

  //탈퇴회원
  static async GetLeaveAll() {
    return await api.get(`admin/resigned/excel/all`);
  }
  static async GetLeaveSelected(queryString) {
    return await api.get(`admin/resigned/excel/selected?${queryString}`);
  }

  //등록된 마켓 관리
  static async GetMarketAll() {
    return await api.get(`admin/markets/all`);
  }
  static async GetMarketSelected(queryString) {
    return await api.get(`admin/markets/selected?${queryString}`);
  }

  //삭제된 마켓 관리
  static async GetDelMarketAll() {
    return await api.get(`admin/deletedMarkets/all`);
  }
  static async GetDelMarketSelected(queryString) {
    return await api.get(`admin/markets/deleted/selected?${queryString}`);
  }

  //거래내역 관리
  static async GetpurchaseAll() {
    return await api.get(`admin/purchases/excel`);
  }
  static async GetpurchaseSelected(queryString) {
    return await api.get(`admin/purchase/excel?${queryString}`);
  }

  //환불내역 관리
  static async GetRefundAll() {
    return await api.get(`admin/refunds/excel`);
  }

  //정산요청 관리
  static async GetCalculateAll() {
    return await api.get(`admin/calculates/excel`);
  }
  static async GetCalculateSelected(queryString) {
    return await api.get(`admin/calculate/excel?${queryString}`);
  }
}
