import api from "./api.js";

export default class CategoryApi {
  static async Create(data) {
    return await api.post(`admin/category`, data);
  }
  static async GetList(params) {
    return await api.get(`admin/categories`, params);
  }
  static async GetListAll(params) {
    return await api.get(`admin/categories/all`, params);
  }
  static async GetById(categoryId) {
    return await api.get(`admin/category/${categoryId}`);
  }
  static async Update(categoryId, data) {
    return await api.put(`admin/category/${categoryId}`, data);
  }
  static async Delete(data) {
    return await api.del(`admin/categories`, "", data);
  }
  static async DeleteById(categoryId) {
    return await api.del(`admin/category/${categoryId}`);
  }

  static async Activation(categoryId) {
    return await api.put(`admin/category/activate/${categoryId}`);
  }
  static async DeActivation(categoryId) {
    return await api.put(`admin/category/deactivate/${categoryId}`);
  }
}
