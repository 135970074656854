import api from "./api.js";

export default class MerchApi {
  static async GetList(marketId, params) {
    return await api.get(`admin/merches/${marketId}`, params);
  }
  static async GetById(merchId) {
    return await api.get(`admin/merch/${merchId}`);
  }
  static async Update(merchId, data) {
    return await api.put(`admin/merch/${merchId}`, data);
  }
  static async Delete(data) {
    return await api.del(`admin/merches`, "", data);
  }
  static async DeleteById(merchId) {
    return await api.del(`admin/merch/${merchId}`);
  }

  static async Status(merchId, data) {
    return await api.put(`admin/merch/status/${merchId}`, data);
  }
}
