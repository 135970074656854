import api from "./api.js";

export default class MemberApi {
  static async GetList(params) {
    return await api.get(`admin/members`, params);
  }
  static async GetById(memberId) {
    return await api.get(`admin/member/${memberId}`);
  }
  static async UpdateCommon(memberId, data) {
    return await api.put(`admin/member/common/${memberId}`, data);
  }
  static async UpdateInfluencer(memberId, data) {
    return await api.put(`admin/member/influencer/${memberId}`, data);
  }
  static async UpdateInfluencerSns(memberId, data) {
    return await api.put(`admin/member/influencer/sns/${memberId}`, data);
  }
  static async DeleteById(memberId) {
    return await api.del(`admin/member/${memberId}`);
  }

  static async Activation(memberId) {
    return await api.put(`admin/member/activate/${memberId}`);
  }
  static async DeActivation(memberId) {
    return await api.put(`admin/member/deactivate/${memberId}`);
  }
  static async Approve(memberId) {
    return await api.put(`admin/influencer/approve/${memberId}`);
  }
  static async ApproveCancel(memberId) {
    return await api.put(`admin/influencer/approve/cancel/${memberId}`);
  }
}
