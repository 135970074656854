import api from "./api.js";

export default class ReportApi {
  static async GetList(params) {
    return await api.get(`admin/reports`, params);
  }
  static async GetById(reportId) {
    return await api.get(`admin/report/${reportId}`);
  }
  static async DeleteById(reportId) {
    return await api.del(`admin/report/${reportId}`);
  }
}
