import React from "react";
import styled from "styled-components";

function DetailPageLayout(props) {
  return (
    <>
      <DetailPageLayoutWrapper
        style={{
          ...props.style,
        }}
      >
        {props.children}
      </DetailPageLayoutWrapper>
    </>
  );
}

export default DetailPageLayout;

const DetailPageLayoutWrapper = styled.div.attrs((props) => {})`
  width: 1044px;
  height: auto;
  margin-top: 116px;
`;
