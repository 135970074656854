import React, { useEffect, useState } from "react";

import CategoryApi from "../../../api/category.api";
import { useNavigate } from "react-router-dom";
import { nullCheck } from "../../../util/check";
import { toast } from "react-toastify";
import { toastMsg } from "../../../util/toastMsg";
import { errorHandler } from "../../../util/error-handler";
import Header from "../../../component/organisms/header";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import Sidebar from "../../../component/organisms/sidebar";
import DetailPageLayout from "../../../component/atoms/layout/detail/detailPageLayout";
import DetailTop from "../../../component/atoms/layout/detail/detailTop";
import GoBack from "../../../component/molecules/goBack";
import DetailPage from "../../../component/atoms/layout/detail/detailPage";
import DetailPageTitle from "../../../component/atoms/text/detail/detailPageTitle";
import DetailPageForm from "../../../component/atoms/layout/detail/detailPageForm";
import DetailFormTitle from "../../../component/atoms/text/detail/detailFormTitle";
import MainInput from "../../../component/atoms/input/mainInput";
import TextArea from "../../../component/atoms/input/textarea";
import Checkbox from "../../../component/atoms/input/checkbox";
import DetailFormText from "../../../component/atoms/text/detail/detailFormText";
import ImgPreview from "../../../component/molecules/imgPreview";
import ImgInput from "../../../component/atoms/input/imgInput";
import PretendardText from "../../../component/atoms/text/pretendardText";
import Modal from "../../../component/templates/Modal";
import { modalMsg } from "../../../util/modalMsg";
import SaveBtn from "../../../component/atoms/button/saveBtn";
import styled from "styled-components";
import Radio from "../../../component/atoms/input/radio";

function MarketCategoryAdd(props) {
  const navigate = useNavigate();

  const [data, setData] = useState({
    title: "",
    isActive: true,
    file: "",

    fileUrl: "",
  });

  //이미지 추가, 삭제
  const inputFile = (e) => {
    const file = e.target.files[0];

    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = (value) => {
      setData({ ...data, file: file, fileUrl: value.target.result });
    };
    e.target.value = "";
  };

  const deleteFile = () => {
    setData({ ...data, file: "", fileUrl: "" });
  };

  //저장하기
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const saveFunc = async () => {
    try {
      console.log(data.isActive);
      // if (nullCheck(data.title)) {
      //   setSaveModalOpen(false);
      //   return toast("필수사항을 모두 입력해주세요.");
      // }

      // const formData = new FormData();
      // formData.append("title", data.title);

      // if (data.file) {
      //   formData.append("file", data.file);
      // }

      // formData.append("isActive", data.isActive);

      // await CategoryApi.Create(formData);
      setSaveModalOpen(false);
      // navigate(-1);
      // toast(toastMsg.create);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <GoBack />
          </DetailTop>
          <DetailPage style={{ minHeight: "375px" }}>
            <DetailPageTitle>추가하기</DetailPageTitle>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                marginBottom: "79px",
              }}
            >
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>
                  카테고리명*
                  <br />
                  <span
                    style={{
                      fontSize: "12px",
                      fontWeight: "400",
                      lineHeight: "24.5px",
                      color: "#ADB4BA",
                    }}
                  >
                    (최대 8자)
                  </span>
                </DetailFormTitle>
                <MainInput
                  type={"text"}
                  placeholder={"카테고리명을 입력해주세요"}
                  onChange={(e) => setData({ ...data, title: e.target.value })}
                  maxLength={8}
                />
              </DetailPageForm>
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>
                  이미지*
                  <br />
                  <span
                    style={{
                      fontSize: "12px",
                      fontWeight: "400",
                      lineHeight: "24.5px",
                      color: "#ADB4BA",
                    }}
                  >
                    *권장 사이즈 110 X 110
                  </span>
                </DetailFormTitle>
                {data.fileUrl ? (
                  <ImgPreview
                    fileUrl={data.fileUrl}
                    onClick={() => {
                      deleteFile();
                    }}
                  />
                ) : (
                  <ImgInput
                    id={"file"}
                    accept={"image/*"}
                    onChange={(e) => {
                      inputFile(e);
                    }}
                  />
                )}
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>상태*</DetailFormTitle>
                <div style={{ display: "flex", gap: "19px" }}>
                  <Radio
                    id={"ACTIVATION"}
                    valueText={"활성"}
                    checked={data.isActive}
                    onChange={() => {
                      setData({ ...data, isActive: true });
                    }}
                  />
                  <Radio
                    id={"DEACTIVATION"}
                    valueText={"비활성"}
                    checked={!data.isActive}
                    onChange={() => {
                      setData({ ...data, isActive: false });
                    }}
                  />
                </div>
              </DetailPageForm>
            </div>
            <SaveBtn
              text={"등록하기"}
              style={{ bottom: "40px", right: "40px" }}
              onClick={() => {
                setSaveModalOpen(true);
              }}
            />
          </DetailPage>
        </DetailPageLayout>
      </MainLayout>
      {saveModalOpen ? (
        <Modal
          title={modalMsg.update?.title}
          text={modalMsg.update?.content}
          setModalOpen={setSaveModalOpen}
          onClick={saveFunc}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default MarketCategoryAdd;

const CheckboxWrapper = styled.div.attrs((props) => {})`
  display: flex;
  align-items: center;
  gap: 8px;
`;
