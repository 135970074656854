import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import Header from "../../../component/organisms/header";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import Sidebar from "../../../component/organisms/sidebar";
import TableLayout from "../../../component/atoms/layout/table/tableLayout";
import TableTitle from "../../../component/atoms/text/table/tableTitle";
import TableTop from "../../../component/atoms/layout/table/tableTop";
import TableSearchBtn from "../../../component/atoms/layout/table/tableSearchBtn";
import TableCount from "../../../component/molecules/tableCount";
import SearchBar from "../../../component/atoms/input/searchBar";
import Table from "../../../component/atoms/layout/table/table";
import Tr from "../../../component/atoms/layout/table/tr";
import Checkbox from "../../../component/atoms/input/checkbox";
import Th from "../../../component/atoms/layout/table/th";
import Td from "../../../component/atoms/layout/table/td";
import Pagenation from "../../../component/molecules/pagenation";
import { toast } from "react-toastify";
import { toastMsg } from "../../../util/toastMsg";
import moment from "moment";
import Modal from "../../../component/templates/Modal";
import { errorHandler } from "../../../util/error-handler";
import { route } from "../../../router/route";
import TableFilterManager from "../../../component/atoms/layout/table/tableFilterManager";
import ThText from "../../../component/atoms/text/table/thText";
import TdText from "../../../component/atoms/text/table/tdText";
import DelBtn from "../../../component/atoms/button/delBtn";
import DetailBtn from "../../../component/atoms/button/detailBtn";
import PretendardText from "../../../component/atoms/text/pretendardText";
import { modalMsg } from "../../../util/modalMsg";
import AddBtn from "../../../component/atoms/button/addBtn";
import TableDelBtn from "../../../component/atoms/button/tableDelBtn";
import StyleApi from "../../../api/style.api";

function Style(props) {
  const navigate = useNavigate();
  const location = useLocation();

  const { page } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [pagination, setPagination] = useState({
    size: 10, // 한 페이지에 표시될 게시글 수
    rangeSize: 5, // 하단 페이지 사이즈

    totalCount: 0,
    totalPages: 0,
    first: false,
    last: false,
  });
  const [list, setList] = useState([]);
  const [keyword, setKeyword] = useState("");

  const getList = async () => {
    try {
      const data = {
        keyword: searchParams.get("keyword"),
        page: page - 1,
        size: pagination.size,
      };
      let list = (await StyleApi.GetList(data)).data.data;
      setList(
        list.content.map((item) => {
          return {
            ...item,
            checked: false,
          };
        })
      );
      setPagination({
        ...pagination,
        totalCount: list.totalElements,
        totalPages: list.totalPages,
        first: list.first,
        last: list.last,
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    setKeyword(searchParams.get("keyword") ? searchParams.get("keyword") : "");
    getList();
  }, [location]);

  // ----- 활성/비활성
  const [filterOpen, setFilterOpen] = useState(-1);
  const [activeId, setActiveId] = useState(null);
  const [activeModalOpen, setActiveModalOpen] = useState(false);
  const [deactiveModalOpen, setDeactiveModalOpen] = useState(false);

  const activeFunc = async (activation) => {
    try {
      if (activation) {
        await StyleApi.Activation(activeId);
      } else {
        await StyleApi.DeActivation(activeId);
      }
      toast(toastMsg.create);
      setFilterOpen(-1);
      setActiveModalOpen(false);
      setDeactiveModalOpen(false);
      getList();
    } catch (error) {
      errorHandler(error);
    }
  };

  // ----- 삭제
  const [delIdList, setDelIdList] = useState([]);
  const [delListModalOpen, setDelListModalOpen] = useState(false);

  const deleteFunc = async () => {
    try {
      const formData = new FormData();
      for (let index = 0; index < delIdList.length; index++) {
        const element = delIdList[index];
        formData.append("idList", element.id);
      }

      await StyleApi.Delete(formData);
      toast(toastMsg.delete);
      setDelListModalOpen(false);
      setDelIdList([]);
      getList();
    } catch (error) {
      errorHandler(error);
    }
  };

  const [delId, setDelId] = useState(0);
  const [delModalOpen, setDelModalOpen] = useState(false);

  const deleteOneFunc = async () => {
    try {
      await StyleApi.DeleteById(delId);
      toast(toastMsg.delete);
      setDelModalOpen(false);
      setDelId(0);
      getList();
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <TableLayout>
          <TableTitle>스타일 추천 관리</TableTitle>
          <TableTop>
            <TableCount text={"항목"} count={pagination.totalCount} />
            <TableSearchBtn>
              <SearchBar
                value={keyword}
                onChange={(e) => {
                  setKeyword(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    navigate(`${route.etc_style}/1?keyword=${keyword}`, { replace: true });
                  }
                }}
                onClick={() => {
                  navigate(`${route.etc_style}/1?keyword=${keyword}`, { replace: true });
                }}
              />
              <DelBtn
                text={"선택항목 삭제"}
                onClick={() => {
                  const delIdList = list.filter((item) => item.checked);
                  if (delIdList.length <= 0) {
                    return;
                  }
                  setDelIdList([...delIdList]);
                  setDelListModalOpen(true);
                }}
              />
              <AddBtn onClick={() => navigate(route.etc_style_add)} />
            </TableSearchBtn>
          </TableTop>
          <Table>
            <colgroup>
              <col width="90px" />
              <col width="497px" />
              <col width="157px" />
              <col width="95px" />
              <col width="80px" />
              <col width="78px" />
            </colgroup>
            <thead>
              <Tr style={{ height: "41px", background: "#f2f4f5" }}>
                <Th style={{ paddingLeft: "20px" }}>
                  <Checkbox
                    checked={list.filter((item) => item.checked).length === list.length}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setList(
                          list.map((item) => {
                            return {
                              ...item,
                              checked: true,
                            };
                          })
                        );
                      } else {
                        setList(
                          list.map((item) => {
                            return {
                              ...item,
                              checked: false,
                            };
                          })
                        );
                      }
                    }}
                  />
                </Th>
                <Th>
                  <ThText>제목</ThText>
                </Th>
                <Th>
                  <ThText>카테고리</ThText>
                </Th>
                <Th>
                  <ThText>게시 상태</ThText>
                </Th>
                <Th>
                  <ThText style={{ textAlign: "center", paddingRight: "27px" }}>삭제하기</ThText>
                </Th>
                <Th>
                  <ThText style={{ textAlign: "center", paddingRight: "21px" }}>상세보기</ThText>
                </Th>
              </Tr>
            </thead>
            <tbody>
              {list.map((item, index) => {
                return (
                  <Tr style={{ height: "57px", background: "#fff" }} key={index}>
                    <Td style={{ paddingLeft: "20px" }}>
                      <Checkbox
                        checked={item.checked}
                        onChange={() => {
                          item.checked = !item.checked;
                          setList([...list]);
                        }}
                      />
                    </Td>
                    <Td>
                      <TdText
                        style={{
                          width: "218px",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          wordBreak: "break-all",
                        }}
                      >
                        {item.title}
                      </TdText>
                    </Td>
                    <Td>
                      <TdText
                        style={{
                          width: "242px",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          wordBreak: "break-all",
                        }}
                      >
                        {item.category?.title}
                      </TdText>
                    </Td>
                    <Td>
                      <TableFilterManager
                        onClick={() => {
                          filterOpen === index ? setFilterOpen(-1) : setFilterOpen(index);
                        }}
                        isOpen={filterOpen === index}
                        selectedValue={item.isActive}
                        list={[
                          {
                            value: true,
                            title: "게시",
                            onClick: () => {
                              setActiveId(item.id);
                              setActiveModalOpen(true);
                            },
                          },
                          {
                            value: false,
                            title: "숨김",
                            onClick: () => {
                              setActiveId(item.id);
                              setDeactiveModalOpen(true);
                            },
                          },
                        ]}
                      />
                    </Td>
                    <Td>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          paddingRight: "27px",
                        }}
                      >
                        <TableDelBtn
                          onClick={() => {
                            setDelId(item.id);
                            setDelModalOpen(true);
                          }}
                        />
                      </div>
                    </Td>
                    <Td>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          paddingRight: "17px",
                        }}
                      >
                        <DetailBtn
                          onClick={() => {
                            navigate(route.etc_style_detail + `/${item.id}`);
                          }}
                        />
                      </div>
                    </Td>
                  </Tr>
                );
              })}
            </tbody>
          </Table>
          <Pagenation
            route={route.etc_style}
            queryString={`keyword=${keyword}`}
            currentPage={page}
            totalPages={pagination.totalPages}
            rangeSize={pagination.rangeSize}
            first={pagination.first}
            last={pagination.last}
          />
        </TableLayout>
        {delListModalOpen ? (
          <Modal
            title={modalMsg.delete?.title}
            text={modalMsg.delete?.content}
            btnTitle={"삭제하기"}
            setModalOpen={setDelListModalOpen}
            onClick={() => {
              deleteFunc();
            }}
          />
        ) : (
          ""
        )}
        {delModalOpen ? (
          <Modal
            title={modalMsg.delete?.title}
            text={modalMsg.delete?.content}
            btnTitle={"삭제하기"}
            setModalOpen={setDelModalOpen}
            onClick={() => {
              deleteOneFunc();
            }}
          />
        ) : (
          ""
        )}
        {deactiveModalOpen ? (
          <Modal
            title={"숨김처리 하시겠습니까?"}
            setModalOpen={setDeactiveModalOpen}
            onClick={() => {
              activeFunc(false);
            }}
          />
        ) : (
          ""
        )}
        {activeModalOpen ? (
          <Modal
            title={"게시하시겠습니까?"}
            setModalOpen={setActiveModalOpen}
            onClick={() => {
              activeFunc(true);
            }}
          />
        ) : (
          ""
        )}
      </MainLayout>
    </>
  );
}

export default Style;
