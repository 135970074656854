import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

function ImgPreview({ fileUrl, onClick }) {
  const navigate = useNavigate();
  return (
    <>
      {fileUrl ? (
        <ImgPreviewWrapper>
          <img style={{ width: "90px", height: "90px", borderRadius: "3px" }} src={fileUrl} alt="" />
          <img
            style={{ position: "absolute", top: "6px", right: "6px", cursor: "pointer" }}
            src="/assets/admin/icons/ico_img_del.svg"
            alt=""
            onClick={onClick}
          />
        </ImgPreviewWrapper>
      ) : (
        ""
      )}
    </>
  );
}

export default ImgPreview;

const ImgPreviewWrapper = styled.div.attrs((props) => {})`
  position: relative;
  width: 90px;
  height: 90px;
`;
