import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import Header from "../../component/organisms/header";
import MainLayout from "../../component/atoms/layout/mainLayout";
import Sidebar from "../../component/organisms/sidebar";
import DetailTop from "../../component/atoms/layout/detail/detailTop";
import DetailPage from "../../component/atoms/layout/detail/detailPage";
import DetailPageTitle from "../../component/atoms/text/detail/detailPageTitle";
import DetailPageLayout from "../../component/atoms/layout/detail/detailPageLayout";
import GoBack from "../../component/molecules/goBack";
import EditBtn from "../../component/atoms/button/editBtn";
import DetailPageForm from "../../component/atoms/layout/detail/detailPageForm";
import DetailFormTitle from "../../component/atoms/text/detail/detailFormTitle";
import DetailFormText from "../../component/atoms/text/detail/detailFormText";
import { toast } from "react-toastify";
import { toastMsg } from "../../util/toastMsg";
import { errorHandler } from "../../util/error-handler";
import { route } from "../../router/route";
import DelBtn from "../../component/atoms/button/delBtn";
import Modal from "../../component/templates/Modal";
import { modalMsg } from "../../util/modalMsg";
import moment from "moment";
import FileImgPreview from "../../component/molecules/fileImgPreview";
import AdsApi from "../../api/ads.api";

function AdsDetail(props) {
  const navigate = useNavigate();
  let { id } = useParams();

  const [data, setData] = useState({
    title: "",
    brand: "",
    brandExplanation: "",
    categoryIdList: [],
    file: "",
    link: "",
    isActive: "",

    categoryTitle: "",
    fileUrl: "",
    originFileName: "",
    serverFileName: "",
  });

  // init
  const getData = async () => {
    try {
      const response = await AdsApi.GetById(id);
      const categoryList = response.data.data.categoryDtoList;
      const categoryTitleList = categoryList.map((item) => item.title);

      setData({
        ...response.data.data,
        categoryTitle: categoryTitleList.join(),
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  // ----- 삭제
  const [delModalOpen, setDelModalOpen] = useState(false);

  const deleteFunc = async () => {
    try {
      await AdsApi.DeleteById(id);
      toast(toastMsg.delete);
      setDelModalOpen(false);
      navigate(-1);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <GoBack />
            <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
              <DelBtn onClick={() => setDelModalOpen(true)} />
              <EditBtn
                onClick={() => {
                  navigate(route.etc_ads_edit + `/${id}`);
                }}
              />
            </div>
          </DetailTop>
          <DetailPage style={{ minHeight: "287px" }}>
            <DetailPageTitle>상세보기</DetailPageTitle>
            <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
              <DetailPageForm>
                <DetailFormTitle>광고명</DetailFormTitle>
                <DetailFormText>{data.title}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>브랜드명</DetailFormTitle>
                <DetailFormText>{data.brand}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>브랜드 설명</DetailFormTitle>
                <DetailFormText>{data.brandExplanation}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>카테고리</DetailFormTitle>
                <DetailFormText>{data.categoryTitle}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>이미지</DetailFormTitle>
                <FileImgPreview
                  fileUrl={data.fileUrl}
                  fileName={data.originFileName}
                  serverFileName={data.serverFileName}
                />
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>링크</DetailFormTitle>
                <DetailFormText>{data.link}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>상태</DetailFormTitle>
                <DetailFormText>{data.isActive ? "게시" : "숨김"}</DetailFormText>
              </DetailPageForm>
            </div>
          </DetailPage>
        </DetailPageLayout>
      </MainLayout>
      {delModalOpen ? (
        <Modal
          title={modalMsg.delete?.title}
          text={modalMsg.delete?.content}
          btnTitle={"삭제하기"}
          setModalOpen={setDelModalOpen}
          onClick={() => {
            deleteFunc();
          }}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default AdsDetail;
