import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import Header from "../../../component/organisms/header";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import Sidebar from "../../../component/organisms/sidebar";
import DetailTop from "../../../component/atoms/layout/detail/detailTop";
import DetailPage from "../../../component/atoms/layout/detail/detailPage";
import DetailPageTitle from "../../../component/atoms/text/detail/detailPageTitle";
import DetailPageLayout from "../../../component/atoms/layout/detail/detailPageLayout";
import GoBack from "../../../component/molecules/goBack";
import EditBtn from "../../../component/atoms/button/editBtn";
import DetailPageForm from "../../../component/atoms/layout/detail/detailPageForm";
import DetailFormTitle from "../../../component/atoms/text/detail/detailFormTitle";
import DetailFormText from "../../../component/atoms/text/detail/detailFormText";
import { toast } from "react-toastify";
import { toastMsg } from "../../../util/toastMsg";
import { errorHandler } from "../../../util/error-handler";
import { route } from "../../../router/route";
import DelBtn from "../../../component/atoms/button/delBtn";
import Modal from "../../../component/templates/Modal";
import { modalMsg } from "../../../util/modalMsg";
import moment from "moment";
import FileImgPreview from "../../../component/molecules/fileImgPreview";
import PurchaseApi from "../../../api/purchase.api";
import MemberApi from "../../../api/member.api";
import MarketApi from "../../../api/market.api";
import { nullCheck } from "../../../util/check";
import PretendardText from "../../../component/atoms/text/pretendardText";

function PurchaseDetail(props) {
  const navigate = useNavigate();
  let { id } = useParams();

  const [data, setData] = useState({
    merchInfo: "",
    purchaseStatus: "",
    purchaseStatusTitle: "",
    accountOwner: "",
    bank: "",
    account: "",
    merchNum: "",
    buyerNum: "",
    marketNum: "",
    marketName: "",
    merchName: "",
    buyerName: "",
    amount: "",
    sellerNum: "",
    calculated: "",
    calculateId: "",
    deliveryFee: "",
    price: "",
    images: "",
  });

  const [sellerInfo, setSellerInfo] = useState({
    email: "",
    name: "",
    nickName: "",
    phoneNumber: "",
    marketName: "",
    memberStatus: "",
    snsTypeTitle: "",
    snsId: "",
    snsLink: "",
  });

  const [marketInfo, setMarketInfo] = useState({
    createdAt: "",
    categoryTitle: "",
  });

  const [merchInfo, setMerchInfo] = useState({
    name: "",
    explanation: "",
    deliveryFee: "",
    chatLink: "",
    content: "",
    images: "",
  });

  const [buyerInfo, setBuyerInfo] = useState({
    email: "",
    name: "",
    nickName: "",
    phoneNumber: "",
  });

  // init
  const getData = async () => {
    try {
      const response = await PurchaseApi.GetById(id);
      setData({
        ...response.data.data,
      });

      if (nullCheck(response.data.data.merchInfo)) {
        setMerchInfo(null);
      } else {
        setMerchInfo({
          ...response.data.data.merchInfo,
        });
      }

      await getBuyerInfo(response.data.data.buyerNum);
      await getSellerInfo(response.data.data.sellerNum);
      // await getMarketInfo(response.data.data.marketNum);
    } catch (error) {
      errorHandler(error);
    }
  };

  const getBuyerInfo = async (id) => {
    try {
      const response = await MemberApi.GetById(id);
      setBuyerInfo({
        ...response.data.data,
      });
    } catch (error) {
      setBuyerInfo(null);
      errorHandler(error);
    }
  };

  const getSellerInfo = async (id) => {
    try {
      const response = await MemberApi.GetById(id);
      setSellerInfo({
        ...response.data.data,
      });
    } catch (error) {
      setSellerInfo(null);
      errorHandler(error);
    }
  };

  // const getMarketInfo = async (id) => {
  //   try {
  //     const response = await MarketApi.GetById(id);
  //     const categoryList = response.data.data.categoryDtoList;
  //     const categoryTitleList = categoryList.map((item) => item.title);
  //     setMarketInfo({
  //       ...response.data.data,
  //       categoryTitle: categoryTitleList.join(),
  //     });
  //   } catch (error) {
  //     setMarketInfo(null);
  //     errorHandler(error);
  //   }
  // };

  useEffect(() => {
    getData();
  }, []);

  // ----- 삭제
  const [delModalOpen, setDelModalOpen] = useState(false);

  const deleteFunc = async () => {
    try {
      await PurchaseApi.DeleteById(id);
      toast(toastMsg.delete);
      setDelModalOpen(false);
      navigate(-1);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <GoBack />
            <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
              <DelBtn onClick={() => setDelModalOpen(true)} />
            </div>
          </DetailTop>

          <DetailPage style={{ minHeight: "", marginBottom: "20px" }}>
            <DetailPageTitle>판매자 정보</DetailPageTitle>
            {nullCheck(sellerInfo) ? (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src="/assets/admin/icons/ico_null.svg"
                    alt=""
                    style={{
                      width: "20px",
                      height: "20px",
                    }}
                  />
                  <PretendardText
                    style={{
                      color: "#ADB4BA",
                      fontSize: "15px",
                      fontWeight: "600",
                      lineHeight: "24.5px",
                      marginTop: "3px",
                    }}
                  >
                    삭제된 회원입니다.
                  </PretendardText>
                </div>
              </>
            ) : (
              <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                {sellerInfo.resigned ? (
                  <>
                    <DetailPageForm>
                      <DetailFormTitle>이메일</DetailFormTitle>
                      <DetailFormText>{sellerInfo.email}</DetailFormText>
                    </DetailPageForm>
                    <DetailPageForm>
                      <DetailFormTitle>이름</DetailFormTitle>
                      <DetailFormText>{sellerInfo.name}</DetailFormText>
                    </DetailPageForm>
                    <DetailPageForm>
                      <DetailFormTitle>탈퇴일</DetailFormTitle>
                      <DetailFormText>{moment(sellerInfo.resignedDate).format("YYYY/MM/DD")}</DetailFormText>
                    </DetailPageForm>
                  </>
                ) : (
                  <>
                    <DetailPageForm>
                      <DetailFormTitle>이메일</DetailFormTitle>
                      <DetailFormText>{sellerInfo.email}</DetailFormText>
                    </DetailPageForm>
                    <DetailPageForm>
                      <DetailFormTitle>이름</DetailFormTitle>
                      <DetailFormText>{sellerInfo.name}</DetailFormText>
                    </DetailPageForm>
                    <DetailPageForm>
                      <DetailFormTitle>닉네임</DetailFormTitle>
                      <DetailFormText>{sellerInfo.nickName}</DetailFormText>
                    </DetailPageForm>
                    <DetailPageForm>
                      <DetailFormTitle>연락처</DetailFormTitle>
                      <DetailFormText>{sellerInfo.phoneNumber}</DetailFormText>
                    </DetailPageForm>
                    <DetailPageForm>
                      <DetailFormTitle>마켓명</DetailFormTitle>
                      <DetailFormText>{data.marketName}</DetailFormText>
                    </DetailPageForm>
                    <DetailPageForm>
                      <DetailFormTitle>상태</DetailFormTitle>
                      <DetailFormText>
                        {sellerInfo.memberStatus === "MARKET_NOT_ACTIVE" ? "비활성" : "활성"}
                      </DetailFormText>
                    </DetailPageForm>
                    <DetailPageForm>
                      <DetailFormTitle>개설일</DetailFormTitle>
                      <DetailFormText>{moment(data.marketCreatedAt).format("YYYY/MM/DD")}</DetailFormText>
                    </DetailPageForm>
                    <DetailPageForm>
                      <DetailFormTitle>카테고리</DetailFormTitle>
                      <DetailFormText>{data.categories}</DetailFormText>
                    </DetailPageForm>
                    <DetailPageForm>
                      <DetailFormTitle>SNS 유형</DetailFormTitle>
                      <DetailFormText>{sellerInfo.snsTypeTitle}</DetailFormText>
                    </DetailPageForm>
                    <DetailPageForm>
                      <DetailFormTitle>SNS 아이디</DetailFormTitle>
                      <DetailFormText>{sellerInfo.snsId}</DetailFormText>
                    </DetailPageForm>
                    <DetailPageForm>
                      <DetailFormTitle>SNS 링크</DetailFormTitle>
                      <DetailFormText>{sellerInfo.snsLink}</DetailFormText>
                    </DetailPageForm>
                  </>
                )}
              </div>
            )}
          </DetailPage>

          <DetailPage style={{ minHeight: "", marginBottom: "20px" }}>
            <DetailPageTitle>상품 정보</DetailPageTitle>
            {nullCheck(merchInfo) ? (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src="/assets/admin/icons/ico_null.svg"
                    alt=""
                    style={{
                      width: "20px",
                      height: "20px",
                    }}
                  />
                  <PretendardText
                    style={{
                      color: "#ADB4BA",
                      fontSize: "15px",
                      fontWeight: "600",
                      lineHeight: "24.5px",
                      marginTop: "3px",
                    }}
                  >
                    삭제된 상품입니다.
                  </PretendardText>
                </div>
              </>
            ) : (
              <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                <DetailPageForm>
                  <DetailFormTitle>상품명</DetailFormTitle>
                  <DetailFormText>{merchInfo.name}</DetailFormText>
                </DetailPageForm>
                <DetailPageForm style={{ alignItems: "flex-start" }}>
                  <DetailFormTitle>상품 요약 설명</DetailFormTitle>
                  <DetailFormText>{merchInfo.explanation}</DetailFormText>
                </DetailPageForm>
                <DetailPageForm>
                  <DetailFormTitle>배송비</DetailFormTitle>
                  <DetailFormText>{Number(merchInfo.deliveryFee).toLocaleString("ko-KR")}</DetailFormText>
                </DetailPageForm>
                <DetailPageForm>
                  <DetailFormTitle>채팅 링크</DetailFormTitle>
                  <DetailFormText>{merchInfo.chatLink}</DetailFormText>
                </DetailPageForm>
                <DetailPageForm style={{ alignItems: "flex-start" }}>
                  <DetailFormTitle>상품 내용</DetailFormTitle>
                  <DetailFormText>{merchInfo.content}</DetailFormText>
                </DetailPageForm>
                <DetailPageForm style={{ alignItems: "flex-start" }}>
                  <DetailFormTitle>상품 이미지</DetailFormTitle>
                  <div style={{ display: "flex", gap: "5px", flexWrap: "wrap", width: "744px" }}>
                    {merchInfo.images?.length > 0
                      ? merchInfo.images?.map((item, index) => {
                          return (
                            <FileImgPreview
                              key={index}
                              fileUrl={item.fileUrl}
                              fileName={item.originFileName}
                              serverFileName={item.serverFileName}
                            />
                          );
                        })
                      : null}
                  </div>
                </DetailPageForm>
              </div>
            )}
          </DetailPage>

          <DetailPage style={{ minHeight: "", marginBottom: "64px" }}>
            <DetailPageTitle>구매자 정보</DetailPageTitle>
            {nullCheck(buyerInfo) ? (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src="/assets/admin/icons/ico_null.svg"
                    alt=""
                    style={{
                      width: "20px",
                      height: "20px",
                    }}
                  />
                  <PretendardText
                    style={{
                      color: "#ADB4BA",
                      fontSize: "15px",
                      fontWeight: "600",
                      lineHeight: "24.5px",
                      marginTop: "3px",
                    }}
                  >
                    삭제된 회원입니다.
                  </PretendardText>
                </div>
              </>
            ) : (
              <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                {buyerInfo.resigned ? (
                  <>
                    <DetailPageForm>
                      <DetailFormTitle>이메일</DetailFormTitle>
                      <DetailFormText>{buyerInfo.email}</DetailFormText>
                    </DetailPageForm>
                    <DetailPageForm>
                      <DetailFormTitle>이름</DetailFormTitle>
                      <DetailFormText>{buyerInfo.name}</DetailFormText>
                    </DetailPageForm>
                    <DetailPageForm>
                      <DetailFormTitle>탈퇴일</DetailFormTitle>
                      <DetailFormText>{moment(buyerInfo.resignedDate).format("YYYY/MM/DD")}</DetailFormText>
                    </DetailPageForm>
                  </>
                ) : (
                  <>
                    <DetailPageForm>
                      <DetailFormTitle>이메일</DetailFormTitle>
                      <DetailFormText>{buyerInfo.email}</DetailFormText>
                    </DetailPageForm>
                    <DetailPageForm>
                      <DetailFormTitle>이름</DetailFormTitle>
                      <DetailFormText>{buyerInfo.name}</DetailFormText>
                    </DetailPageForm>
                    <DetailPageForm>
                      <DetailFormTitle>닉네임</DetailFormTitle>
                      <DetailFormText>{buyerInfo.nickName}</DetailFormText>
                    </DetailPageForm>
                    <DetailPageForm>
                      <DetailFormTitle>연락처</DetailFormTitle>
                      <DetailFormText>{buyerInfo.phoneNumber}</DetailFormText>
                    </DetailPageForm>
                    <DetailPageForm>
                      <DetailFormTitle>입금은행</DetailFormTitle>
                      <DetailFormText>{data.bank}</DetailFormText>
                    </DetailPageForm>
                    <DetailPageForm>
                      <DetailFormTitle>입금계좌</DetailFormTitle>
                      <DetailFormText>{data.account}</DetailFormText>
                    </DetailPageForm>
                    <DetailPageForm>
                      <DetailFormTitle>입금자명</DetailFormTitle>
                      <DetailFormText>{data.accountOwner}</DetailFormText>
                    </DetailPageForm>
                    <DetailPageForm>
                      <DetailFormTitle>입금금액</DetailFormTitle>
                      <DetailFormText>{Number(data.amount).toLocaleString("ko-KR")}</DetailFormText>
                    </DetailPageForm>
                    <DetailPageForm style={{ alignItems: "flex-start" }}>
                      <DetailFormTitle>입금 인증 이미지</DetailFormTitle>
                      <div style={{ display: "flex", gap: "5px" }}>
                        {data.images?.length > 0
                          ? data.images?.map((item, index) => {
                              return (
                                <FileImgPreview
                                  key={index}
                                  fileUrl={item.fileUrl}
                                  fileName={item.originFileName}
                                  serverFileName={item.serverFileName}
                                />
                              );
                            })
                          : null}
                      </div>
                    </DetailPageForm>
                    <DetailPageForm>
                      <DetailFormTitle>거래 상태</DetailFormTitle>
                      <DetailFormText>{data.purchaseStatusTitle}</DetailFormText>
                    </DetailPageForm>
                  </>
                )}
              </div>
            )}
          </DetailPage>
        </DetailPageLayout>
      </MainLayout>
      {delModalOpen ? (
        <Modal
          title={modalMsg.delete?.title}
          text={modalMsg.delete?.content}
          btnTitle={"삭제하기"}
          setModalOpen={setDelModalOpen}
          onClick={() => {
            deleteFunc();
          }}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default PurchaseDetail;
