import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import MainInput from "../../component/atoms/input/mainInput";
import { route } from "../../router/route";
import { nullCheck } from "../../util/check";
import { regExpression } from "../../util/reg-expresstion";
import PretendardText from "../../component/atoms/text/pretendardText";
import ManagerApi from "../../api/manager.api";
// import ManagerApi from "../../api/managerApi";

function Join(props) {
  const navigate = useNavigate();

  const [joinData, setJoinData] = useState({
    accessMenu: [
      route.home,
      route.member_member,
      route.member_leave,
      route.video_video,
      route.video_filter,
      route.service_notice,
      route.service_faq,
      route.service_ask,
      route.service_license,
      route.service_terms,
      route.basic,
      route.manager,
    ],
    name: "",
    email: "",
    password: "",
    passwordCheck: "",
  });

  //에러
  const [emailErr, setEmailErr] = useState(false);
  const [PwErr, setPwErr] = useState(false);
  const [PwCheckErr, setPwCheckErr] = useState(false);

  const activeFlag = useMemo(() => {
    if (
      !nullCheck(joinData.name) &&
      !nullCheck(joinData.email) &&
      !nullCheck(joinData.password) &&
      !nullCheck(joinData.passwordCheck) &&
      !emailErr &&
      !PwErr &&
      !PwCheckErr
    ) {
      return true;
    } else {
      return false;
    }
  }, [joinData.name, joinData.email, joinData.password, joinData.passwordCheck, emailErr, PwErr, PwCheckErr]);

  useEffect(() => {
    if (joinData.password !== joinData.passwordCheck) {
      setPwCheckErr(true);
    } else setPwCheckErr(false);
  }, [joinData.passwordCheck, joinData.password]);

  //회원가입 처리
  const handleJoin = async () => {
    const obj = {
      accessMenu: JSON.stringify(joinData.accessMenu),
      name: joinData.name,
      email: joinData.email,
      password: joinData.password,
    };
    try {
      await ManagerApi.Join(obj);
      navigate(route.join_complete);
    } catch (error) {
      toast(error.response.data.message);
    }
  };

  return (
    <JoinWrapper>
      <JoinBox>
        <PretendardText
          style={{
            fontSize: "30px",
            fontWeight: "700",
            lineHeight: "41.5px",
            letterSpacing: "-0.21px",
            color: "#000",
            marginBottom: "40px",
          }}
        >
          회원가입
        </PretendardText>
        <JoinInputWrapper>
          <JoinInputForm>
            <PretendardText
              style={{
                fontSize: "16px",
                fontWeight: "600",
                lineHeight: "26px",
                color: "#808991",
              }}
            >
              이름
            </PretendardText>
            <MainInput
              type={"text"}
              placeholder={"이름을 입력해 주세요."}
              style={{ width: "343px" }}
              onChange={(e) => setJoinData({ ...joinData, name: e.target.value })}
            />
          </JoinInputForm>
          <div>
            <JoinInputForm>
              <PretendardText
                style={{
                  fontSize: "16px",
                  fontWeight: "600",
                  lineHeight: "26px",
                  color: "#808991",
                }}
              >
                이메일
              </PretendardText>
              <MainInput
                type={"email"}
                placeholder={"이메일을 입력해 주세요."}
                helperText={"이메일 형식이 올바르지 않습니다"}
                onError={emailErr}
                style={{
                  width: "343px",
                  borderColor: `${emailErr ? "#FF003D" : ""}`,
                }}
                onChange={(e) => {
                  if (!regExpression.email.test(e.target.value)) {
                    setEmailErr(true);
                    return;
                  }
                  setEmailErr(false);
                  setJoinData({ ...joinData, email: e.target.value });
                }}
              />
            </JoinInputForm>
          </div>
          <div>
            <JoinInputForm>
              <PretendardText
                style={{
                  fontSize: "16px",
                  fontWeight: "600",
                  lineHeight: "26px",
                  color: "#808991",
                }}
              >
                비밀번호
              </PretendardText>
              <MainInput
                type={"password"}
                placeholder={"비밀번호를 입력해 주세요."}
                style={{
                  width: "343px",
                  borderColor: `${PwErr ? "#FF003D" : ""}`,
                }}
                onChange={(e) => {
                  setJoinData({ ...joinData, password: e.target.value });
                  if (!regExpression.password.test(e.target.value)) {
                    setPwErr(true);
                    return;
                  }
                  setPwErr(false);
                }}
              />
            </JoinInputForm>
            <div style={{ width: "100%", marginTop: "3px" }}>
              <JoinCaption style={{ color: `${PwErr ? "#FF003D" : ""}` }}>
                영문/숫자/특수문자 중 2가지 이상 포함, 8자 ~ 16자
              </JoinCaption>
            </div>
          </div>
          <div>
            <JoinInputForm>
              <PretendardText
                style={{
                  fontSize: "16px",
                  fontWeight: "600",
                  lineHeight: "26px",
                  color: "#808991",
                }}
              >
                비밀번호 확인
              </PretendardText>
              <MainInput
                type={"password"}
                placeholder={"비밀번호를 한번 더 확인해주세요."}
                style={{ width: "343px" }}
                onChange={(e) => setJoinData({ ...joinData, passwordCheck: e.target.value })}
                onError={joinData.passwordCheck !== "" && PwCheckErr}
                helperText={"비밀번호가 일치하지 않습니다"}
              />
            </JoinInputForm>
          </div>
        </JoinInputWrapper>
        <JoinBtn disabled={!activeFlag} onClick={handleJoin}>
          회원가입
        </JoinBtn>
      </JoinBox>
    </JoinWrapper>
  );
}

export default Join;

const JoinWrapper = styled.div.attrs((props) => {})`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const JoinBox = styled.div.attrs((props) => {})`
  width: 518px;
  min-height: 730px;
  padding: 86px 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 16px;
  background: #fff;
  box-shadow: 4px 8px 36px 0px rgba(0, 0, 0, 0.05);
`;

const JoinInputWrapper = styled.div.attrs((props) => {})`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;
  margin-bottom: 40px;
`;

const JoinInputForm = styled.div.attrs((props) => {})`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 3px;
`;

const JoinBtn = styled.button.attrs((props) => {})`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => (props.disabled ? "#BABABA" : "#27BEFF")};
  font-family: Pretendard;
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
  color: #fff;
  border-radius: 3px;

  &:hover {
    background-color: #009bde;
  }
  &:disabled:hover {
    background-color: #bababa;
  }
`;

const JoinCaption = styled(PretendardText).attrs((props) => {})`
  color: #808991;
  font-size: 14px;
  font-weight: 500;
  line-height: 22.75px; /* 141.667% */
`;
