import React from "react";
import styled from "styled-components";
import PretendardText from "../pretendardText";

const ThText = (props) => {
  return <ThTextWrapper {...props} />;
};

export default ThText;

const ThTextWrapper = styled(PretendardText).attrs((props) => {})`
  color: #778088;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.042px;
`;
