import api from "./api.js";

export default class CommentApi {
  static async Create(data) {
    return await api.post(`admin/columnComment`, data);
  }
  static async GetList(params, columnId) {
    return await api.get(`admin/columnComments/${columnId}`, params);
  }
  static async GetById(columnCommentId) {
    return await api.get(`admin/columnComment/${columnCommentId}`);
  }
  static async Update(columnCommentId, data) {
    return await api.put(`admin/columnComment/${columnCommentId}`, data);
  }
  static async Delete(data) {
    return await api.del(`admin/columnComments`, "", data);
  }
  static async DeleteById(columnCommentId) {
    return await api.del(`admin/columnComment/${columnCommentId}`);
  }

  static async Activation(columnCommentId) {
    return await api.put(`admin/columnComment/activate/${columnCommentId}`);
  }
  static async DeActivation(columnCommentId) {
    return await api.put(`admin/columnComment/deactivate/${columnCommentId}`);
  }
}
